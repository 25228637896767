<template>
    <b-modal title='Manage Responsible Users' id='projectsResponsibleUsersModal' hide-footer>
        <b-overlay :show="is_saving">
            <vue-typeahead-bootstrap
                ref="userSearch"
                :data="users"
                v-model="query"
                placeholder="Search name/email"
                showOnFocus
                @hit="selectedItemEvent"
                prepend="Users:"
            ></vue-typeahead-bootstrap>
            <webix-ui v-if="has_mounted" style="height: 100%;" v-model="tree_data" :config="ux" ></webix-ui>
            <!-- footer -->
            <div class='d-flex'>
                <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
            </div>
        </b-overlay>
    </b-modal>

</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
//eslint-disable-next-line
import { cloneDeep, findIndex } from 'lodash';
import { levelComputed, ganttComputed, ganttMethods, performanceComputed } from '@/state/helpers';
export default {
    components:{
        //eslint-disable-next-line
        VueTypeaheadBootstrap,
    },
    props:{
        payload:{
            required: true,
        },
    },
    data:() => ({
        //typeahead
        users:[],
        query: '',

        //webix
        tree_data:[],
        has_mounted: false,
        is_saving: false,
    }),
    computed:{
        ...levelComputed,
        ...ganttComputed,
        ...performanceComputed,
        ux(){
            let el = this;
            return {
                id: 'projectsResponsibleUsersTree',
                view: 'tree',
                css: 'levelTree',
                drag: false,
                tooltip: true,
                data: [] ,
                template: function(obj, common){
                    if(obj.show_checkbox){
                        if(obj.is_owner){
                            return `<div class="text-primary click-not-allowed ms-1"><span style="width: 13px; height:13px;" class="d-inline-flex"></span> ${obj.name} (Owner)</div>`
                        }
                        else{
                            return `<span class="checkbox-padding-fix ms-1">${common.checkbox(obj, common)} ${obj.name}</span>`
                        }
                    }
                    else{
                        return common.icon(obj, common) + common.folder(obj, common) + ` ${obj.name}`
                    }
                } ,
                on:{
                    //eslint-disable-next-line
                    onBeforeContextMenu:function(id, e, node){
                        el.right_clicked_id = id;
                        let ctx_menu =  window.webix.$$('assignmentContextMenu');
                        if(ctx_menu != undefined){
                            ctx_menu.clearAll();
                            let options = el.contextMenuItems;
                            ctx_menu.define('data', options);
                            ctx_menu.refresh();
                        }
                    },
                },
                ready(){
                    
                    //if(window.webix.$$('assignmentContextMenu')){
                    //    window.webix.$$('assignmentContextMenu').destructor();
                    //}
                    //window.webix.ui({
                    //    view:'contextmenu',
                    //    id: 'assignmentContextMenu',
                    //    on:{
                    //        onMenuItemClick: (id) => {
                    //            switch(id){
                    //                case 'Select all users':{
                    //                    alert('select all');
                    //                    break;
                    //                }
                    //                case 'Unselect all users':{
                    //                    alert('Unselect all')
                    //                    break;
                    //                }
                    //                case 'Select all for level only':{
                    //                    alert('select all for level')
                    //                    break;
                    //                }
                    //                case 'Unselect all for level only':{
                    //                    alert('Unselect all for level')
                    //                    break;
                    //                }
                    //            }
                    //        }
                    //    }
                    //})
                    //.attachTo(this);
                    
                    //* insert users
                    let user_array = cloneDeep(el.all_users)
                    user_array.forEach(( user )=>{
                        if(user.id == el.payload.owner_user_id){
                            user['is_owner'] = true;
                        }
                        else{
                            user['is_owner'] = false;
                        }
                        
                        user['show_checkbox'] = true;
                        if(user.user_levels[0] != undefined){
                            let branch_id = user.user_levels[0].id
                            //TODO - remove the level_id part
                            user.id = `${branch_id}|user-${user.id}`
                            //*insert user leaf into the linked level branch
                            //*{ user_object, level_id (parent)}
                            this.add(user, 0,branch_id);
                            console.log(user, 0, branch_id)
                        }                        
                    })
                    //*show users under folders
                    this.sort('#show_checkbox#');

                    //* expand first node
                    
                    this.open(this.getFirstId());
                    el.setInitialCheckedIUsers();
                },
                scroll: false,
            }
        },
        contextMenuItems(){
            if(this.modal_mode == 'level'){
                return [
                    'Select all children',
                    'Unselect all children'
                ]
            }
            else{
                return [
                    'Select all users',
                    'Unselect all users',
                    'Select all for level only',
                    'Unselect all for level only'
                ]
            }
        },
    },
    methods:{
        ...ganttMethods,
        filterProjectsData() {
        this.$nextTick(() => {
            console.log(this.selected_goal_objective)
            if(this.selected_goal_objective != -1) {
                console.log("1")
                let params = {
                    item_id: this.selected_goal_objective,
                    item_type: 'objective',
                    user_id: this.selected_user_id, 
                }
                this.getClientProjects(params).then(() => {    
                    // this.findMainLinkedProjects()      
                    // this.setProjectsData()
                    this.$emit('updated');
                    this.$bvModal.hide('linkedKeyActionsModal')
                })
            } else if(this.selected_target != -1) {
                console.log("2")
                    let params = {
                        item_id: this.selected_target,
                        item_type: 'target',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params).then(() => {
                        // this.findMainLinkedProjects();
                        // this.setProjectsData()
                        this.$emit('updated');
                        this.$bvModal.hide('linkedKeyActionsModal')
                    })
            } else {
                console.log("3")
                    let params = {
                        item_id: this.selected_level.id,
                        item_type: 'level',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params).then(() => {
                        // this.setProjectsData()
                        // this.findMainLinkedProjects();
                        this.$emit('updated');
                        this.$bvModal.hide('linkedKeyActionsModal')
                    })
            }     
        })
    },
        save(){
            let tree = window.webix.$$('projectsResponsibleUsersTree');
            let checked_items = tree.getChecked();
            let params = {
                project_id: this.payload.id,
                responsible_users: [],
            }
            checked_items.forEach( item_id => {
                // each user node has the id format of {level_id}|user-{user_id};
                params.responsible_users.push(item_id.split('-')[1]);
            })
            this.is_saving = true;
            this.saveResponsibleProjectUsers(params)
            .then(()=>{
                this.$bvModal.hide('projectsResponsibleUsersModal');
                this.filterProjectsData()
            })
            .catch(()=>{
                this.is_saving = false;
            })
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.users = users;
        },
        processTree(data){
            data.forEach((element) => {
                // give element the required fields
                element["parent"] = element.parent_id; //used for webix related stuff
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id != null) {
                //* If element is a child element, push to children array.
                    //let users_array = [];
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            ele['flagged'] = true;
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //TODO - verify this fixed duplicates not being removed
                            let index = findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }
                        }
                    });
                }
            });
            //* Remove the leftover / duplicate elements
            //data = data.filter((ele) => ele.parent_id != ele.id);
            //data = data.filter((ele) => ele.parent_id == null);
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            this.$nextTick(()=>{
                this.has_mounted = true;
            })
            return data;
        },
        setInitialCheckedIUsers(){
            let tree = window.webix.$$('projectsResponsibleUsersTree');
            if(tree){
                //let projects = _.cloneDeep(this.projects)
                console.log(this.payload.id);
                console.log(this.projects)
                 let project_index = this.projects.findIndex( item => { return item.id == this.payload.id});
                 console.log(project_index)
                if(project_index != undefined) {
                    this.projects[project_index].responsible_users.forEach( users => {
                    let user = this.all_users.find( item => {
                        return item.id == users.id
                    });
                    if(user){
                        user.user_levels.forEach( user_level => {
                            let user_in_tree_id = `${user_level.id}|user-${user.id}`;
                            if(tree.exists(user_in_tree_id)){
                                tree.checkItem(user_in_tree_id);
                            }
                        })
                    }
                })
                this.openTreeNode(tree, tree.getChecked());
                }
                
            }
        },
        openTreeNode(tree_component, id_array){
            id_array.forEach( id => {
                let item = tree_component.getItem(id);
                while(item.$parent != 0){
                    tree_component.open(item.$parent);
                    item = tree_component.getItem(item.$parent);
                }
            })
        },
        //* typeahead
        selectedItemEvent(item){
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let email = item.split('| ')[1];
            let user = this.all_users.find( user => {
                return user.email == email;
            });
            
            if(user){
                if(user.id == this.payload.owner_user_id){
                    this.$swal.fire(`${user.name} (${email}) is the owner of the current project and can't be added as a reponsible user`);
                    return;
                }
                let tree = window.webix.$$('projectsResponsibleUsersTree');
                let matches = [];
                user.user_levels.forEach( user_level => {
                    matches.push(`${user_level.id}|user-${user.id}`)
                });

                matches.forEach( item => {
                    if(tree.isChecked(item)){
                        tree.uncheckItem(item);
                    }
                    else{
                        tree.checkItem(item);
                    }
                })
                this.openTreeNode(tree, matches);
                this.resetTypeahead();
            }
        },
        resetTypeahead(){
            this.$nextTick(()=>{
                this.query = '';
                let input = this.$refs['userSearch'].$refs['input'];
                input.blur();
            })
        },
},
    mounted(){
        console.log(JSON.parse(JSON.stringify(this.payload)))
        //* build array of users for the webix tree
        let levels_with_users = cloneDeep(this.levels);
        levels_with_users.forEach( element => element['data'] = []);
        this.tree_data = this.processTree(levels_with_users);

        this.populateList();
    },
}
</script>

<style>
.webix_view.webix_window.webix_popup.webix_menu{
    z-index: 1041 !important;
}

.checkbox-padding-fix .webix_tree_checkbox{
    margin-top: 2px;
}
</style>