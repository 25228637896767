<template>
    <div>
        <div v-if="show" class="border border-grey p-2" style="min-height: 14rem;">
                <div class="" v-if="data">
                    <GraphHeader>
                        <template #text>
                            <span style="max-width: 10rem;" class="text-truncate">{{data.name}}</span>
                        </template>
                        <template #badges>
                            <b-badge v-if="data.category" variant="primary" :class="data.category == 'leading' ? 'bg-primary' : 'bg-danger'" class="bg-soft text-dark me-1 text-capitalize">{{ data.category }}</b-badge>
                            <b-badge v-if="hasSharedDataset" variant="primary" class="bg-primary bg-soft text-dark me-1 text-capitalize">{{data.share_type}}</b-badge>
                            <b-badge class="bg-info bg-soft text-dark me-1 text-capitalize">{{returnTrackingPeriodTitle}}</b-badge>
                            <b-badge variant="primary" class="bg-success bg-soft text-dark me-1">{{getUnitType(data.unit_type_id)}}</b-badge>
                            
                            
                        </template>
                        <template #buttons>
                            <span class="ms-auto" style="">
                                <span v-if="edit_mode && dataset_mode === 'shared'" class="btn-sm pb-0 pe-0 me-1">
                                    <b-dropdown size="sm" variant="success" toggle-class="text-capitalize" :text="calculationTypeTitle" >
                                        <template #button-content>
                                            <font-awesome-icon class="me-1" icon="fa-solid fa-caret-down" />
                                            <span class="text-capitalize">{{calculationTypeTitle}}</span>
                                        </template>
                                        <b-dropdown-item @click="setCalculationType('sum')">
                                            Sum
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setCalculationType('product')">
                                            Product
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setCalculationType('average')">
                                            Average
                                        </b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item @click="setCalculationType('multi-line')">
                                            Overlay
                                        </b-dropdown-item>
                                    </b-dropdown>

                                    <b-button size="sm" @click="saveSharedDataset" variant="primary" class="ms-2">
                                        Save
                                    </b-button>
                                </span>
                                <span v-show="edit_mode" class="btn-group btn-sm me-1 pb-0 pe-0">
                                    <b-button size="sm" :variant="dataset_mode == 'default' ? 'dark' : 'light'" @click="toggleDatasetType('default')">
                                        Data Table
                                    </b-button>
                                    <b-button size="sm" :variant="dataset_mode == 'shared' ? 'dark' : 'light'" @click="toggleDatasetType('shared')">
                                        Linked Graphs Table
                                    </b-button>
                                </span>
                                <span class="" v-if="edit_mode == false">
                                    <span class="btn-group  me-2">
                                        <b-button :disabled="edit_mode" @click="setCustomTimePeriod(data.current_time_period)" size="sm" variant="warning" v-if="false && custom_time_period != this.data.current_time_period">
                                            <i class="fas fa-redo"></i>
                                        </b-button>
                                        <b-button :disabled="data.tracking.length == 0" v-if="canShow('daily')" @click="setCustomTimePeriod('daily')" :variant="custom_time_period === 'daily' ? 'dark' : 'light'" size="sm">D</b-button>
                                        <b-button :disabled="data.tracking.length == 0" v-if="canShow('weekly')" @click="setCustomTimePeriod('weekly')" :variant="custom_time_period === 'weekly' ? 'dark' : 'light'" size="sm">W</b-button>
                                        <b-button :disabled="data.tracking.length == 0" v-if="canShow('monthly')" @click="setCustomTimePeriod('monthly')" :variant="custom_time_period === 'monthly' ? 'dark' : 'light'" size="sm">M</b-button>
                                        <b-button :disabled="data.tracking.length == 0" v-if="canShow('quarterly')" @click="setCustomTimePeriod('quarterly')" :variant="custom_time_period === 'quarterly' ? 'dark' : 'light'" size="sm">Q</b-button>
                                        <b-button :disabled="data.tracking.length == 0" v-if="canShow('yearly')" @click="setCustomTimePeriod('yearly')" :variant="custom_time_period === 'yearly' ? 'dark' : 'light'" size="sm">Y</b-button>
                                    </span>

                                    <span v-if="middle_text !== ''" style="" class="">
                                        <b-button style="width: 4rem;" size="sm" variant="light">
                                            {{middle_text}}
                                        </b-button>
                                    </span>
                                </span>
                                <span class="btn-group">
                                    <ToolBar v-if="edit_mode && dataset_mode == 'default'" :is_kpi="true" :table_id="table_uid"/>
                                    <b-button v-show="false && edit_mode == false" style="width: 2rem;" title="Number of linked datasets" variant="outline-dark" size="sm">
                                        {{getDatasetCount}}
                                    </b-button>
                                    <b-button v-if="false" variant="outline-primary" size="sm" style="width: 2rem;"  @click="toggleGraphDatasetEditMode()"><!-- @click="edit_mode = !edit_mode" -->
                                        <span v-show="edit_mode" title="Cancel">
                                            <font-awesome-icon icon="fa-solid fa-times" />
                                        </span>
                                        <span v-show="edit_mode == false" title="Show Data View">
                                            <font-awesome-icon icon="fa-solid fa-database" />
                                        </span>
                                    </b-button>
                                    <b-button v-if="false" @click="expandGraph()" size="sm" style="width: 2rem;">
                                        <!--<i class="fas fa-arrows-alt-v"></i>-->
                                        <font-awesome-icon icon="fa-solid fa-arrows-alt-v" />
                                    </b-button> 
                                    <!--<b-button title="Show More Options" v-if="edit_mode == false" variant="outline-primary" size="sm" style="width: 2rem;" @click="show_more_options = !show_more_options">
                                            <i class="fas fa-ellipsis-v"></i>
                                    </b-button>-->
                                </span>
                            </span>
                        </template>
                    </GraphHeader>
                </div>
                <div class="d-flex" v-if="false && data">
                    <span class="card-title p-0 m-0 d-inline-block text-truncate">
                        <b-badge v-if="hasSharedDataset" variant="primary" class="bg-primary bg-soft text-dark me-1 text-capitalize">{{data.share_type}}</b-badge>
                        <b-badge class="bg-info bg-soft text-dark me-1 text-capitalize">{{returnTrackingPeriodTitle}}</b-badge>
                        <b-badge variant="primary" class="bg-success bg-soft text-dark me-1">{{getUnitType(data.unit_type_id)}}</b-badge>
                        <span style="max-width: 10rem;" class="text-truncate">{{data.name}}</span>
                    </span>
                    
                    <div class="ms-auto" style="">
                        <span class="" v-if="edit_mode == false">
                            <span class="btn-group  me-2">
                                <b-button v-if="canShow('daily')" @click="setCustomTimePeriod('daily')" :variant="custom_time_period === 'daily' ? 'dark' : 'light'" size="sm">D</b-button>
                                <b-button :disabled="data.tracking.length == 0" v-show="canShow('weekly')" @click="setCustomTimePeriod('weekly')" :variant="custom_time_period === 'weekly' ? 'dark' : 'light'" size="sm">W</b-button>
                                <b-button :disabled="data.tracking.length == 0" v-show="canShow('monthly')" @click="setCustomTimePeriod('monthly')" :variant="custom_time_period === 'monthly' ? 'dark' : 'light'" size="sm">M</b-button>
                                <b-button :disabled="data.tracking.length == 0" v-show="canShow('quarterly')" @click="setCustomTimePeriod('quarterly')" :variant="custom_time_period === 'quarterly' ? 'dark' : 'light'" size="sm">Q</b-button>
                                <b-button :disabled="data.tracking.length == 0" v-show="canShow('yearly')" @click="setCustomTimePeriod('yearly')" :variant="custom_time_period === 'yearly' ? 'dark' : 'light'" size="sm">Y</b-button>
                            </span>

                            <span v-if="middle_text !== ''" style="" class="me-2">
                                <b-button style="width: 4rem;" size="sm" variant="light">
                                    {{middle_text}}
                                </b-button>
                            </span>
                        </span>
                        
                        <span class="btn-group">
                            <ToolBar v-if="edit_mode && dataset_mode == 'default'" :is_kpi="true" :table_id="table_uid"/>
                            <b-button v-show="edit_mode == false" style="width: 2rem;" title="Number of linked datasets" variant="outline-dark" size="sm">
                                {{getDatasetCount}}
                            </b-button>
                            <b-button variant="outline-primary" size="sm" style="width: 2rem;"  @click="toggleGraphDatasetEditMode()"><!-- @click="edit_mode = !edit_mode" -->
                                <span v-show="edit_mode" title="Cancel">
                                    <font-awesome-icon icon="fa-solid fa-times" />
                                </span>
                                <span v-show="edit_mode == false" title="Show Data View">
                                    <font-awesome-icon icon="fa-solid fa-database" />
                                </span>
                            </b-button>
                            <b-button @click="expandGraph()" size="sm" style="width: 2rem;">
                                <!--<i class="fas fa-arrows-alt-v"></i>-->
                                <font-awesome-icon icon="fa-solid fa-arrows-alt-v" />
                            </b-button> 
                            <!--<b-button title="Show More Options" v-if="edit_mode == false" variant="outline-primary" size="sm" style="width: 2rem;" @click="show_more_options = !show_more_options">
                                    <i class="fas fa-ellipsis-v"></i>
                            </b-button>-->
                        </span>
                    </div>
                </div>
                <div>
                    <b-collapse v-model="show_more_options" v-if="edit_mode == false">
                        <div class="d-flex pt-1">
                            <span class="ms-auto btn-group">
                                <b-dropdown toggle-class="text-capitalize" variant="outline-info" size="sm">
                                    <template #button-content>
                                        {{displayTrackingFrequency}}
                                        <font-awesome-icon class="fa-fw ms-1" icon="fa-caret-down"/>
                                    </template>
                                    <b-dropdown-item v-if="canShow('weekly')" @click="setCustomTimePeriod('weekly')">
                                        View as Weekly
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="canShow('monthly')" @click="custom_time_period = 'monthly'">
                                        View as Monthly
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="canShow('yearly')" @click="custom_time_period = 'yearly'">
                                        View as Yearly
                                    </b-dropdown-item>
                                    <b-dropdown-item-button v-if="custom_time_period != data.current_time_period" @click="resetCurrentTrackingPeriod" variant="danger">Reset</b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown menu-class="dropdown-menu-end" right size="sm" variant="outline-primary" v-if="edit_mode == false">
                                    <template #button-content>
                                        Convert
                                        <font-awesome-icon class="fa-fw ms-1" icon="fa-caret-down"/>>
                                    </template>
                                    <b-dropdown-item v-if="canShow('weekly')" @click="traverseGraph('weekly')">
                                        Weekly Dataset
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="canShow('monthly')" @click="traverseGraph('monthly')">
                                        Monthly Dataset
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="canShow('yearly')" @click="traverseGraph('yearly')">
                                        Yearly Dataset
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-button size="sm" variant="danger" @click="deleteItem(data.id)">
                                    <i class="fas fa-trash"></i>
                                </b-button>
                            </span>
                        </div>
                    </b-collapse>
                    <!--
                    <span v-if="show_expanded_view == false && edit_mode == false && is_key_result == false">
                        <p>Showing All Data</p>
                    </span>
                    -->
                </div>
                <div v-if="!edit_mode">
                    <b-overlay :show="is_scrubber_loading" >
                        <div>
                            <apexchart
                            :ref="'mainChart'+graph_id"
                            type="area"
                            v-if="chart_config != null "
                            :height="200"
                            :options="chart_config"
                            :series="all_graph_series_top"
                            ></apexchart>
                            <b-collapse v-model="show_expanded_view">
                                    <apexchart
                                    :ref="'scrubber'+graph_id"
                                    v-if="scrubber_config != null "
                                    type="line"
                                    height="100"
                                    :options="scrubber_config"
                                    :series="all_graph_series"
                                ></apexchart>
                            </b-collapse>
                        </div>
                    </b-overlay>
                </div>
                <div class="pt-1" style="height: 14.5rem;" v-if="edit_mode && dataset_mode == 'shared'">
                    <!--<DatasetTable ref="sharedDatasetTable" @savedChanges="closeEditMode" @updated="was_data_updated = true" /> -->
                    <CombinedDatasetEditor ref="sharedDatasetTable" :share_type="data.share_type" :is_kpi="!is_metric" :is_metric="is_metric" @closeComponent="handleCombinedDatesetClose" />
                </div>
                <div class="pt-1" v-if="canShowDatasetEditor">
                    <div>
                        <transition name="fade">
                        <div style="min-height: 230px;" class="pt-1" > <!-- v-if="edit_mode && dataset_mode == 'default'" -->
                            <DataSetEditor  :item="data" is_kpi/>
                            <!-- <div @handleBack="handleDatasetBack" v-if="getSelectedTarget != null"></div> -->
                        </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
//eslint-disable-next-line
import { getWeekOfMonth, getMonthOfYear, getMonth, getQuarter} from 'date-fns';
//eslint-disable-next-line
import {format, parseISO} from 'date-fns';
//eslint-disable-next-line
import { eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns';
//eslint-disable-next-line
import { startOfMonth, endOfMonth } from 'date-fns';
//eslint-disable-next-line
import { startOfYear, endOfYear } from 'date-fns';
//eslint-disable-next-line
import { startOfWeek, endOfWeek } from 'date-fns';
//eslint-disable-next-line
import { addDays, addMonths, isBefore, subDays } from 'date-fns';
//eslint-disable-next-line
import { eachYearOfInterval, eachQuarterOfInterval, startOfQuarter, endOfQuarter } from 'date-fns';
import _ from 'lodash';
import { activityMethods, activityComputed, levelMethods, levelComputed, uxToggleComputed, uxToggleMethods, uxGraphMethods } from "@/state/helpers";
import EditGraph from "../../objectives/components/graph-components/KpiDatasetTable.vue";
import EditKRGraph from "../../objectives/components/graph-components/KeyResultDatasetTable.vue"
//eslint-disable-next-line
import ApexCharts from 'apexcharts'
import CombinedDatasetEditor from '../../objectives/components/graph-components/combindedDatasetTable.vue'
import { cardHeightMethods } from "@/state/helpers";

import ToolBar from './graphWidgets/datatableToolbar.vue'

import dateMixin from '@/mixins/dateMixin.js'
import graphHelpers from '@/mixins/graphHelpers.js'

import GraphHeader from '@/components/widgets/graphHeader.vue'
import DataSetEditor from '@/components/objectives/performance2/datasetEditor'
export default {
    mixins: [dateMixin, graphHelpers],
    components:{
        //eslint-disable-next-line
        EditGraph,
        //eslint-disable-next-line
        EditKRGraph,
        DataSetEditor,
        ToolBar,
        CombinedDatasetEditor,
        GraphHeader
    },
    props:{
        data:{
            required: true,
        },
        is_metric:{
            default: () => {
                return false;
            },
        },
        is_key_result:{
            default: () => {
                return false;
            },
        },
        middle_text:{
            default: '',
        },
        //* used to update different card states, if true will set the height state for the top kpis next to objectives
        is_top_graph:{
            default: false,
        }
    },
    data:() => ({
        table_uid: null,

        show: true,
        show_more_options: false,
        custom_time_period: null,
        //edit_mode: false,
        is_combined_dataset: false,
        chart_config: null,
        series:[],  //* graph series
        tracking_values:[],//* objects with date,target,achieved for the date_range
        date_range:{//* graphs starting and ending values
            start: null,
            end: null,
        },
        custom_date_range:{//* used when viewing a graph where the current_tracking_period != custom_tracking_period
            start: null,
            end: null,
        },
        value_range:{
            min: 0,
            max: 0
        },
        hasMounted: false,

        was_data_updated: false,//* track edit mode changes made,

        //* prevent watcher from firing while updating the custom_date_ranges
        //!  might end up being an anti-pattern. 
        freeze_watcher: false,
        graph_id:null, //* main graph id, used by the scrubber graph
        show_expanded_view: false,

        scrubber_series:[],
        scrubber_config: null,
        is_scrubber_loading: false,

        dataset_mode: 'default', // default or shared
        calculation_type: '',
    }),
    watch:{
        is_editing_metric_dataset(newVal){
            if(this.is_metric && newVal == false){
                this.calculation_type = '';
            }
        },
        is_editing_kpi_dataset(newVal){
            if(!this.is_metric && newVal == false){
                this.calculation_type = '';
            }
        },
        'graph_scrubber_state.is_kr_scrubber_expanded'(newValue){
            if(this.is_metric){
                return;
            }
            if(this.show_expanded_view != newValue){
                this.expandGraph();
            } 
        },
        //TODO - need to diff between kpi and metric refresh
        selected_key_performance_indicator(newVal){
            if(newVal != null){
                this.setDatasetMode()
            }
        },
        // edit_mode:{
        //     //immediate: true,
        //     handler(newValue){
        //         alert(`Edit mode state ${newValue}`);
        //         this.$emit('toggleEdit', this.edit_mode)
        //     }
        // },
        show_expanded_view(){
            if(this.show_expanded_view == true){
                if(this.is_top_graph){
                    this.setKpiHeight({
                        size: 'expanded',
                        type: 'kpi',
                    })
                }
                else{
                    this.setNewHeightState({
                        size: 'expanded',
                        type: 'kpi',
                    });
                }
                
            }
            else{
                if(this.is_top_graph){
                    this.setKpiHeight({
                        size: 'default',
                        type: 'kpi',
                    })
                }
                else{
                    this.setNewHeightState({
                        size: 'default',
                        type: 'kpi',
                    });
                }
            }
            this.$emit('expanded', this.show_expanded_view);
        },
        viewableRangeDate(){
            this.chart_config = this.buildGraphConfig();
        },
        custom_date_range:{
            deep: true,
            handler(){
                if(this.freeze_watcher == false){
                    this.processSeries();
                }
            }
        },
        custom_time_period(newValue){
            if(newValue != null && this.data.current_time_period != newValue){
                this.processSeries();
            }
        },
        data(oldVal, newVal){
            if(oldVal.id != newVal.id){
                //this.show = false;
                
                //this.scrubber_config = null;
                //this.graph_config = null;
                setTimeout(()=>{
                    //this.show = true;

                    this.initComponent();
                },1)
                
            }
            else{
                this.processSeries();
            }
            
        },
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...uxToggleComputed,
        getDatasetCount(){
            if(this.data.share_type == null){
                return 1;
            }
            else{
                return this.data.shared_graph_count;
            }
        },
        hasSharedDataset(){
            return this.data.share_type != null ? true : false;
        },
        edit_mode:{
            get(){
                return this.is_editing_kpi_dataset;
            },
            set(newValue){
                this.setGraphEditState({
                    type: 'kpi',
                    state: newValue,
                });
            }
        },
        canShowDatasetEditor(){
            let allowed = false;
            if(this.data){
                if(this.edit_mode && this.dataset_mode == 'default' && this.data.share_type == null){
                    allowed = true;
                }
            }
            return allowed;
        },
        getKRDate(){
            let dates = {
                start: this.data.start_date,
                end: this.data.end_date,
            }
            dates.start = format(parseISO(dates.start),'yyyy-MM-dd');
            dates.end = format(parseISO(dates.end),'yyyy-MM-dd');
            return dates;
        },
        getKpiDates(){
            let dates = {
                start: this.computedViewableRangeDate.range_start,
                end: this.computedViewableRangeDate.range_end,
            }
            dates.start = format(dates.start,'yyyy-MM-dd');
            dates.end = format(dates.end,'yyyy-MM-dd');
            return dates;
        },
        returnTrackingPeriodTitle(){
            if(this.data == null){
                return 'Loading';
            }

            if(this.data.current_time_period === this.custom_time_period){
                return `${this.data.current_time_period}`;
                //return `(${this.data.current_time_period})`;
            }
            else{
                return `${this.data.current_time_period} as ${this.custom_time_period}`
                //return `(${this.data.current_time_period} as ${this.custom_time_period})`
            }
        },
        activeUserID(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
              //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        top_graph_series(){
            if(this.data.current_time_period === this.custom_time_period){
                return this.all_graph_series;
            }
            else{
                let tracking = [];
                if(this.custom_time_period === 'weekly'){
                    let dates = [];
                    
                    dates = eachWeekOfInterval({ start:parseISO(this.date_range.start), end:parseISO(this.date_range.end) });
                    dates.forEach((date, idx) => {
                        let start = startOfWeek(date);
                        let end = endOfWeek(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(this.data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += this.data.tracking[index].value;
                                total_target += this.data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');
                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                //* process the dates to pipe into apex charts
                let array = [];
                tracking.forEach( (item) => {
                    let date = item.date;
                    if(date != 'NaN'){
                        array.push(
                            {
                                x: date,
                                y: item.value,
                            }
                        );
                    }
                    else{
                        alert('No')
                    }
                    
                });
                array = _.sortBy(array, function(obj) {
                    return new Date(obj.x);
                });
                
                if(array.length > 0){
                    //* insert a years worth of empty rows
                    let first_last_date = array[array.length -1].x;
                    last_date = new Date(last_date);
                    first_last_date = addDays(first_last_date, 1);
                    let last_date = array[array.length -1].x;
                    last_date = new Date(last_date);
                    let day = addMonths(last_date, 12);

                    array.push({
                        x: first_last_date.getTime(),
                        y: 0,
                    })
                    array.push({
                        x: day.getTime(),
                        y: 0,
                    })
                    /*if(this.is_key_result){
                        array.push({
                            x: first_last_date.getTime(),
                            y: 0,
                        })
                        array_2.push({
                            x: day.getTime(),
                            y: 0,
                        })
                    }*/
                }
                else{
                    //* create an empty years data
                    let first_date = startOfYear(new Date());
                    let last_date = endOfYear(new Date());
                    array.push({
                        x: first_date.getTime(),
                        y: null,
                    });
                    array.push({
                        x: last_date.getTime(),
                        y: null,
                    });
                }
                /*if(this.is_key_result){
                    return [
                        {name: 'Achieved', data: array},
                    ];
                }
                else{
                    
                }*/
                
                return [
                    { name: "Achieved", data: array,},
                ];
            }
        },
        all_graph_series(){
            if(this.data.multi_line){
                return this.all_graph_series_top;
            }
                let array = [];
                this.data.tracking.forEach( (item) => {
                    let date = format(parseISO(item.achieved_date), "dd MMM yyyy")
                    date = new Date(date + ' GMT');
                    date = date.getTime();
                    if(date != 'NaN'){
                        array.push(
                            {
                                x: date,
                                y: item.value,
                            }
                        );
                    }
                    else{
                        alert('No')
                    }
                    
                });
                array = _.sortBy(array, function(obj) {
                    return new Date(obj.x);
                });
                return [
                    {name: 'Achieved', data: array},
                ];
        },
        all_graph_series_top(){
            if(this.data.multi_line){
                return this.processMultilineValues(this.data, this.custom_time_period);
            }
            return this.processNormalValues(this.data, this.custom_time_period)
        },
        trackingDates(){
            //* the goal is to return all the valid dates for the
            if(this.date_range.start == null || this.date_range.end == null){
                return [];
            }
            let tracking_dates = [];
            //let start = new Date(format(parseISO(this.date_range.start), "dd MMM yyyy")+ ' GMT');
            //let end = new Date(format(parseISO(this.date_range.end), "dd MMM yyyy")+ ' GMT');
            let start = new Date(this.date_range.start);
            let end = new Date(this.date_range.end);
//format(parseISO(item.achieved_date), "dd MMM yyyy")
            //! NEED TO ADD A DAY TO THE START AND END
            //! for some reason the eachDayOf method array is offset by -1
            //start = addDays(start, 1);
            //end = addDays(end, 1);

            switch(this.data.current_time_period){
                case 'daily':{
                    tracking_dates = eachDayOfInterval({ start, end });
                    break;
                }
                case 'weekly':{
                    tracking_dates = eachWeekOfInterval({ start, end });
                    break;
                }
                case 'monthly':{
                    tracking_dates = eachMonthOfInterval({ start, end });
                    break;
                }
                case 'yearly':{
                    //alert('fix');
                    //tracking_dates = eachMonthOfInterval({ start, end });
                    tracking_dates = [];
                    break;
                }

            }
            return tracking_dates;
        },
        displayTrackingFrequency(){
            if(this.custom_time_period === this.data.current_time_period){
                //* Display default set period
                return `${this.data.current_time_period} Tracking`
            }
            else{
                //* if a custom period is set then the graph data will be a summary of data
                //* -------------------------------------------------
                //* eg if a graph is daily but the custom period is set to weekly then
                //* the weekly values displayed are just summed up daily values (not triggering the traversal endpoint)
                return `${this.data.current_time_period} Tracking Shown as ${this.custom_time_period}`
            }
        },
        calculationTypeTitle(){
            if(this.calculation_type == null || this.calculation_type == ''){
                return 'Select Calculation Mode'
            }
            else{
                return `${this.calculation_type} Mode`
            }
        },
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...cardHeightMethods,
        ...uxToggleMethods,
        ...uxGraphMethods,
        debugLinking(){
            let params = {
                share_type: "sum",
                parent_kpi_id: 831,
                graphs: [
                    { "linked_kpi_id": 828 },
                    { "linked_kpi_id": 829 }
                ]
            }
            this.saveSharedKpiGraph(params);
        },
        formatTooltipValue(index){
            const range = this.$store.getters['performance/objectives/viewableRangeDate'];
            let range_start = range.range_start;
            let range_end = range.range_end;
            let tooltips = this.getGraphLabelsAsDates(this.custom_time_period, range_start, range_end);
            if(tooltips[index-1] != undefined){
                return format(tooltips[index-1], 'EE dd MMMM');
            }
            else{
                return 'NA';
            }
        },
        isDataValidForPeriod(period){
            if(this.data.tracking.length == 0){
                return true;
            }
            let interval = {
                start: this.data.start_date,
                end: this.data.end_date,
            }
            //* note we need to return the opposite boolean value
            return this.isDatasetWithinPeriod(period, interval);
        },
        handleCombinedDatesetClose(){
            //let item = this.kpis.find( element => {
            //    return element.id == this.data.id;
            //} )
            //if(item){
            //    this.setSelectedKPI(item)
            //}

            //TODO VERIFY IF FIXED BUG
            this.$nextTick(()=>{
                this.toggleGraphDatasetEditMode();
            })
        },
        getUnitType(unit_type_id){
            let unit = this.keyResultUnit.find( item => item.id == unit_type_id);
            return unit != undefined ? unit.name : '';
        },
        handleTableUID(uid){
            this.table_uid = uid;
        },
        setCustomTimePeriod(type){
            // igniore daily type
            // if(type != 'daily' && this.isDataValidForPeriod(type) == false){
            //     this.$swal.fire({
            //         icon: 'info',
            //         title: 'Incompatible data',
            //         text: `This graphs data can not be displayed as ${type}.`
            //     })
            //     return false;
            // }
            this.is_scrubber_loading = true;
            setTimeout(()=>{
                this.custom_time_period = type;
                this.is_scrubber_loading = false;
            },150)
            
            /*if(this.show_expanded_view == false){
                this.expandGraph();
            }*/
        },
        returnMultilineDataset(){
            let series_data = _.cloneDeep(this.data);
            series_data.tracking = [];

            //* if no custom time perios is set - eg weekly graph is weekly
            if(series_data.current_time_period === this.custom_time_period){
                //* here we need to check if the graphs deadline date is the last date in the travking array
                let graph_series = []; //{ name, data }
                let el = this;
                series_data.multi_line_data.forEach(( graph )=>{
                    let name = `${graph.name} (${graph.owner_name})`;
                    let data = [];
                    graph.tracking.forEach(( item )=>{
                        let date = el.getDate(item.achieved_date);
                        if(date != 'NaN'){
                            data.push(
                                {
                                    x: date.getTime(),
                                    y: item.value,
                                }
                            );
                        }
                    })
                    data = _.sortBy(data, function(obj) {
                        return el.getDate(obj.x);
                    });
                    graph_series.push({ name, data });
                })
                el = undefined;
                return graph_series;
            }
            else{
                let tracking = [];
                let array = [];
                let array_2 = [];
                if(this.custom_time_period === 'weekly'){
                    let weekly_intervals = eachWeekOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    weekly_intervals.forEach((date, idx)=>{
                        let start = startOfWeek(date);
                        let end = endOfWeek(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'monthly'){
                    let monthly_intervals = eachMonthOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfMonth(date);
                        let end = endOfMonth(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }

                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'quarterly'){
                    let monthly_intervals = eachQuarterOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfQuarter(date);
                        let end = endOfQuarter(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'yearly'){
                    let monthly_intervals = eachYearOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfYear(date);
                        let end = endOfYear(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }

                //eslint-disable-next-line
                tracking.forEach( (item, item_idx) => {
                    let date = item.date;
                    if(date != 'NaN'){
                        array.push(
                            {
                                x: date,
                                y: item.value,
                            }
                        );
                        if(item.target_value < 1 && item_idx < 1){
                            array_2.push(
                                {
                                    x:date,
                                    y: 0,
                                }
                            )
                        }
                        else if(item.target_value > 0){
                            array_2.push(
                                {
                                    x:date,
                                    y: item.target_value,
                                }
                            )
                        }
                    }
                    else{
                        alert('No')
                    }
                    
                });
                //* achieved
                array = _.sortBy(array, function(obj) {
                    return new Date(obj.x);
                });
                //* target
                array_2 = _.sortBy(array_2, function(obj) {
                    return new Date(obj.x);
                });

                return [
                    {name: 'Achieved', data: array,},
                    {name: 'Target', data: array_2, }
                ]
            }
        },

        closeEditMode(clear_changes_flag = false){
            if(this.was_data_updated){
                let params = {
                    id: this.selected_objective.id,
                    user_id: this.activeUserID,
                }
                this.loadObjectiveDetailAPI(params).then(() => {
                    this.was_data_updated = false;
                    this.setGraphEditState({
                        type: 'kpi',
                        state: false,
                    })
                    //this.edit_mode = false;

                    this.processSeries();
                });
            }
            if(clear_changes_flag){
                this.was_data_updated = false;
                this.setGraphEditState({
                    type: 'kpi',
                    state: false,
                })
            }
        },
        resetCurrentTrackingPeriod(){
            this.custom_time_period = this.data.current_time_period;
            this.processSeries();
            if(this.show_expanded_view){
                this.expandGraph();
            }
        },
        setDateRange(){
            let start = new Date();
            let end = new Date();
            switch(this.data.current_time_period){
                case 'daily':{
                    start = startOfMonth(start);
                    end = endOfMonth(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'weekly':{
                    start = startOfMonth(start);
                    end = endOfMonth(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'monthly':{
                    start = startOfYear(start);
                    end = endOfYear(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'yearly':{
                    start = startOfYear(start);
                    end = endOfYear(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                default:{
                    start = null;
                    end = null;
                }
            }
            
            this.date_range = {
                start: start,
                end: end,
            }
            this.custom_date_range = _.cloneDeep(this.date_range);
        },
        processSeries(){
            return new Promise( resolve => {
                let series_a = [];
                //let series_b = [];
                this.tracking_values = [];

                //* flag is set to false when there is a valid index found in th loop below
                let contains_empty_values = true;
                //* if flag is true then set the series values as []
                

                //! should make this it's own method but ehh
                if(this.data.current_time_period === this.custom_time_period){
                    //* If custom and current periods match then just find the values and push to the series array
                    this.trackingDates.forEach( date => {
                        let current_date = format(date, "dd-MM-yyyy");
                        let index = _.findIndex(this.data.tracking, (item) => {
                            let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                            return item_date == current_date;
                        });
                        if (index != -1) {
                            this.tracking_values.push({
                                date: current_date,
                                value: this.data.tracking[index].value,
                                target_value: this.data.tracking[index].target_value,
                                id: this.data.tracking[index].id,
                            });
                            //* valid tracking value found
                            contains_empty_values = false;
                        }
                        else {
                            this.tracking_values.push({
                                date: current_date,
                                value: 0,
                                target_value: 0,
                                isEmpty: true,
                            });
                        }
                    });
                    if(contains_empty_values == false){
                        this.tracking_values.forEach((item) => {
                            let date_arr = item.date.split("-");
                            let new_date = new Date(date_arr[2]+' '+date_arr[1]+' '+date_arr[0] +' GMT');
                            let formatted_date = new_date.getTime();//this.formatTrackingDate(item.date);
                            series_a.push({ x: formatted_date, y: item.value });
                            //series_b.push({ x: formatted_date, y: item.target_value });
                        });
                    }
                }
                else{
                    //* Here we need to sum the current tracking values to the custom period set.
                    let range_start = this.date_range.start;
                    let range_end = this.date_range.end;
                    if(this.custom_time_period === 'weekly'){
                        this.processCustomWeeklySeries(range_start, range_end);
                    }
                    if(this.custom_time_period === 'monthly'){
                        this.freeze_watcher = true;
                        this.custom_date_range.start = format(startOfYear(parseISO(this.custom_date_range.start)), 'yyyy-MM-dd');
                        range_start = this.custom_date_range.start;
                        this.custom_date_range.end = format(endOfYear(parseISO(this.custom_date_range.end)), 'yyyy-MM-dd');
                        range_end = this.custom_date_range.end;
                        this.$nextTick(()=>{
                            this.freeze_watcher = false;
                        })
                        this.processCustomMonthlySeries(range_start, range_end);
                    }
                    /*if(this.custom_time_period === 'yearly'){
                        this.freeze_watcher = true;
                        this.custom_date_range.start = format(startOfYear(parseISO(this.custom_date_range.start)), 'yyyy-MM-dd');
                        range_start = this.custom_date_range.start;
                        this.custom_date_range.end = format(endOfYear(parseISO(this.custom_date_range.end)), 'yyyy-MM-dd');
                        range_end = this.custom_date_range.end;
                        this.$nextTick(()=>{
                            this.freeze_watcher = false;
                        });
                        this.processCustomYearlySeries(range_start, range_end);
                    }*/
                    this.tracking_values.forEach((item) => {
                        let formatted_date = item.date
                        series_a.push({ x: formatted_date, y: item.value });
                        //series_b.push({ x: formatted_date, y: item.target_value });
                    });
                }
                

                this.series = [
                    {
                        name: "Achieved",
                        data: series_a,
                    },
                    /*{
                        name: "Target",
                        data: series_b,
                    }*/
                ];
                resolve();
            })
        },
        initChart(){
            this.processSeries()
            .then(()=>{
                this.chart_config = this.buildGraphConfig();
            })
        },
        //eslint-disable-next-line
        setGraphZoom(){
            //let min = new Date(this.date_range.start);
            //let max = new Date(this.date_range.end);
            this.$refs[`mainChart${this.graph_id}`].updateOptions({
                        yaxis: {
                            max: undefined,
                        }
                    })

        },
        expandGraph(){
            this.show_more_options = false;
            if(this.show_expanded_view){
                this.is_scrubber_loading = true;
                this.scrubber_config = null;
                this.show_expanded_view = false;
                this.setScrubberState({
                    type: this.is_metric ? 'metric' : 'kpi',
                    state: this.show_expanded_view,
                });
                //* Delay because nextTick is too short, could try move this code to the destroy event of the graph scrubber
                setTimeout(()=>{
                    //this.$refs[`mainChart${this.graph_id}`].resetSeries();
                    this.setGraphZoom();
                    this.is_scrubber_loading = false;
                },200)
                //this.setDateRange();
            }
            else{
                this.is_scrubber_loading = true;
                this.$nextTick(()=>{
                    this.show_expanded_view = true;
                    this.buildScrubberConfig();
                    this.setScrubberState({
                        type: this.is_metric ? 'metric' : 'kpi',
                        state: this.show_expanded_view,
                    });
                })
                
            }
        },
        buildGraphConfig(){
            //* hide legend when the data.tracking array is empty
            //let show_legend = this.data.tracking.length > 0 ? true : false
            this.graph_id = window.webix.uid();
            const range = this.$store.getters['performance/objectives/viewableRangeDate'];
            let range_start = this.getBackendDateFormat(range.range_start);
            let range_end = this.getBackendDateFormat(range.range_end);
            const categories = this.getGraphLabels(this.custom_time_period, range_start, range_end);

            

            this.$emit('graphID', this.graph_id);
            let el = this;
            let config = {
                legend:{
                    position: 'bottom',
                    fontSize: '13px'
                },
                chart: {
                    parentHeightOffset: '0px',
                    id: this.graph_id,
                    /*sparkline: {
                        enabled: true
                    },*/
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 200,
                    },
                    events:{
                        
                        //click: function(context, config){
                        //    el.expandGraph();
                        //},
                        //eslint-disable-next-line
                        // legendClick: function(chartContext, seriesIndex){
                        //     if(el.$refs[`scrubber${el.graph_id}`]){
                        //         let series_name = el.all_graph_series_top[seriesIndex].name;
                        //         el.$refs[`scrubber${el.graph_id}`].toggleSeries(series_name);
                        //     }
                        // },
                        //eslint-disable-next-line
                        // mounted: function(context, config){
                        //     el.setGraphZoom();
                        // }
                    },
                        type: "area",
                        foreColor: "#ccc",
                        toolbar: {
                        autoSelected: "pan",
                        show: false
                        },
                    },
                    
                    //colors: ["#00BAEC", "#12EC00"],
                    stroke: {
                        width: 2,
                    },
                    grid: {
                        borderColor: "#555",
                        clipMarkers: false,
                        yaxis: {
                        lines: {
                            show: false
                        }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        gradient: {
                        enabled: false,
                        opacityFrom: 0.3,
                        opacityTo: 0
                        }
                    },
                    markers: {
                        size: 0,
                        //colors: ["#041cb5"],
                        //strokeColor: ["#00BAEC", "#ff00ec"],
                        strokeWidth: 3
                    },
                    tooltip:{
                        x:{
                            formatter(value){
                                return el.formatTooltipValue(value)
                            }
                        }
                    },
                    xaxis: {
                        type: 'category',
                        //tickAmount: 'dataPoints',
                        categories:categories,
                        labels: {
                            rotate: -45,
                            rotateAlways: el.custom_time_period == 'weekly',
                            //hideOverlappingLabels: true,
                            formatter: function (value) {
                                if(value == undefined){
                                    return '';
                                }
                                let date_obj = el.parseDateString(value)
                                switch(el.custom_time_period){
                                    case 'weekly':{
                                        return 'W' + getWeekOfMonth(new Date(date_obj)) + ' ' + format(new Date(date_obj),'MMM');
                                    }
                                    case 'monthly':{
                                        return format(new Date(date_obj),'MMM') + ' ' + format(new Date(date_obj),'yy');
                                    }
                                    case 'quarterly':{
                                        return format(new Date(date_obj),'QQQ') + ' ' + format(new Date(date_obj),'yyyy');
                                    }
                                    case 'yearly':{
                                        return format(new Date(date_obj),'yyyy');
                                    }
                                    default:{
                                        return format(new Date(date_obj), 'dd MMM yy');
                                    }
                                }
                                //return el.getSimpleDate(date_obj, '**');
                            }
                        },
                    },
                    yaxis: {
                        min: 0,
                        //tickAmount: 1
                        forceNiceScale: true,
                    },
                noData: {
                    text: 'No Chart Data',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: 'grey',
                        fontSize: '14px',
                        //fontFamily: undefined
                    }
                },
            }
            // if(show_legend == false){
            //     config.xaxis.labels = { show: false };
            // }
            // else{
            //     config.xaxis.labels = { show: true };
            // }

            return config;
        },
        handleBrushScroll: _.debounce(function(start_date, end_date){
            this.is_scrubber_loading = true;
            let start = new Date(start_date);
            let end = new Date(end_date);
            let dates = eachDayOfInterval({ start, end });
            let yaxis_scale = 0;
            if(this.data.multi_line){
                this.all_graph_series_top.forEach(( series )=>{
                    dates.forEach( date => {
                        let tracking_index = series.data.findIndex( tracking_obj => {
                            return this.getSimpleDate(tracking_obj.x) == this.getSimpleDate(date);
                        })
                        if(tracking_index != -1){
                            if(series.data[tracking_index].y > yaxis_scale){
                                yaxis_scale = series.data[tracking_index].y
                            }
                        }
                    })
                })
            }
            else{
                dates.forEach( date => {
                    let tracking_index = this.all_graph_series_top[0].data.findIndex( tracking_obj => {
                        return this.getSimpleDate(tracking_obj.x) == this.getSimpleDate(date);
                    })
                    if(tracking_index != -1){
                        if(this.all_graph_series_top[0].data[tracking_index].y > yaxis_scale){
                            yaxis_scale = this.all_graph_series_top[0].data[tracking_index].y
                        }
                    }
                })
            }

            
            //only bother to set scale if is a valid no
            if(yaxis_scale > 0){
                setTimeout(()=>{
                    this.$refs[`mainChart${this.graph_id}`].updateOptions({
                        yaxis: {
                            max: yaxis_scale,
                        }
                    })
                    this.is_scrubber_loading = false;
                }, 250)
            }
            else{
                this.is_scrubber_loading = false;
            }
        }, 300),
        buildScrubberConfig(){
            let el = this;
            this.is_scrubber_loading = true;
            //! TAKE NOTE!!!!!!!!!!!!!
            //* The month value is 0 indexed so we need to subtract 1
            //let date_arr = this.date_range.start.split("-");
            //let start = new Date(this.data.start_date);
            //let date_arr_end = this.date_range.end.split("-");
            //let end = new Date(this.data.deadline);
            //* damn..
            let config = {
                chart: {
                    parentHeightOffset: '0px',
                    id: "brushChart"+this.graph_id,
                    events:{
                        mounted:function(){
                            el.is_scrubber_loading = false;
                        },
                        //eslint-disable-next-line
                        brushScrolled: function(chartContext, { xaxis, yaxis }) {
                            el.handleBrushScroll(xaxis.min, xaxis.max);
                        },
                    },
                    height: 150,
                    type: "line",
                    foreColor: "#ccc",
                    brush: {
                    target: this.graph_id,
                    enabled: true
                    },
                    animations:{
                        enabled: false,
                    },
                    selection: {
                        enabled: true,
                        //fill: {
                            //color: "#C7F3FF",
                            //opacity: 0.4
                        //},
                        xaxis: {
                            //min: start.getTime(),
                            //max: end.getTime()
                            /*min: start.getTime(),
                            max: end.getTime()*/
                        }
                    }
                },
                legend:{
                    show: false,
                },
                //colors: ["#00BAEC", "#ff00ec"],
                //series: this.all_graph_series,
                stroke: {
                    width: 2
                },
                grid: {
                    borderColor: "#444"
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    //min: start.getTime(),
                    //max: end.getTime(),
                    type: "datetime",
                    /*tooltip: {
                    enabled: false
                    }*/
                },
                
                yaxis: {
                    tickAmount: 1
                }
            }
            this.scrubber_config = config;
        },
        formatTrackingDate(date){
            let date_arr = date.split("-");
            let new_date = new Date(date_arr[2]+' '+date_arr[1]+' '+date_arr[0] +' GMT');
            if(this.data.current_time_period === 'daily'){
                return format(new_date, 'iii dd MMM yyyy');
            }
            else{
                return format(new_date, 'dd MMM yyyy');
            }
            
        },
        processCustomWeeklySeries(range_start, range_end){
            let dates = [];
            dates = eachWeekOfInterval({ start:parseISO(range_start), end:parseISO(range_end) });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfWeek(date);
                let end = endOfWeek(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');
                this.tracking_values.push({
                    
                    //date: `${format(start, "dd MMM")} > ${format(end, "dd MMM")}`,
                    date: formatted_date.getTime(),
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        processCustomMonthlySeries(range_start, range_end){
            let dates = [];
            dates = eachMonthOfInterval({ start:parseISO(range_start), end:parseISO(range_end) });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfMonth(date);
                let end = endOfMonth(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');
                this.tracking_values.push({
                    date: formatted_date.getTime(),
                    //date: `${format(start, "dd MMM")} > ${format(end, "dd MMM")}`,
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        processCustomYearlySeries(range_start, range_end){
            let dates = [];
            dates = eachMonthOfInterval({ start:range_start, end:range_end });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfMonth(date);
                let end = endOfMonth(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                this.tracking_values.push({
                    date: 'current_date',
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        canShow(type){  
            let valid = false;
            switch(this.data.current_time_period){
                case 'daily':{
                    if(type === 'daily' || type === 'weekly' || type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'weekly':{
                    if(type === 'weekly' || type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'monthly':{
                    if(type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'quarterly':{
                    if(type === 'quarterly' || type === 'yearly'){
                        valid = true;
                    }
                    break;
                }
                case 'yearly':{
                    if(type === 'yearly'){
                        valid = true;
                    }
                    break;
                }
            }
            return valid;
        },
        emitGraphSize(size){
            this.$emit('updateColSize', size);
        },
        traverseGraph(type){
            if(this.is_metric){
                let params = {
                    metric_id: this.data.id,
                    type: type,
                }
                this.traverseMetric(params)
                .then(()=>{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserID,
                    }
                    this.loadObjectiveDetailAPI(params).then(() => {
                        this.processSeries();
                    });
                })
            }
            else if(this.is_key_result){
                //* kr graphs

            }
            else{
                //* kpi graphs
                let params = {
                    kpi_id: this.data.id,
                    type: type,
                }
                this.traverseKPI(params)
                .then(()=>{
                    this.reloadData();
                })
            }
            
        },
        deleteItem(id){
            if(this.is_metric){
                let params = {
                    metric_id: id,
                }
                this.this.deleteMetric(params)
                .then(()=>{
                    this.reloadData();
                })
            }
            else if(this.is_key_result){
                console.log('handle delete')
            }
            else{
                let params = {
                    kpi_id: id,
                };
                this.deleteKpi(params)
                .then(()=>{
                    this.reloadData();
                })
            }
        },
        reloadData(){
            let params = {
                id: this.selected_objective.id,
                user_id: this.activeUserID,
            }
            this.loadObjectiveDetailAPI(params).then(() => {
                this.processSeries();
            });
        },
        toggleDatasetType(new_type){
            if(this.calculation_type == ''){
                this.dataset_mode = new_type;
            }

            if(this.dataset_mode === 'shared' && new_type === 'default'){
                this.$swal.fire({
                    title: "Warning!",
                    text: "Click yes to unlink all shared graphs. You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, unlink all!",
                }).then((result) => {
                    if (result.value) {
                        this.$refs['sharedDatasetTable'].removeSharedDataset();
                    }
                });
            }
            else{
                this.dataset_mode = new_type;
            }
        },
        setDatasetMode(){
            if(this.is_metric){
                if(this.selected_metric.share_type !== null){
                    this.dataset_mode = 'shared';
                    this.calculation_type = this.selected_metric.share_type;
                }
                else{
                    this.dataset_mode = 'default';
                }
            }
            else{ // is kpi
                if(this.selected_key_performance_indicator.share_type !== null){
                    this.dataset_mode = 'shared';
                    this.calculation_type = this.selected_key_performance_indicator.share_type;
                }
                else{
                    this.dataset_mode = 'default';
                }
            }
        },
        setCalculationType(type){
            this.calculation_type = type;
            this.$refs['sharedDatasetTable'].updateCalculationMode(type);
        },
        saveSharedDataset(){
            this.$eventHub.$emit('saveSharedDataset');
        },
        showModal(){
            //tell parent component to display this graph component in a fullscreen modal
            this.$emit('openFullscreenModal', this.data.id);
        },
        toggleGraphDatasetEditMode(){
            this.setDatasetMode();
            this.setGraphEditState({
                type: 'kpi',
                state: !this.is_editing_kpi_dataset,
            })
            //this.edit_mode = !this.edit_mode;
            //alert(this.selected_key_result.share_type + ' --> ' + this.data.share_type);
            
        },
        initComponent(){
            this.custom_time_period = this.data.current_time_period;
            this.value_range = { min: this.data.min_value, max: this.data.max_value };
            this.setDateRange();
            this.initChart();
            this.$nextTick(()=>{ this.hasMounted = true; })
        }
    },
    mounted(){
        this.initComponent();
        if(this.data != null){
            this.setDatasetMode();
        }
    },
    beforeDestroy(){
        if(this.$refs['mainChart'+this.graph_id]){
            this.$refs['mainChart'+this.graph_id].destroy();
        }
        if(this.$refs['brushChart'+this.graph_id]){
            this.$refs['brushChart'+this.graph_id].destroy();
        }
    }
}
</script>

<style>
.graph-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.graph-toolbar-text{
    flex-basis: auto;
    text-align: left;
}
.graph-toolbar-buttons{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    /*gap: 10px;
    margin-top: 10px;*/
}
    .apexcharts-legend-text{
        font-size: 12px !important;
    }


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>