<template>
    <b-modal size="sm" hide-footer id="linkingModal" title="Graph Linking">
        <b-row>
            <b-col cols="12">
                <h5>Select New Linking Type</h5>
            </b-col>
            <b-col>
                <b-button
                v-if="isLevelLinked == false"
                @click="linkToLevel">
                    Level</b-button>&nbsp;

                <b-button v-if="isKeyResultLinked == false && hasKeyResult"
                @click="linkToKeyResult">
                    Key Result</b-button>&nbsp;

                <b-button v-if="isObjectiveLinked == false && hasObjective"
                @click="linkToObjective">
                    Objective</b-button>
            </b-col>
        </b-row>
    </b-modal> 
</template>

<script>
import { levelComputed, activityMethods, activityComputed } from "@/state/helpers";
export default {
    props:{
        type:{
            required: true,
        },
        linking_item:{
            required: true,
        }
    },
    data:() => ({}),
    computed:{
        ...activityComputed,
        ...levelComputed,
        isObjectiveLinked(){
            if(this.linking_item.milestone_objective_id != null){
                return true;
            }
            else{
                return false;
            }
        },
        isLevelLinked(){
            if(this.linking_item.level_id != null){
                return true;
            }
            else{
                return false;
            }
        },
        isKeyResultLinked(){
            if(this.linking_item.key_result_id != null){
                return true;
            }
            else{
                return false;
            }
        },

        hasKeyResult(){
            if(this.selected_key_result != null){
                return true;
            }
            else{
                return false;
            }
        },
        hasObjective(){
            if(this.selected_objective != null){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...activityMethods,
        closeModal(){
            this.$bvModal.hide('linkingModal');
        },
        linkToLevel(){
            if(this.type === 'kpi'){
                let params = {
                    kpi_id: this.linking_item.id,
                    level_id: this.selected_level.id,
                }
                this.linkKpi(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
            if(this.type === 'metric'){
                let params = {
                    metric_id: this.linking_item.id,
                    level_id: this.selected_level.id,
                }
                this.linkMetric(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
        },
        linkToObjective(){
            if(this.type === 'kpi'){
                let params = {
                    kpi_id: this.linking_item.id,
                    objective_id: this.selected_objective.id,
                }
                this.linkKpi(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
            if(this.type === 'metric'){
                let params = {
                    metric_id: this.linking_item.id,
                    objective_id: this.selected_objective.id,
                }
                this.linkMetric(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
        },
        linkToKeyResult(){
            if(this.type === 'kpi'){
                let params = {
                    kpi_id: this.linking_item.id,
                    key_result_id: this.selected_key_result.id,
                }
                this.linkKpi(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
            if(this.type === 'metric'){
                let params = {
                    metric_id: this.linking_item.id,
                    key_result_id: this.selected_key_result.id,
                }
                this.linkMetric(params)
                .then(()=>{
                    this.closeModal();
                    this.$eventHub.$emit('newLevelSelected');
                })
                .catch((err)=>{
                    console.log('Linking Error', err);
                })
            }
        }
    },

}
</script>

<style>

</style>