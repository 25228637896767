<template>
    <div>
        <b-overlay opacity="0.8" :show="is_reloading_graph_data" >
            <webix-ui v-model="item_tracking_data" :config="ui"></webix-ui>
            <template #overlay>
                <div class="text-center">
                    <b-spinner label="Loading..."></b-spinner>
                    <h5>Updating new graph data...</h5>
                </div>
            </template>
        </b-overlay>
        <!-- //* TARGETS and KPIS data modal -->
        <kpiDataModal
        v-if="show_kpi_data_modal"
        :payload="modal_payload"
        :parent_tracking_period="getSelectedTarget.current_time_period"
        @nextDate="handleNextDate"
        @prevDate="handlePrevDate"
        @saveData="handleSaveEvent"
        @refreshData="handleRefreshEvent"
        />
    </div>
</template>

<script>
import { adminComputed, performanceMethods, performanceComputed, levelComputed, uxToggleMethods, uxToggleComputed } from '@/state/helpers'
import kpiDataModal from '../components/misc/activityModals/kpiDataModal.vue'
import { startOfMonth, endOfMonth, eachWeekOfInterval, startOfYear, eachMonthOfInterval, eachDayOfInterval, eachYearOfInterval,eachQuarterOfInterval , endOfWeek , endOfYear, endOfQuarter, isBefore, format, parseISO, differenceInMilliseconds, parse, isWeekend} from 'date-fns';
import _ from 'lodash';
export default {
    components: {
        kpiDataModal
    },
    props:{
        item:{
            required: true,
        },
        is_kpi:{
            default: false,
            type: Boolean,
        }
    },
    data:() => ({
        show_cumulative_value: 0,
        show_cumulative_target_value: 0,
        changes_made_flag: false,
        show_kpi_data_modal: false,
        webix_resize_event: null,
        modal_payload: null,
        last_item_id: -1,
    }),
    watch:{
        item:{
            deep: true,
            handler(){
                this.setGraphLoadingState(false);
                //reset values used for summing cumulative values
                this.show_cumulative_target_value = 0;
                this.show_cumulative_value = 0;
            },
        },
        table_columns(){
            const current_item_id = this.item.id;
            const table = window.webix.$$('datasetTable')
            let state = table.getState();
            table.refreshColumns(this.table_columns);
            table.refresh();  
            if(this.last_item_id == current_item_id){
                this.$nextTick(()=>{
                    table.setState(state);
                })
            }
            this.last_item_id = current_item_id;
        }
    },
    computed:{
        ...uxToggleComputed,
        ...performanceComputed,
        ...levelComputed,
        ...adminComputed,
        isCompanyLevelSelected(){
            return this.company_id == this.current_level_id;
        },

        ui(){
            let el = this;
            let config = {
                css: "webix_data_border webix_header_border datasetTable",
                scrollX: true,
                autoheight: true,
                id: 'datasetTable',
                view: 'treetable',
                columns: el.table_columns,
                select: 'cell',
                resizeColumn:{headerOnly:true},
                editable: false,
                data:[],
                leftSplit: 2,
                undo: true,
                ready(){
                    //* scroll to column on init
                    el.scrollToColumn();
                    el.last_item_id = el.item.id;
                    if(this.exists('kpis_row')){
                        this.open('kpis_row')
                    }
                },
                on:{
                    onBeforeEditStart(cell){
                        const item = this.getItem(cell.row);
                        if(item.block_edit){
                            return false;
                        }
                    },
                    //eslint-disable-next-line
                    onAfterEditStop(state, editor){
                        const did_value_change = state.value != state.old
                        if(did_value_change == false){
                            return;
                        }

                        let number_value = state.value.replace(/,/g, '.');
                        const is_number = isNaN(Number(number_value)) == false;
                        if(!is_number){
                            this.undo();
                            setTimeout(()=>{
                                el.$swal.fire(`Error saving value '${state.value}'. Please use only numeric values.`);
                            }, 150)
                            return;
                        }

                        const date_string = format(new Date(editor.column), 'yyyy-MM-dd')

                        const edited_item = this.getItem(editor.row);
                        if(edited_item.type == 'target'){
                            if(edited_item.name == "Achieved"){
                                let target_value = this.getItem('target')[editor.column]
                                let payload = {
                                    tracking_data: [
                                        {
                                            "value": Number(state.value),
                                            "notes": "",
                                            "achieved_date": date_string,
                                            "target": Number(target_value)
                                        }
                                    ],
                                    "target_id": el.item.id
                                }
                                el.saveTargetTracking(payload);
                                el.changes_made_flag = true;
                            }
                            else if(edited_item.name == "Target"){
                                let achieved_value = this.getItem('achieved')[editor.column]
                                let payload = {
                                    tracking_data: [
                                        {
                                            "value": Number(achieved_value),
                                            "notes": "",
                                            "achieved_date": date_string,
                                            "target":  Number(state.value),
                                        }
                                    ],
                                    "target_id": el.item.id
                                }
                                el.saveTargetTracking(payload);
                                el.changes_made_flag = true;
                            }
                        }
                        else if(edited_item.type == 'kpi'){
                            let payload = {
                                "tracking_data": [
                                    {
                                        "value": Number(state.value),
                                        "notes": "",
                                        "achieved_date": date_string,
                                        "target": 1
                                    }
                                ],
                                "kpi_id": editor.row,
                            }
                            el.saveKeyPerformanceTracking(payload);
                            el.changes_made_flag = true;
                        }
                    },
                    onItemClick(obj){
                        // Get selected cels column config, to check if the click should be blocked for weekend dates
                        let col = this.getColumnConfig(obj.column);
                        let has_weekend_flag = true;
                        if(has_weekend_flag == false){
                            el.handleClickEvent(obj);
                        }
                        else{
                            if(col.is_weekend == false){
                                el.handleClickEvent(obj);
                            }
                            else{
                                el.$swal.fire({ title:'Weekend dates are disabled', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true})
                            }
                        }
                    }
                }
            }
            return config;
        },
        table_columns(){
            let cols = [
                {id: 'name', header: 'Datasets', fillspace: true, minWidth: 250, template: (obj, common) => {
                    if(obj.$count == 0){
                        switch(obj.name){
                            case "Target Cumulative":{
                                return  `<i style="color: rgb(148, 161, 179); font-size: 1em; vertical-align: middle; margin-right: 1em; margin-bottom: 0.3em; margin-left: 2em; padding-right: 0.2em" class="fas fa-sort-amount-up"></i><span class="normal-text tree_styles">${obj.name}</span>`;
                            }
                            case "Achieved":{
                                return  `<i style="color: rgb(148, 161, 179); font-size: 1em; vertical-align: middle; margin-right: 1em; margin-bottom: 0.3em; margin-left: 2em; padding-right: 0.2em" class="fas fa-star-half-alt"></i><span class="normal-text tree_styles">${obj.name}</span>`;
                            }
                            case "Target":{
                                return  `<i style="color: rgb(148, 161, 179); font-size: 1em; vertical-align: middle; margin-right: 1em; margin-bottom: 0.3em; margin-left: 2em; padding-right: 0.2em" class="fas fa-trophy"></i><span class="normal-text tree_styles">${obj.name}</span>`;
                            }
                            case "Achieved Cumulative":{
                                return  `<i style="color: rgb(148, 161, 179); font-size: 1em; vertical-align: middle; margin-right: 1em; margin-bottom: 0.3em; margin-left: 2em; padding-right: 0.2em" class="fas fa-star"></i><span class="normal-text tree_styles">${obj.name}</span>`;
                            }
                            default :{
                                return  `<i style="color: rgb(148, 161, 179); font-size: 1em; vertical-align: middle; margin-right: 1.5em; margin-bottom: 0.3em; margin-left: 2em; padding-right: 0.2em" class="fas fa-thermometer-half"></i><span class="normal-text tree_styles">${obj.name}</span>`;
                            }
                        }
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}</span>`;
                    }
                },},
                {
                    id: 'tracking_type', width: 42, header: '', format(value){
                        if(!value){
                            return '';
                        }
                        let letter = value.charAt(0).toUpperCase();
                        return `<kbd>${letter}</kbd>`
                    }
                }
            ];
            //* Get array of date range
            let dates = this.getRange();
            let include_weekends = this.item.include_weekends == 0 && this.item.current_time_period == 'daily';

            //* Loop date range and create webix column for date
            dates.forEach( date => {
                let is_weekend = false;
                if(include_weekends){
                    is_weekend = isWeekend(date);
                }
                cols.push({
                    id: format(date, "dd-MMM-yyyy"),
                    header: format(date, "dd-MMM"),
                    editor: 'text',
                    width: 80,
                    is_weekend: is_weekend,
                    format(value){
                        if(value != ""){
                            let table_value = value.toLocaleString();
                            if(table_value.includes(',')){
                                return table_value;
                            }
                            else{
                                if (table_value % 1 === 0) {
                                    return Number(table_value).toFixed(0); // No decimals for whole numbers
                                } else {
                                    return Number(table_value).toFixed(2); // Two decimals for numbers with decimal part
                                }
                            }
                            //let html = `<span class="no-decimal" data-value="${value.toLocaleString()}"></span>`
                            //return html;
                        }
                        return ""
                    },
                    cssFormat: !is_weekend ? this.makeCellHover : this.makeCellDisabled
                })
            })
            return cols;

        },
        item_tracking_data(){
            let target_row = {};
            target_row.name = this.item.name;
            target_row.tracking_type = '';
            //target_row.tracking_type = this.item.current_time_period,
            target_row.id = this.item.id
            target_row.block_edit = true,
            target_row.open = true
            target_row.data = [
                {name: 'Achieved', owner_user_id: this.item.owner_user_id, id: `achieved-${this.item.id}`, $parent: 'root', type:"target", tracking_type :this.item.current_time_period},
            ];
            target_row.data1 = [
                {name: 'Target', owner_user_id: this.item.owner_user_id, id: `target-${this.item.id}`, type:"target", tracking_type :this.item.current_time_period},
            ];

            if(this.item.show_cumulative == 1) {
                let achieved_cumulative_row = {name: 'Achieved Cumulative', id: 'achieved_cumulative', type:"target", block_edit: true}
                target_row.data.push(achieved_cumulative_row)
                let dates = this.getRange();
                dates.forEach( date => {
                    let date_string = format(date, "dd-MMM-yyyy");
                    let found_tracking = this.item.tracking.find( tracking_item => {
                        let date_to_compare = format(parseISO(tracking_item.achieved_date), "dd-MMM-yyyy");
                        return date_string == date_to_compare;
                    })
                    if(found_tracking){
                        let combineValues = Number(this.show_cumulative_value) + Number(found_tracking.value) 
                        target_row.data[1][date_string] = combineValues;
                        this.show_cumulative_value = combineValues
                    }
                    else{
                        target_row.data[1][date_string] = '';
                    }
                })
            }

            if(this.item.show_cumulative_target == 1) {
                let target_cumulative_row = {name: 'Target Cumulative', id: 'target_cumulative', type:"target", block_edit: true}
                target_row.data1.push(target_cumulative_row)
                let dates = this.getRange();
                dates.forEach( date => {

                    let date_string = format(date, "dd-MMM-yyyy");
                    let found_tracking = this.item.tracking.find( tracking_item => {
                        let date_to_compare = format(parseISO(tracking_item.achieved_date), "dd-MMM-yyyy");
                        return date_string == date_to_compare;
                    })
                    if(found_tracking){
                        let combineTargetsValues = Number(this.show_cumulative_target_value) + Number(found_tracking.target_value)
                        target_row.data1[1][date_string] = combineTargetsValues;    
                        this.show_cumulative_target_value = combineTargetsValues
                    }
                    else{
                        target_row.data1[1][date_string] = '';
                    }
                })
            }
            //Combine the two sets of data
            let dataSet1 = target_row.data
            let dataSet2 = target_row.data1
            let value = dataSet1.concat(dataSet2)
            target_row.data = value

            let kpis_row = {
                name: 'KPIS',
                block_edit: true,
                data:[],
                id: 'kpis_row',
                open: true,
            }

            let dates = this.getRange();
            dates.forEach( date => {

                let date_string = format(date, "dd-MMM-yyyy");
                let found_tracking = this.item.tracking.find( tracking_item => {
                    let date_to_compare = format(parseISO(tracking_item.achieved_date), "dd-MMM-yyyy");
                    return date_string == date_to_compare;
                })
                if(found_tracking){
                    target_row.data[0][date_string] = found_tracking.value;
                        if(this.item.show_cumulative == 1) {
                            target_row.data[2][date_string] = found_tracking.target_value;
                        } else {
                            target_row.data[1][date_string] = found_tracking.target_value;
                        }
                    
                }
                else{
                    target_row.data[0][date_string] = '';
                    if(this.item.show_cumulative == 1) {
                        target_row.data[2][date_string] = '';
                    } else {
                        target_row.data[1][date_string] = '';
                    }
                }
                
                //data.data[1][format(date, "dd-MMM-yyyy")] = 2;
            })


            if(this.is_kpi == false && this.isCompanyLevelSelected == false){
                this.item.kpis.forEach( (kpi_item) => {
                    //kpis_row.data[idx]['name'] = kpi_item.name;
                    let kpi_row = {
                        owner_user_id: kpi_item.owner_user_id,
                        id: kpi_item.id,
                        name: kpi_item.name,
                        type: 'kpi',
                        tracking_type: kpi_item.current_time_period,
                        is_different_tracking_period: this.item.current_time_period != kpi_item.current_time_period,
                        metadata: [],
                    }
                    if(kpi_row.is_different_tracking_period){
                        let tracking = {};
                        kpi_item.tracking.forEach( (tracking_item) => {
                            let date = null;
                            switch(this.item.current_time_period){
                                case 'weekly':{
                                    date = format(endOfWeek(parseISO(tracking_item.achieved_date)), "dd-MMM-yyyy");
                                    break;
                                }
                                case 'monthly':{
                                    date = format(endOfMonth(parseISO(tracking_item.achieved_date)), "dd-MMM-yyyy");
                                    break;
                                }
                                case 'quarterly':{
                                    date = format(endOfQuarter(parseISO(tracking_item.achieved_date)), "dd-MMM-yyyy");
                                    break;
                                }
                                case 'yearly':{
                                    date = format(endOfYear(parseISO(tracking_item.achieved_date)), "dd-MMM-yyyy");
                                    break;
                                }
                            }
                            if(tracking[date] == undefined){
                                tracking[date] = Number(tracking_item.value);
                            }
                            else{
                                tracking[date] += Number(tracking_item.value);
                            }
                            kpi_row.metadata.push({
                                grouped_date: date,
                                origional_date: format(parseISO(tracking_item.achieved_date), "dd-MMM-yyyy"),
                                origional_value: tracking_item.value,
                            })
                        })
                        // merge trackiing data
                        Object.assign(kpi_row, tracking)
                    }
                    else{
                        kpi_item.tracking.forEach( (tracking_item) => {
                            let formatted_tracking_date = format(parseISO(tracking_item.achieved_date), "dd-MMM-yyyy")
                            kpi_row[formatted_tracking_date] = tracking_item.value
                        })
                    }
                    kpis_row.data.push(kpi_row);
                })
                return [target_row, kpis_row];
            }
            else{
                return [target_row];
            }
        },
    },
    methods:{
        ...uxToggleMethods,
        
        ...performanceMethods,
        findSmallestTrackingPeriod(target_item){
            const hierarchy = {
                daily: 0,
                weekly: 1,
                monthly: 2,
                quarterly: 3,
                yearly: 4,
            }
            let period = target_item.current_time_period;
            if(this.is_kpi == false){
                target_item.kpis.forEach( kpi_item => {
                    if(hierarchy[kpi_item.current_time_period] < hierarchy[period]){
                        period = kpi_item.current_time_period
                    }
                });
            }

            return period;

        },
        handleRefreshEvent(){
            this.$emit('handleBack');
        },
        handlePrevDate(){
            let table = window.webix.$$('datasetTable');
            let selected_item = table.getSelectedId();
            let col_idx = table.getColumnIndex(selected_item.column);
            let new_col =  table.getColumns()[col_idx-1];
            table.select(
                selected_item.row,
                new_col.id
            )
            this.handleClickEvent({
                row: selected_item.row,
                column: new_col.id
            });
        },
        handleNextDate(){
            let table = window.webix.$$('datasetTable');
            let selected_item = table.getSelectedId();
            let col_idx = table.getColumnIndex(selected_item.column);
            let new_col =  table.getColumns()[col_idx+1];
            if(new_col != undefined){
                table.select(
                    selected_item.row,
                    new_col.id
                )
                this.handleClickEvent({
                    row: selected_item.row,
                    column: new_col.id
                });
            }
        },
        handleClickEvent(obj){
            let el = this;
            const item = window.webix.$$('datasetTable').getItem(obj.row);
            const is_manager = el.$isManagerCheck(el.core.id, item.owner_user_id);
            const is_owner = el.core.id == item.owner_user_id;
                        
            if(is_manager || is_owner){
                const grouped_date = obj.column;
                //if(item.type == 'kpi') {
                let payload = {
                    graph_name: el.item.name,
                    item_name: item.name,
                    item_type: item.type,
                    item_id: item.id,
                    target_value: 0, // optional - only used when item_type is kpi
                    target_date: obj.column,
                    tracking_values:[],
                    is_target_value: null, // used for targets -> is it a target or achieved value 
                    tracking_type: null,
                }
                if(item.type == 'kpi'){
                    payload.tracking_type = item.tracking_type;
                    if(payload.tracking_type == el.item.current_time_period){
                        let value = item[obj.column] != undefined ? item[obj.column] : 0;
                        payload.tracking_values = [
                            {[obj.column] : value}
                        ]
                        payload.is_achieved_value = false;
                    }
                    else{
                        payload.tracking_values = _.filter(item.metadata, { grouped_date:grouped_date})
                        .map( item => {
                            return {
                                //[item.origional_date] : item.origional_value
                                date: item.origional_date,
                                value: item.origional_value,
                            }
                        })
                        //eslint-disable-next-line
                        let dates_that_should_exist = [];
                        let period_date = parse(payload.target_date, 'dd-MMM-yyyy', new Date());
                        switch(payload.tracking_type){
                            case 'daily':{
                                dates_that_should_exist = eachDayOfInterval({
                                    start: startOfMonth(period_date),
                                    end: period_date
                                });
                                break;
                            }
                            case 'weekly':{
                                dates_that_should_exist = eachWeekOfInterval({
                                    start: startOfMonth(period_date),
                                    end: period_date
                                });
                                break;
                            }
                            case 'monthly':{
                                dates_that_should_exist = eachWeekOfInterval({
                                    start: startOfYear(period_date),
                                    end: period_date
                                });
                                break;
                            }
                            case 'quarterly':{
                                dates_that_should_exist = eachQuarterOfInterval({
                                    start: startOfYear(period_date),
                                    end: period_date
                                });
                                break;
                            }
                            case 'yearly':{
                                dates_that_should_exist = eachYearOfInterval({
                                    start: startOfYear(period_date),
                                    end: period_date
                                });
                                
                                break;
                            }
                        }
                        
                        dates_that_should_exist.forEach( d => {
                            if(payload.tracking_type == 'daily'){
                                //let dd = endOfMonth(d);
                                let date_to_find = format(d, 'dd-MMM-yyyy');
                                //if(isBefore(end_of_month,period_date)){
                                    let index = _.findIndex(payload.tracking_values, {date:date_to_find});
                                    if(index == -1){
                                        payload.tracking_values.push({
                                            date: date_to_find,
                                            value: '',
                                        })
                                    }
                                //}
                            }
                            else{
                                let end_of_week = endOfWeek(d)
                                let date_to_find = format(end_of_week, 'dd-MMM-yyyy');
                                if(isBefore(end_of_week,period_date)){
                                    let index = _.findIndex(payload.tracking_values, {date:date_to_find});
                                    if(index == -1){
                                        payload.tracking_values.push({
                                            date: date_to_find,
                                            value: '',
                                        })
                                    }
                                }
                            }
                            
                        })
                        payload.tracking_values = payload.tracking_values.map( item => {
                            return {
                                [item.date] : item.value
                            }
                        })
                    }
                }
                else if(item.type == 'target'){
                    payload.tracking_type = el.item.current_time_period;
                    let value = item[obj.column] != undefined ? item[obj.column] : 0;
                    payload.tracking_values = [
                        {[obj.column] : value}
                    ]
                    payload.is_achieved_value = item.id == 'achieved';
                }
                if((item.type == 'kpi' || item.type == 'target') && obj.column != 'name' && obj.column != 'tracking_type'){
                    el.modal_payload = payload;
                    el.modal_payload['include_weekends'] = el.item.include_weekends == 1 ? true : false;
                    el.show_kpi_data_modal = true;
                    el.$nextTick(()=>{
                        el.$bvModal.show('kpiDataModal');
                        el.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "kpiDataModal") {
                                el.show_kpi_data_modal = false;
                                el.modal_payload = null;
                            }
                        });
                    })
                }
            }
        },
        handleSaveEvent(data) {
            this.setGraphLoadingState(true);
            if(data.item_type == 'kpi'){
                let payload = {
                    "tracking_data": [],
                    "kpi_id": data.item_id,
                }
                data.tracking_values.forEach( item => {
                    const variableName = Object.keys(item)[0];
                    let date = parse(variableName, 'dd-MMM-yyyy', new Date());
                    date = format(date, 'yyyy-MM-dd');
                    payload.tracking_data.push({
                        value: item[variableName] == "" ? 0 : item[variableName] ,
                        notes: '',
                        achieved_date: date,
                        target: 1,
                    })
                })
                this.fireSaveKpiTracking(payload, data.no_reload)
                // this.saveKeyPerformanceTracking(payload)
                // .then(()=>{
                    
                //     // //if(data.no_reload == false){
                //     //     this.$bvModal.hide('kpiDataModal')
                //     //     this.$emit('handleBack');
                //     // //}
                    
                // })
            }
            else{
                let target_type = data.item_id.split('-')[0];
                let target_id = data.item_id.split('-')[1];
                let row_id = target_type == 'achieved' ? `target-${target_id}` : `achieved-${target_id}` ;

                // need to find the other data associated with the target
                let target_details = window.webix.$$('datasetTable').getItem(row_id);

                let payload = {
                    "tracking_data": [],
                    "target_id": target_id,
                }
                //* get the graphs current saved target (from table column)
                let largest_target_value = Number(this.item.target) 

                //loop over values to save
                data.tracking_values.forEach( item => {
                    const variableName = Object.keys(item)[0];
                    //alert(variableName);
                    //alert(item[variableName])
                    let value = item[variableName] == "" ? 0 : item[variableName];
                    let date = parse(variableName, 'dd-MMM-yyyy', new Date());
                    date = format(date, 'yyyy-MM-dd');
                    if(target_type == 'achieved'){
                        payload.tracking_data.push({
                            value: value ,
                            notes: '',
                            achieved_date: date,
                            target: target_details[variableName],
                        })
                    }
                    else{
                        payload.tracking_data.push({
                            value: target_details[variableName] != '' ? target_details[variableName] : 0 ,
                            notes: '',
                            achieved_date: date,
                            target: value != '' ? value : 0,
                        })
                        // * check if a greater target value was entered 
                        if( Number(value) > largest_target_value){
                            largest_target_value = Number(value)
                        }
                    }
                })
                if(largest_target_value != this.item.target){
                    let graph_params = {
                        id: this.item.id,
                        level_id: this.item.level_id,
                        owner_user_id: this.item.owner_user_id,
                        name: this.item.name,
                        //* update target here !!!
                        target: largest_target_value,
                        unit_type_id: this.item.unit_type_id,
                        graph_type: this.item.graph_type,
                        min_value: this.item.min_value,
                        max_value: this.item.max_value,
                        current_time_period: this.item.current_time_period,
                        graph_size: '12', //only one graph displays at a time, so force full width graph
                        show_cumulative: Number(this.item.show_cumulative),
                        show_cumulative_target: Number(this.item.show_cumulative_target),
                    }
                    let save_params = {
                        targets: [graph_params]
                    }
                    this.saveTargets(save_params).then(()=>{
                        this.fireSaveTargetTracking(payload, data.no_reload)
                    })
                }
                else{
                    this.fireSaveTargetTracking(payload, data.no_reload)
                }
                
            }
        },
        fireSaveTargetTracking(payload, no_reload = false){
            this.saveTargetTracking(payload)
            .then(()=>{
                if(no_reload == false){
                    this.$bvModal.hide('kpiDataModal')
                    this.$emit('handleBack');
                }
                
            })
        },
        fireSaveKpiTracking(payload, no_reload = false){
            this.saveKeyPerformanceTracking(payload)
            .then(()=>{
                if(no_reload == false){
                    this.$bvModal.hide('kpiDataModal')
                    this.$emit('handleBack');
                }
                
            })
        },
        scrollToColumn(targetDate) {
            let search_date = targetDate ? targetDate : format(new Date(), "dd-MMM-yyyy");
            let datatable = window.webix.$$("datasetTable"); // Replace with your actual DataTable ID

            let columnIds = datatable.config.columns.map(function(column) {
                return column.id;
            });

            //*Convert target date to timestamp for comparison
            var dateColumns = columnIds.filter(function(columnId) {
                return !isNaN(parse(columnId, 'dd-MMM-yyyy', new Date()));
            });

            // Convert target date to timestamp for comparison
            var targetTimestamp = parse(search_date, 'dd-MMM-yyyy', new Date());

            if (!isNaN(targetTimestamp)) {
                if(dateColumns.length == 0){ return }
                // Find the closest date column
                var closestDateColumn = dateColumns.reduce(function(prev, curr) {
                    var currTimestamp = parse(curr, 'dd-MMM-yyyy', new Date());
                    var prevTimestamp = parse(prev, 'dd-MMM-yyyy', new Date());

                    return (
                        Math.abs(differenceInMilliseconds(currTimestamp, targetTimestamp)) <
                        Math.abs(differenceInMilliseconds(prevTimestamp, targetTimestamp))
                    )
                        ? curr
                        : prev;
                });

                // Scroll to the closest date column
                //datatable.scrollTo(columnIds.indexOf(closestDateColumn));
                datatable.showCell(this.item.id, closestDateColumn);
                datatable.select(closestDateColumn);
                
            } else {
                console.error("Invalid date format. Please use 'DD-MMM-YYYY' format.");
            }
        },
        makeCellDisabled(){
            return 'blackOut';
        },
        //eslint-disable-next-line
        makeCellHover(val, config, test){
            if(config.block_edit == true) {
                return 'blackOut push-right'
            }
            else {
                return 'highlight push-right'
            }

            
        },
        getRange(){
            if(!this.viewableRangeDate.range_start){
                return [];
            }
            let start_date = this.viewableRangeDate.range_start;
            let end_date = this.viewableRangeDate.range_end;

            let start = null;
            let end = null;
            let dates = [];

            const time_period = this.item.current_time_period;

            switch(time_period){
                case 'daily':{
                    start = start_date;
                    end = end_date
                    dates = eachDayOfInterval({ start, end });
                    break;
                }
                case 'weekly':{
                    start = startOfMonth(start_date);
                    end = endOfMonth(end_date);
                    dates = eachWeekOfInterval({ start, end });
                    // set the dates to the end of the week ( currently start of week)
                    dates.forEach( (new_date, idx) => {
                        dates[idx] = endOfWeek(new_date);
                    });
                    break;
                }
                case 'monthly':{
                    start = startOfMonth(start_date);
                    end = endOfMonth(end_date);
                    dates = eachMonthOfInterval({ start, end });
                    //* set date to end of the month
                    dates.forEach((single_date, idx)=>{
                        dates[idx] = endOfMonth(single_date);
                    })
                    break;
                }
                case 'yearly':{
                    //start = startOfYear(start_date);
                    end = endOfYear(end_date);
                    dates = [end]
                }
            }
            return dates;
        },
    },
    mounted(){
        if(this.is_kpi){
            this.setIsEditingDatasetKPI(true);
        }
        else{
            this.setIsEditingDataset(true);
        }

        this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
            let table = window.webix.$$('datasetTable');
            if(table){
                table.refresh();
            }
        }, 100)); 
    },
    beforeDestroy(){
        if(this.is_kpi){
            if(this.changes_made_flag){
                this.$emit('handleBack');
            }
            this.setIsEditingDatasetKPI(false);
        }
        else{
            if(this.changes_made_flag){
                this.$emit('handleBack');
            }
            this.setIsEditingDataset(false);
            window.webix.eventRemove(this.webix_resize_event);
        }
    }
}
</script>

<style>
.highlight:hover{
    transition: all 0.2s linear !important;
    background-color:#edeff5 !important;
    cursor: pointer !important;
    
}
.push-right{
    text-align: right;
}
.webix_column_select {
    background-color: white !important;
}
.blackOut {
    background-color: #f8f8fb !important;
}

.datasetTable .webix_hcell {
    text-align: center !important;
}

.no-decimal:after {
  content: attr(data-value) "\0000A0"; /* Non-breaking space */
  overflow: hidden;
}


</style>