<template>
    <div>
    <b-card no-header class="">
        <!-- <template #header>
            <strong>
                Strategic Objectives
            </strong>
        </template> -->

        <!-- <section class="normal-text"> -->
            <b-row>
                <b-col cols="12">
                    <!-- <Objectives key="ob1" v-if="view_type == 'COMPANY' " objective_type="goal"/>
                    <Objectives key="ob2" v-else objective_type="level" /> -->
                    <Objectives key="ob1" objective_type="goal"/>
                    <!-- <Objectives key="ob2" objective_type="level" /> -->
                </b-col>
                <b-col v-if="hasGoalObjectiveSelected && !is_viewing_okr_list && !show_gantt" cols="12">
                    <ObjectiveDetails :view_type="view_type" />

                </b-col>
            </b-row>
        <!-- </section> -->
    </b-card>
</div>
</template>

<script>
import { activityComputed, performanceComputed, performanceMethods } from '@/state/helpers';
import Objectives from "@/components/objectives/components/cards/mission-objectives-new.vue";
import ObjectiveDetails from "../components/cards/ObjectiveDetails.vue";
export default {
    props:{
        view_type:{
            required: true,
        }
    },
    components:{
        Objectives,
        ObjectiveDetails,
    },
    data:() => ({}),
    computed:{
        ...activityComputed,
        ...performanceComputed,
        hasGoalObjectiveSelected(){
            if(this.selected_goal_objective != -1 || this.selected_personal_objective != -1) {
                return true
            } else {
                return false
            }
        }
    },
    methods:{
        ...performanceMethods,
    },
    mounted(){},
}
</script>

<style>

</style>