<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-02T10:02:23+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-05T13:12:45+02:00
@Copyright: Diversitude 2021
-->



<template>
    <b-overlay :show="is_loading || lazy_loaded == false">
        <div v-show="!lazy_loaded" style="height: 200px;"></div>
        <div v-if="lazy_loaded">
            <div class="">
                <CardHeader>
                    <template #title>
                        {{ titleName }}
                        &nbsp;&nbsp;>&nbsp;&nbsp;
                        <span class="text-primary">{{name}}</span>
                    </template>
                    <template #buttons>
                        <b-dropdown
                        size="sm"
                        variant="white"
                        menu-class="dropdown-menu-end"
                        toggle-class="p-0"
                        no-caret
                        right>
                            <template #button-content>
                                <font-awesome-icon icon="fa-solid  fa-ellipsis-h" />
                            </template>
                            <b-dropdown-item
                                v-if="isShow.isView"
                                variant=""
                                @click="flipCard('edit')"
                                href="#"
                                >Edit Vision</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="isShow.isComment"
                                variant=""
                                href="#"
                                @click="addComment"
                                >Add Comment</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="!isShow.isEdit && !isShow.isView"
                                variant=""
                                @click="flipCard('back')"
                                href="#"
                                >View Vision</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="isShow.isEdit"
                                variant=""
                                @click="flipCard('cancel')"
                                href="#"
                                >Cancel Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="isEditGuideline"
                                variant=""
                                @click="flipCard('cancelguideline')"
                                href="#"
                                >Cancel Edit</b-dropdown-item
                            >
                            <!--<b-dropdown-item
                                v-if="isShow.isEdit"
                                variant=""
                                @click="saveDraft()"
                                href="#"
                                >Save Draft</b-dropdown-item
                            >-->
                            <b-dropdown-item
                                v-if="isShow.isEdit"
                                variant=""
                                @click="saveFinal()"
                                href="#"
                                >Save</b-dropdown-item
                            >

                            <b-dropdown-item
                                v-if="isEditGuideline"
                                variant=""
                                href="#"
                                @click="saveGuideline"
                                >Save Guideline</b-dropdown-item
                            >
                        </b-dropdown>  
                    </template>
                </CardHeader>

            <!-- Body Row -->
            <div class="">
                <div>
                <div class="" style="text-align: left;">
                    <b-alert v-if="body_data == '' && !isShow.isEdit" show>No Vision Added</b-alert>
                    <div v-if="isShow.isView && body_text">
                        <!--<b-form-textarea
                        readonly
                        v-model="body_text"
                        no-resize
                        rows="7"
                        ></b-form-textarea>-->
                        <blockquote class="p-1 mb-0 border-light border rounded">
                            <p v-html="body_data"></p>
                        </blockquote>
                        <div
                        v-if="data.draft == 1 && body_text"
                        style="text-align: center;"
                        class="draft-overlay d-flex align-items-center justify-content-center"
                        >
                        Draft
                        </div>
                    </div>
                    <div v-if="isShow.isEdit">
                        <Editor
                            @contentChanged="contentChanged"
                            :content="body_text">
                        </Editor>
                    </div>
                </div>

                <div v-if="!flip">
                    <Guideline
                    v-if="isShow.isGuideline || isEditGuideline"
                    ref="guideline"
                    :content="data.guideline"
                    :cardName="'Vision'"
                    :isEditGuideline="isEditGuideline"
                    @refresh="update('guideline')"
                    />
                    <Comment
                    v-if="isShow.isComment"
                    ref="comment"
                    :comment="data.comments"
                    :cardName="'Vision'"
                    :isAddComment="isAddComment"
                    @refresh="update"
                    />
                </div>
                </div>
            </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import Editor from "@/components/widgets/simpleEditorWrapper.vue"
import Comment from "@/components/widgets/comment.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from "lodash";
import {
  levelComputed,
  activityMethods,
  activityComputed,
  performanceComputed,
} from "@/state/helpers";
import CardHeader from '@/components/widgets/cardHeader.vue'
export default {
    components: { Comment, Guideline, CardHeader, Editor },
    props: {
        title: { type: String },
        data: {},
    },
    data: () => ({
        isAddComment: false,
        flip: true,
        body_text: "",
        updated_body_text: '',
        commentData: [],
        isEditGuideline: false,
        isShow: {
            isView: true,
            isEdit: false,
            isComment: false,
            isGuideline: false,
        },

        is_loading: false,
        lazy_loaded: false,
    }),

    computed: {
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        titleName() {
            return "Vision";
        },
        display_body_data(){
            var text = "";
            if (this.data.body != undefined) {
                text = `<p>${this.data.body}</p>`;
                // text.replace(/\n/g, '<br>');
            }
            return text;
        },
        body_data() {
            var text = "";
            if (this.data.body != undefined) {
                text = this.data.body;
            }
            return text;
        },
        name() {
            return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
    },
    watch:{
        data:{
        immediate: true,
        deep: true,
        handler(){
            if (this.data != "") {
                if(this.data && this.data.body){
                    this.body_text = _.cloneDeep(this.data.body);
                }
                else{
                    this.body_text = '';
                }
            }
        }
    }
    },
    methods: {
        ...activityMethods,
        addComment() {
            this.$refs.comment.editNew();
        },
        saveGuideline() {
            this.$refs.guideline.saveGuideline();
        },
        contentChanged(new_content){
            this.updated_body_text = new_content;
        },
        update(type) {
            this.isEditGuideline = false;
            this.$refs.isEditingNew = false;
            this.$refs.isEditingExisting = false;
            this.$emit('reloadData')

            if (type === "guideline") this.isShow.isGuideline = true;
        },

        flipCard(action) {
            this.clearIsShow();
            switch (action) {
                case "edit":
                this.flip = true;
                this.isShow.isEdit = true;

                /*setTimeout(() => {
                    this.$refs["field"].focus();
                }, 200);*/
                break;
                case "editguideline":
                this.isEditGuideline = true;
                console.log("editguideline" + this.isEditGuideline);
                break;

                case "cancel":
                this.flip = true;
                this.isShow.isView = true;
                this.body_text = _.cloneDeep(this.data.body);

                break;
                case "cancelguideline":
                this.isShow.isGuideline = true;
                this.$refs.guideline.resetContent();
                break;
                case "comment":
                this.flip = false;
                this.isShow.isComment = true;
                break;
                case "guideline":
                this.flip = false;
                this.isShow.isGuideline = true;
                break;
                case "back":
                this.flip = true;
                this.isShow.isView = true;
                break;
            }
        },
        clearIsShow() {
            this.$refs.isEditingNew = false;
            this.$refs.isEditingExisting = false;
            this.isEditGuideline = false;
            Object.keys(this.isShow).forEach((key) => {
                this.isShow[key] = false;
            });
        },
        saveDraft() {
            var params = {
                level_id: this.selected_level.id,
                draft: true,
                body: this.updated_body_text,
            };

            this.is_loading = true;

            this.saveVision(params).then(() => {
                this.$emit('reloadData')
                this.flipCard("back");
                this.$nextTick(()=>{
                    this.is_loading = false;
                })
            })
            .catch( e => {
                console.log(e);
                this.is_loading = false;
            });
        },
        saveFinal() {
            var params = {
                level_id: this.selected_level.id,
                draft: false,
                body: this.updated_body_text,
            };
            this.is_loading = true;
            this.saveVision(params).then(() => {
                this.flipCard("back");
                this.$emit('reloadData')
                this.$nextTick(()=>{
                    this.is_loading = false;
                })
            })
            .catch( e => {
                console.log(e);
                this.is_loading = false;
            });
        },
    },
    mounted() {
        
        setTimeout(()=>{
            this.lazy_loaded = true;
        }, 100)
    },
};
</script>

<style scoped>
.dynamic-textarea {
  resize: none; /* Disable default resize handle */
  height: auto; /* Allow height to adjust based on content */
  overflow-y: auto; /* Enable scroll if content exceeds container height */
}
</style>
