<template>
    <div>
        <StrategicPlan
            v-if="!fullscreen_kanban"
            :view_type="view_type"
            @loadPerfromanceData="loadPerfromanceData()">
            <template #okrs>
                <OKRS :view_type="view_type" v-if="!fullscreen_kanban"/>
            </template>
        </StrategicPlan>
            
        <div v-if="canShowDetails">
            <BauTargets v-if="!fullscreen_kanban"/>
            <KeyActions v-if="view_type != 'COMPANY' && !fullscreen_kanban"/>
            <kanbanChart v-if="show_kanban && fullscreen_kanban" />
        </div>
    </div>
</template>

<script>
import StrategicPlan from '@/components/objectives/performance2/StrategicPlan.vue';
import OKRS from '@/components/objectives/performance2/OKRS.vue';
import BauTargets from '@/components/objectives/performance2/BauTargets.vue';
import KeyActions from '@/components/objectives/performance2/KeyActions.vue';
import kanbanChart from '../kanban/kanbanView.vue';

//Vuex imports
import {
    levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    performanceComputed,
    performanceMethods,
    ganttComputed,
    ganttMethods

} from "@/state/helpers";
import _ from 'lodash';
import miletoneHelpers from '@/mixins/milestoneHelpers.js'
export default {
    mixins: [ miletoneHelpers ],
    components:{
        StrategicPlan,
        OKRS,
        BauTargets,
        KeyActions,
        kanbanChart,
    },
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        ...ganttComputed,
        view_type(){
            if(this.selected_user_id != null){
                return 'USER'
            }
            else if(this.current_level_id == this.company_id){
                return 'COMPANY'
            }
            else{
                return 'DIVISION'
            }
        },
        canShowDetails(){
            if(this.view_type == 'COMPANY'){
                return true
            }
            else{
                return true;
            }
        }
    },
    watch:{
        objective_viewing_quarter(){
            this.loadLevelPerformanceData();
            this.loadPerfromanceData();
        },
        pinned_milestone_id(){
            console.log('loadPerfromanceData', 'pinned_milestone_id')
            this.loadPerfromanceData();
        },
        selected_user_id(){
            console.log('loadPerfromanceData', 'selected_user_id');
            this.loadLevelPerformanceData();
            this.loadPerfromanceData();
        },
        current_level_id:{
            immediate: true,
            handler(){
                this.loadLevelPerformanceData();
            }
        },

        selected_goal_objective(){
            if(this.selected_goal_objective > 0){
                let params = {
                    level_id: this.current_level_id,
                    user_id: this.selected_user_id ? this.selected_user_id : 0,
                    objective_id: this.selected_goal_objective,
                    objective_data: 1,
                }
                if(params.user_id == 0){
                    delete params.user_id
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                })
            }
        },

        selected_personal_objective(){
            if(this.selected_personal_objective > 0){
                let params = {
                    level_id: this.current_level_id,
                    user_id: this.selected_user_id ? this.selected_user_id : 0,
                    objective_id: this.selected_personal_objective,
                    objective_data: 1,
                }
                if(params.user_id == 0){
                    delete params.user_id
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                })
            }
        }

        //view_type(new_type){
        //    if(new_type == 'COMPANY' || new_type == 'USER'){
        //        this.$nextTick(()=>{
        //            let table = window.webix.$$('milestonesTable');
        //            if(table){ table.refresh(); }
        //        })
        //    }
        //},
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...performanceMethods,
        ...ganttMethods,
        getQueryParams(){
            let params = {};
            if(this.selected_milestone){
                params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                    milestone_id: this.selected_milestone.id
                }
            }
            else{
                params = {
                    level_id: this.current_level_id,
                    //user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                }
            }
            if(this.selected_user_id){
                params['user_id'] =  this.selected_user_id
            }
            return params;
            
        },
        loadLevelPerformanceData: _.debounce( function (){
            this.loadPerformanceScreenData({
                type: 'plans',
                params:{
                    level_id: this.current_level_id,
                    level_plans_data: 1,
                    user_id: this.selected_user_id ? this.selected_user_id : -1,
                }
            })
            this.loadPerfromanceData()
                let params2 = {
                        level_id: this.current_level_id,
                        graph_data: 1
                }
                if(this.selected_goal_objective != -1) {
                    params2 = {
                        level_id: this.current_level_id,
                        objective_id: this.selected_goal_objective,
                        graph_data: 1
                    }
                }
                if(this.selected_personal_objective != -1) {
                    params2 = {
                        level_id: this.current_level_id,
                        objective_id: this.selected_personal_objective,
                        graph_data: 1
                    }
                }
                if(this.selected_user_id != -1){
                    params2['user_id'] = this.selected_user_id;
                }   
                if(params2.objective_id == -1){
                    alert('returnin')
                    return;
                }
                this.loadPerformanceScreenData({
                    type: 'targets',
                    params: params2,
            });
        }, 50),
        loadPerfromanceData: _.debounce( function (){
            let params = this.getQueryParams();
            if(this.selected_user_id != -1){
                params['user_id'] = this.selected_user_id;
            }
            this.loadPerformanceScreenData({
                type: 'level',
                params: params,
            })
            .then(()=>{
                this.setKeyResultLoadingID(-1);
            })
        }, 50),
        loadPerfromanceData2: _.debounce(function(){
            if(this.is_loading_data){
                return;
            }
            this.is_loading_data = true;
            console.log('LOADING PERF DATA');
            //* 2 seperate endpoints (load for user or level)
            //this.$nextTick(()=>{
                if(this.view_type == 'USER'){
                    this.loadUserActivityData(this.selected_user_id)
                    .then(()=>{
                        this.$nextTick(()=>{
                            this.is_loading_data = false;
                        })
                    }).catch(()=>{
                        this.is_loading_data = false;
                    })
                }
                else{
                    //* COMPANY or DIVISION
                    this.loadActivityData(this.selected_level.id)
                    .then(()=>{
                        this.$nextTick(()=>{
                            this.is_loading_data = false;
                        })
                    }).catch(()=>{
                        this.is_loading_data = false;
                    })
                }
            //})
        }, 50),
        handleStatusReload(){
            this.loadPerfromanceData();
            this.loadCompanyMilestones(this.company_id);
        }
    },
    mounted(){
        this.$eventHub.$on('reloadLinkedObjectives', this.handleStatusReload );
    },
    beforeDestroy(){
        this.$eventHub.$off('reloadLinkedObjectives');
    }
}
</script>

<style>

</style>