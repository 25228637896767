<template>
    <div class="" ref="card2" id="card2">
        <b-alert variant="warning" :show="keyResultUnit == null || keyResultUnit.length == 0">
            No unity types found, please add unit types from the right hand menu before adding KPIS
        </b-alert>

        <div class="pretty-tiles" v-if="false">
                <div  class="outerContainer" >
                    <transition-group name="fade" class="newTileContainer">
                        <TileCard
                            v-for="item in kpis" :key="item.id"
                            :id="item.id"
                            :item="item"
                            :active_id="getActiveKpiID"
                            type="kpi"
                            @selectedItem="selectedCardEvent"
                        >
                        </TileCard> 
                    </transition-group>
                </div>
        </div>





        <Table
            v-show="show_table == true"
            :columns="columns"
            :default_table_mode="kpis.length == 0 ? 'edit' : 'view'"
            :simple_columns="simple_columns"
            :data="kpis"
            :uid="table_uid"
            :ref="table_uid"
            :is_loading="is_loading"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :only_editable_columns="true"
            :default_values="default_values"
            :validation="table_validation_rules"
            enforce_structure_checks
            :edit_permissions="{manager:'kpi-manager', user:'kpi'}"
            :required_permissions="['kpi', 'kpi-manager']"
            :variable_watchers="variable_watchers"
            :allow_duplication_feature="true"
            :active_id="getActiveKpiID"
            :button_methods="button_methods"
            :enable_pretty_mode="false"
            @handleDuplicateionItemsInsert="handleDuplicateionItemsInsert"
            @saveTable="saveTable"
            @updateViewMode="updateViewMode"
            @onDataUpdate="onChangesMade"
            @deleteRows="deleteRow"
            @selectedRowEvent="selectedRowEvent"
        >
            <template #title>
                
                KPI
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span v-if="kpi_display_type === 'Level'" class="text-primary">{{levelName}}</span>
                <span v-else-if="kpi_display_type === 'KeyResult'" class="text-primary">
                    {{keyResultName}}
                </span>
                <span v-else>
                    <span v-if="objectiveName !== ''" class="text-primary">
                        {{objectiveName}}
                    </span>
                    <span v-else class="text-danger">
                        No Objective Selected
                    </span>
                </span>
            </template>
            <template #buttons>
                <span class="btn-group me-2">
                    <b-button :disabled="isEditingTable" @click="toggleTableView()" size="sm" :variant="!show_table ? 'primary' : 'light'" v-b-tooltip.hover.bottomright title="Graph View">
                        <font-awesome-icon icon="fa-chart-area"></font-awesome-icon>
                    </b-button>
                    <b-button :disabled="isEditingTable" @click="toggleTableView()" size="sm" :variant="show_table ? 'primary' : 'light'" v-b-tooltip.hover.bottomright title="Kpi List">
                        <font-awesome-icon icon="fa-list-alt"></font-awesome-icon> 
                    </b-button>
                </span>
                <!--<span class="form-check form-switch pt-1 me-3 ms-2" style="cursor:pointer; font-size: 10px;">
                    <span>
                        <input class="form-check-input" style="cursor:pointer;" v-model="show_table" type="checkbox" id="showGraphSwitchKpi"/>
                        <label class="form-check-label" for="showGraphSwitchKpi">
                            Show Table
                        </label>
                    </span>
                </span>-->
                <!--<span class="form-check form-switch pt-1 me-3 ms-2" style="cursor:pointer; font-size: 10px;">
                    <span>
                        <input class="form-check-input" style="cursor:pointer;" type="checkbox" id="toggle_id_PLEASE_UPDATE_ID"/>
                        <label class="form-check-label" for="toggle_id_PLEASE_UPDATE_ID">
                            Toggle Text
                        </label>
                    </span>
                </span>-->
            </template>
            <template #extra-items>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showUnitTypeModal">
                    <i class="fas fa-wrench me-1"></i>Manage Unit Types
                </b-dropdown-item>
            </template>
        </Table>
        <CardHeader v-if="show_table == false" class="pb-1">
            <template #title>
                <div class="pt-1 ms-4">
                    KPI
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span v-if="kpi_display_type === 'Level'" class="text-primary">{{levelName}}</span>
                    <span v-else-if="kpi_display_type === 'KeyResult'" class="text-primary">
                        {{keyResultName}}
                    </span>
                    <span v-else>
                        <span v-if="objectiveName !== ''" class="text-primary">
                            {{objectiveName}}
                        </span>
                        <span v-else class="text-danger">
                            No Objective Selected
                        </span>
                    </span>
                </div>
            </template>    
            <template #buttons>
                
                <!--<span class="form-check form-switch pt-1 me-3 ms-2" style="cursor:pointer; font-size: 10px;">
                    <span>
                        <input class="form-check-input" style="cursor:pointer;" v-model="show_table" type="checkbox" id="showGraphSwitchKpi"/>
                        <label class="form-check-label" for="showGraphSwitchKpi">
                            Show Table
                        </label>
                    </span>
                </span>-->
                <div class="btn-group me-4">
                    <b-button @click="toggleTableView()" size="sm" :variant="!show_table ? 'primary' : 'light'" v-b-tooltip.hover.bottomright title="Table View">
                        <font-awesome-icon icon="fa-chart-area"></font-awesome-icon>
                    </b-button>
                    <b-button @click="toggleTableView()" size="sm" :variant="show_table ? 'primary' : 'light'" v-b-tooltip.hover.bottomright title="Graph View">
                        <font-awesome-icon icon="fa-table"></font-awesome-icon>
                    </b-button>
                </div>

                <TableActionButton
                    v-if="show_table"
                    class=""
                    :currentMode="table_view_mode"
                    :actions="['edit', 'delete', 'view']"
                    @updateMode="updateViewMode"
                    >
                    <template #extra-items>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="showUnitTypeModal">
                            <i class="fas fa-wrench me-1"></i>Manage Unit Types
                        </b-dropdown-item>
                    </template>
                </TableActionButton>
                <b-button @click="saveTable" style="height: 1.5rem;" size="sm" variant="primary" v-show="changes_made_flag && show_table == true" right class="me-3">
                    Save Table Changes
                </b-button>
                <b-button @click="showDeletePrompt" size="sm" variant="danger" v-show="delete_array.length > 0 && show_table == true" right class="me-3 dropdown-menu-end">
                    Delete Selected Rows
                </b-button>
            </template>
        </CardHeader>

        <b-overlay :show="is_updating_graph">
            <div v-if="show_table == false && update_kpi_graphs == false">
                <b-row v-if="kpis.length == 0 && this.kpi_display_type != 'loading'">
                <!--graph -->
                <b-col >
                <b-alert show
                    >No Key Performance Indicators Added</b-alert
                >
                </b-col>
                
                
                <div v-if="this.kpi_display_type == 'loading'">
                    <b-overlay show>
                        <div style="height: 250px;"></div>
                    </b-overlay>
                </div>
                </b-row>
                <div v-if="graph_index > -1 && hasKpis">
                    <GraphNav
                        v-if="show_table == false"
                        :disable_buttons="is_editing_kpi_dataset"
                        @next="graphNext"
                        @previous="graphPrevious"
                        >
                        <template #graph >
                            <div>
                                <LineGraph v-if="kpi" :data="kpi"  :middle_text="graphMiddleText"/>
                            </div>
                        </template>
                    </GraphNav>
                    <div v-if="kpi != null && false" style="display: flex; flex-direction: row;">
                        <div style="width: 1.55rem;" class="d-flex">
                            <b-button class="mt-auto mb-auto" variant="light" size="sm"  style="height: 6rem;" @click="graphPrevious()">
                                <font-awesome-icon icon="fa-arrow-left"/>
                            </b-button>
                        </div>
                        <transition name="fade">
                            <div style="flex: 1;" v-if="kpi != null && graph_index > -1 && show">
                                <LineGraph :data="kpi"  :middle_text="graphMiddleText"/>
                            </div>
                        </transition>
                        <div style="width: 1.55rem;"  class="d-flex">
                            <b-button class="mt-auto mb-auto" variant="light" size="sm" @click="graphNext()" style="height: 6rem;">
                                <font-awesome-icon icon="fa-arrow-right"/>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

        <!-- LINKING MODAL -->
        <LinkingModal
        v-if="linking_item != null"
        type="kpi"
        :linking_item="linking_item"
        />
        <UnitTypeModal v-if="show_units_modal"/>
    </div>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
import TableHelpers from '@/mixins/tableHelpers.js';

import CardHeader from '@/components/widgets/cardHeader.vue'
//eslint-disable-next-line
import { format, parseISO, isAfter, isBefore, isEqual } from "date-fns";
import GraphNav from '@/components/widgets/graphNavigationContainer.vue'

import _ from "lodash";
import {
  adminComputed,
  levelComputed,
  levelMethods,
  activityMethods,
  activityComputed,
  cardHeightMethods,
  cardHeightComputed,
  webixTableState,
  uxToggleMethods, uxToggleComputed,
  performanceComputed, performanceMethods,
} from "@/state/helpers";
import LineGraph from '../../../widgets/objectives/lineGraphUpgraded.vue'
import LinkingModal from "../../../widgets/objectives/linkingModal.vue";
import Table from '@/components/widgets/table/table.vue'
import TableActionButton from  '@/components/widgets/tableActionsButton.vue'
import Swal from 'sweetalert2';
//import ObjectiveDropdown from "@/components/objectives/components/objective-dropdown.vue";
//import KeyResultsDropdown from "@/components/objectives/components/keyResults-dropdown.vue";
import UnitTypeModal from '../misc/unitTypesModal.vue'
import TileCard from '@/components/objectives/components/tiles/tileCardModernGraph.vue'

export default {
    mixins:[ dateMixin, TableHelpers ],
    props:{
        max_height:{
            default: 20,
        },
        table_state:{
            default: null,
        }
    },
    components: {
        LinkingModal,
        LineGraph,
        TableActionButton,
        Table,
        CardHeader,
        UnitTypeModal,
        GraphNav,
        TileCard,
        //ObjectiveDropdown,
        //KeyResultsDropdown,
    },
    data: () => ({
        /* table stuff*/
        show_table: false,
        table_view_mode: 'view',
        changes_made_flag: false,
        delete_array: [],
        table_uid: 'krKpiTable',
        show: true,

        debug_var: '',
        graph_index:0,

        show_units_modal: false,

        update_kpi_graphs: false,
        is_updating_graph: false,
        is_loading: false,
        kr_start: null,
        kr_end: null,
        time_periods: [
            { value: "daily", text: "Daily" },
            { value: "weekly", text: "Weekly" },
            { value: "monthly", text: "Monthly" },
            { value: "yearly", text: "Yearly" }
        ],
        graph_types: [
        { value: "line", text: "Line Chart" },
        { value: "bar", text: "Bar Chart" },
        ],
        graph_sizes: [
        { value: "3", text: "Quarter" },
        { value: "6", text: "Half" },
        { value: "12", text: "Full" },
        ],
        /*save_type: {
        level: false,
        objective: false,
        key_result: false,
        },*/
        save_type: '', // must set to selected kpi category
        form_data: {}, //* kpi object for modal
        show_modal: false,

        kpi_display_type: "KeyResult", // Level, Objective or KeyResult

        //* linking item - passed through to linking modal //
        linking_item: null,
    }),
    computed: {
        ...performanceComputed,
        ...adminComputed,
        ...cardHeightComputed,
        ...activityComputed,
        ...levelComputed,
        ...webixTableState,
        ...uxToggleComputed,
        getPeriodOptions(){
            
            // [
            //             { id: "daily", value: "Daily" },
            //             { id: "weekly", value: "Weekly" },
            //             { id: "monthly", value: "Monthly" },
            //             { id: "quarterly", value: "Quarterly" },
            //             { id: "yearly", value: "Yearly" },
            //         ]
            let opts = [];
            switch(this.getSelectedTarget.current_time_period){
                case 'daily':{
                    opts = [{ id: "daily", value: "Daily" }];
                    break;
                }
                case 'weekly':{
                    opts = [{ id: "daily", value: "Daily" },
                            { id: "weekly", value: "Weekly" },];
                            break;
                }
                case 'monthly':{
                    opts = [
                        { id: "daily", value: "Daily" },
                        { id: "weekly", value: "Weekly" },
                        { id: "monthly", value: "Monthly" }
                    ];
                    break;
                }
                case 'yearly':{
                    opts = [
                        { id: "daily", value: "Daily" },
                        { id: "weekly", value: "Weekly" },
                        { id: "monthly", value: "Monthly" },
                        { id: "yearly", value: "Yearly" },
                    ];
                    break;
                }
            }
            return opts;
        },  
        isEditingTable(){
            //TODO - replace with new helper mixin (table)
            if(this.table_states[this.table_uid] && this.table_states[this.table_uid].changes_made){
                return true;
            }
            return false;
        },
        //'name','unit_type', 'description', 'time_period','min_value', max_value
        //* table computed props
        variable_watchers(){
            var el = this;
            return {
                start_date(newVal, oldVal, id, is_new_row){
                    if(!is_new_row){
                        let new_date = parseISO(newVal);
                        let old_date = parseISO(oldVal);
                        if(isBefore(new_date, old_date)){
                            el.$swal.fire('New values from the adjusted start date will have to be updated in this graphs dataset view.')
                        }
                        else if(isAfter(new_date, old_date)){
                            el.$swal.fire('Some data will be lost due to new starting date');
                        }
                    }
                },
                end_date(newVal, oldVal, id, is_new_row){
                    if(!is_new_row){
                        let new_date = parseISO(newVal);
                        let old_date = parseISO(oldVal);
                        if(isBefore(new_date, old_date)){
                            el.$swal.fire('Some data will be lost due to shrinking end date');
                        }
                        else if(isAfter(new_date, old_date)){
                            el.$swal.fire('New values from the adjusted end date will have to be updated in this graphs dataset view.');
                        }
                    }
                },
                unit_type_id(newVal, oldVal, id, is_new_row){
                    if(!is_new_row && newVal != oldVal){
                        el.$swal.fire('Changing the Unit Type will affect the shareability of this graph');
                    }
                }
            }
        },
        simple_columns(){
            let columns = [
                { id: "name", header: "Name",editor: "text", colspan: 2, fillspace: true, tooltip: '', },
                { id: "end_date", header: "End", editor: "date", sort: "date", minWidth: 120, tooltip: '',
                    format:function(value){
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                {
                    id: "owner_user_id",
                    editor: "combo",
                    header: "Owner",
                    tooltip: '',
                    minWidth: 120,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: 'notes',
                    header: "Notes",
                    width: 60,
                    template:function(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        if(obj.notes == null || obj.notes == ''){
                            return '';
                        }
                        return `
                        <div style='text-align: center' class="notes_button">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                        </div>
                        `
                    }
                }
            ];
            return columns;
        },
        columns(){
            let el = this;
            return [
                { id: "name", header: "Name",editor: "text", width: 200 },
                //{ id: "unit_type", header: "Unit Type",editor: "text", fillspace: 1},
                { id: "unit_type_id", header: "Unit Type", editor: "combo", width: 100,
                    options: el.availableUnitTypes,
                },
                {
                    id: 'category', header: 'Category', editor: 'combo', options: el.kpiCategories, width: 100,
                },
                { id: "time_period", header: "Capture Period", editor: "select",options: el.getPeriodOptions,  width: 150},
                // { id: "start_date", header: "Start", editor: "date", sort: "date", width: 100,
                //     format:function(value){
                //         let date = _.cloneDeep(value);
                //         if(date !== ''){
                //         if(typeof date === 'string'){
                //             date = parseISO(date);
                //         }
                //             return format(date, 'dd-MMM-yyyy');
                //         }
                //         else{
                //             return '';
                //         }
                //     },
                // },
                // { id: "end_date", header: "End", editor: "date", sort: "date", width: 100,
                //     format:function(value){
                //         let date = _.cloneDeep(value);
                //         if(date !== ''){
                //         if(typeof date === 'string'){
                //             date = parseISO(date);
                //         }
                //             return format(date, 'dd-MMM-yyyy');
                //         }
                //         else{
                //             return '';
                //         }
                //     },
                // },
                { id: "min_value", header: "Min Value", editor: "text", width: 150},
                { id: "max_value", header: "Max Value", editor: "text", width: 150},
                {
                id: "owner_user_id",
                editor: "combo",
                header: "Owner",
                width: 200,
                options: this.userList,
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                },
                { id: "description", header: "Description",editor: "text", width: 150},
                {
                    id: 'notes', header: 'Notes', editor: 'text' , width: 150,
                }
        ]},
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'notes_button':function(e, id){
                    let item = this.getItem(id)
                    let note_data = {
                        item: item,
                        can_edit: false,
                        table: 'kpis'
                    }
                    //el.notes_text = item.notes;
                    el.$eventHub.$emit('showNotesModal', note_data);
                },
            }
            return onClick;
        },
        default_values(){
            let el = this;
            return [
                { id : "owner_user_id", value: el.core.id },
                { id: 'min_value', value: 0 },
                { id: 'time_period', value: 'monthly' },
                { id: 'description', value: 'N/a'},
                { id : "category", value: 'leading'},
            ]
        },
        table_validation_rules(){
            //let el = this;
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "unit_type_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "description": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "time_period": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "min_value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "max_value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == undefined || item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                //eslint-disable-next-line
                // "start_date": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(obj.start_date && obj.end_date){
                //             return el.startDateCheck(obj.start_date, obj.end_date)
                //         }
                //         else{
                //             return false;
                //         }
                //     }
                // },
                // //eslint-disable-next-line
                // end_date(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(obj.start_date && obj.end_date){
                //             return el.endDateCheck(obj.end_date, obj.start_date)
                //         }
                //         else{
                //             return false;
                //         }
                //     }
                // },
                
            };
        },
        kpiCategories(){
            return [
                { id: 'leading', value: 'Leading'},
                { id: 'lagging', value: 'Lagging'},
            ]
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        availableUnitTypes(){
            if(this.keyResultUnit){
                return this.keyResultUnit.map( item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
            }
            else{
                return [];
            }
        },
        setCardSize(){
          return this.row2;
        },
        hasKpis(){
            return this.kpis.length > 0;
        },

        graphMiddleText(){
            let text = '';
            text = (this.graph_index + 1) + ' of ' + this.kpis.length;
            return text;
        },
        graphsCount(){
          //TODO - count key result linked graphs
            let count = {
                level: 0,
                objective: 0,
                key_result: 0,
            }
            //* do calcs
            if (this.activity_data != null) {
                count.level = this.activity_data.level_kpis.length;
            }
            if(this.selected_objective_details != null){
                count.objective = this.selected_objective_details.kpis.length;
            }
            if(this.selected_key_result != null){
                count.key_result = this.selected_key_result.kpis.length;
            }


            return count;
        },
        levelName(){
            if(this.selected_level != null){
                return this.selected_level.name;
            }
            else{
                return '';
            }
        },
        keyResultName(){
            if(this.selected_key_result != null){
                return this.selected_key_result.name;
            }
            else{
                return '';
            }
        },
        objectiveName(){
        if(this.selected_objective != null){
            return this.selected_objective.name;
        }
        else{
            return '';
        }
        },
        kpi(){
            if(this.kpis.length == 0){
                return null;
            }
            else{
                let id = this.selected_key_performance_indicator
                ? this.selected_key_performance_indicator.id : -1;
                let data = this.kpis.find( k => {
                    return k.id == id
                })
                return data ? data : null;
                //return this.kpis[this.graph_index]
            }
        },
        kpis() {
        //* filter kpis
        //* display if linked to level, objective or key result
        // let data = [];
        // if(this.selected_key_result != null){
        //     data = _.cloneDeep(this.selected_key_result.kpis);
        //     data.forEach((item)=>{
        //         item['time_period'] = item.current_time_period;
        //     });
        // }
        let data = [];
        //data = _.cloneDeep(this.getSelectedTarget.kpis);
        //*get target's from the vuex store array that holds all targets
        let found_target = this.level_targets.find( item => {
            return item.id == this.getSelectedTarget.id
        })
        if(!found_target){
            return [];
        }
        else{
            data = _.cloneDeep(found_target.kpis);
        }
        data.forEach((item)=>{
            item['time_period'] = item.current_time_period;
        });
        return data;
        },
        activeUserId(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        getActiveKpiID(){
            return this.selected_key_performance_indicator
            ? this.selected_key_performance_indicator.id : -1;
        },
    },
    watch:{
        show_table(newVal){
            if(newVal && window.webix.$$(this.table_uid)){
                //* fix webix table size 
                this.$nextTick(()=>{
                    window.webix.$$(this.table_uid).refresh();
                })
            }
            this.setGraphEditState({
                type: 'kpi',
                state: newVal,
            })
        },
        selected_key_performance_indicator(newVal){
            if(newVal == null){
                this.graph_index = 0;
            }
            else{
                //set the selected graph index to the 
                    let index = _.findIndex(this.kpis, { id : newVal.id});
                    if(index != -1){
                        this.graph_index = index;
                    }
            }
        },
        kpis(){
            //* always show graph view when data updates
            this.show_table = false;
        },
        //table_state(newValue){
        //    alert(newValue)
        //    if(newValue != null){
        //        alert('setting state')
        //        this.show_table = newValue;
        //        }
        //    },
        //kpis(){
        //    if(this.kpis.length > 0){
        //        alert('kpis > 0')
        //        this.setSelectedKPI(this.kpis[0]);
        //        this.show_table = false;
        //    }
        //    else{
        //        this.show_table = true;
        //    }
        //},
        selected_key_result:{
            //! removed deep
            //deep: true,
            handler(newVal, oldVal){
                if(this.kpi_display_type !== 'KeyResult'){
                    this.setGraphDisplayType('KeyResult')
                }

                if(oldVal == null){
                    this.graph_index = 0;
                }
                else if(newVal == null){
                    this.graph_index = 0;
                }
                else if(newVal.id != oldVal.id){
                    this.$nextTick(()=>{
                        if(this.selected_key_result.kpis.length == 0){
                            this.graph_index = 0;
                            this.setSelectedKPI(null);
                        }
                        else{
                            this.graph_index = 0;
                            this.setSelectedKPI(this.selected_key_result.kpis[0]);
                        }
                        
                    })
                }
            }
        }
    },
    methods: {
        ...activityMethods,
        ...cardHeightMethods,
        ...levelMethods,
        ...uxToggleMethods,
        ...performanceMethods,
        async toggleTableView(){
            if(this.show_table){
                //* only run if table is visible 
                if(await this.$hasUnsavedChanges(this.table_uid)){
                    //* stop if unsaved changes are found
                    return false;
                }
                else{
                    this.$refs[this.table_uid].table_mode = 'view'; 
                }
            }
            this.show_table = !this.show_table;
        },
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        selectedCardEvent(item){
            if(this.getActiveKpiID == item.id){
                this.setSelectedKPI(null);
            }
            else{
                var index = _.findIndex(this.kpis, { id: item.id });
                if (index != -1) {
                    this.setSelectedKPI(this.kpis[index])
                }
            }
        },
        selectedRowEvent(row){
            //* Event returns the selected row
            if(!row){
                this.setSelectedKPI(null);
                return;
            }
            //let kpis_array = this.selected_key_result ? this.selected_key_result.kpis : [];
            var index = _.findIndex(this.kpis, { id: row.id });
            if (index != -1) {
                this.setSelectedKPI(this.kpis[index])
            }
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected key results?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteKpi({kpi_id: item.id}));
            })
            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                Swal.fire({
                    icon:'success',
                    title:'Deleted selection',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })

                let params2 = {
                    level_id: this.current_level_id,
                    graph_data: 1
                }
                this.loadPerformanceScreenData({
                    type: 'targets',
                    params: params2,
                })
                .then(()=>{
                    //window.webix.$$('keyResultsTable').select(this.selected_key_result.id)
                    this.is_loading = false;
                    this.delete_array = [];
                    if(this.$refs[this.table_uid]){
                        this.$refs[this.table_uid].table_mode = 'view';
                    }
                })
            });
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        saveTable(data){
            this.is_loading = true;
            //eslint-disable-next-line
            let rows_to_save = [];
            data.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        target_id: this.getSelectedTarget.id,
                        objective_id: 431,
                        id: newRow ? -1 : row.id,           //
                        //level_id: this.current_level_id,                       //
                        owner_id: row.owner_user_id,   //
                        name: row.name,                     //
                        show_cumulative: true,
                        show_cumulative_target: true,
                        unit_type_id: row.unit_type_id,
                        graph_type: 'line',                 //
                        description: row.description,
                        min_value: row.min_value,           //
                        max_value: row.max_value,
                        current_time_period: row.time_period,
                        start_date: row.start_date,         //
                        deadline: row.end_date,
                        category: row.category,
                        graph_size: '12'                    //only one graph displays at a time, so force full width graph
                    };
                    if(params.start_date == null || params.start_date == undefined){
                        params['start_date'] = new Date();
                    }
                    if(params.deadline == null || params.deadline == undefined){
                        params['deadline'] = new Date();
                    }

                    if(params.start_date != undefined && typeof params.start_date !== 'string'){
                        //alert(params.start_date)
                        params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                    }
                    if(params.end_date != undefined && typeof params.end_date !== 'string'){
                        //alert(params.end_date);
                        params.end_date = format(new Date(params.end_date), 'yyyy-MM-dd')
                    }
                    rows_to_save.push(params);
                }
            })

            let params = {
                kpis: rows_to_save,
            }
            this.is_loading = true;
            this.saveKpi(params)
            .then(()=>{
                let table_ref = this.$refs[this.table_uid];
                if(table_ref){
                    table_ref.changes_made_flag = false;
                }
                
                let params2 = {
                    level_id: this.current_level_id,
                    graph_data: 1
                }
                this.loadPerformanceScreenData({
                    type: 'targets',
                    params: params2,
                })
                .then(()=>{
                    //window.webix.$$('keyResultsTable').select(this.selected_key_result.id)
                    this.is_loading = false;
                    if(this.$refs[this.table_uid]){
                        this.$refs[this.table_uid].table_mode = 'view';
                    }
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })

        },
        
        handleDuplicateionItemsInsert(payload){//table_uid and target_id
            let index = this.duplicate_clipboard.findIndex( clipboard => {
                return payload.table_uid == clipboard.uid;
            })
            if(index == -1){
                alert('Error')
                return;
            }
            else{
                this.is_loading = true;
                let data = {
                    destination_type: 'key_result',
                    destination_id: this.selected_key_result.id,
                    sources: this.duplicate_clipboard[index].rows,
                    source_type:'kpi',
                }
                this.sendDuplicationRequest(data)
                .then(()=>{
                    let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        window.webix.$$('keyResultsTable').select(this.selected_key_result.id)
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$swal.fire({
                            icon:'success',
                            title:'Success',
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },

    setGraphDisplayType(type){
      this.graph_index = 0;
      this.kpi_display_type = 'loading';
      setTimeout(()=>{
        this.kpi_display_type = type;
      }, 400)
    },
    updateTrackingPeriods(type){
      this.is_updating_graph = true;
      let promises = [];
      this.kpis.forEach( kpi => {
        if(kpi.current_time_period !== type){
          let kpi_param = {
            kpi_id: kpi.id,
            type: type,
          }
          promises.push(this.traverseKPI(kpi_param))
        }
      });
      Promise.all(promises)
      .then(()=>{
        let objective_params = {
            id: this.selected_objective.id,
            user_id: this.activeUserId,
        }
        this.loadObjectiveDetailAPI(objective_params)
        .then(()=>{
          this.update_kpi_graphs = true;
          this.$nextTick(() => {
            this.update_kpi_graphs = false;
            this.is_updating_graph = false;
          });
        })
      })
    },
    updateTrackingPeriod(payload){ // type - id
      this.changeTrackingPeriod(payload);
    },
    applyKeyResultChange() {
      //* Check if start date is before the ending date
      let valid = isBefore(parseISO(this.kr_start), parseISO(this.kr_end));
      if (valid) {
        //* dunno why i did this backwards
          this.update_kpi_graphs = true;
          this.$nextTick(() => {
            this.update_kpi_graphs = false;
          });
      } else {
        //TODO - change to sweet-alert toast
        alert("Please choose a valid date range");
      }
    },
    resetKeyResultChange(){
      //* Reset graph date tracking
      this.kr_start = null;
      this.kr_end = null;
      this.update_kpi_graphs = true;
      this.$nextTick(() => {
        this.update_kpi_graphs = false;
      });
    },
    changeTrackingPeriod(payload){
        let params = {
          kpi_id: payload.id,
          type: payload.type,
        }
        let objective_params = {
            id: this.selected_objective.id,
            user_id: this.activeUserId,
        }
        this.is_updating_graph = true;
        this.traverseKPI(params)
        .then(()=>{
          this.loadObjectiveDetailAPI(objective_params)
          .then(()=>{
            this.update_kpi_graphs = true;
            this.$nextTick(() => {
              this.is_updating_graph = false;
              this.update_kpi_graphs = false;
              //this.showKeyGraph = true;
              
            });
          })
        })
    },
    showModal() {
      this.form_data.graph_size = "12";
      this.form_data.graph_type = "line";
      this.form_data.time_period = "daily";

      switch(this.kpi_display_type){
        case 'Level':{
          this.save_type = 'level';
          break;
        }
        case 'Objective':{
          this.save_type = 'objective';
          break;
        }
        case 'KeyResult':{
          this.save_type = 'key_result';
          break;
        }
      }
      /*this.save_type = {
        level: false,
        objective: true,
        key_result: false,
      },*/
      this.save_type = '',
      this.show_modal = true;
      this.$nextTick(() => {
        this.$bvModal.show("kpiModal");
      });
    },
    linkGraph() {},
    save() {
      if (this.save_type === '') {
        //* check if all 3 values are false -- show alert
        alert("Please select a linking type");
      } else {
        this.form_data["level_id"] = null;
        this.form_data["objective_id"] = null;
        this.form_data["key_result_id"] = null;

        //* add required ID's to selected linking types
        if (this.save_type === 'level') {
          this.form_data["level_id"] = this.selected_level.id;
          this.form_data["objective_id"] = -1;
          this.form_data["key_result_id"] = -1;
        }
        else if (this.save_type ===  'objective') {
          this.form_data["objective_id"] = this.selected_objective.id;
          this.form_data["level_id"] = -1;
          this.form_data["key_result_id"] = -1;
        }
        else if (this.save_type === 'key_result') {
          this.form_data["key_result_id"] = this.selected_key_result.id;
          this.form_data["level_id"] = -1;
          this.form_data["objective_id"] = -1;
        }

        this.form_data["id"] = -1;
        this.is_loading = true;
        this.saveKpi(this.form_data).then(() => {
          this.form_data = {};
          /*this.save_type = {
            level: false,
            objective: false,
            key_result: false,
          };*/
          this.save_type = '';
          if(this.kpi_display_type === 'Level'){
            this.loadActivityData(this.selected_level.id)
            .then(()=>{
              this.is_loading = false;
              this.show_modal = false;
            })
          }
          /*else if(this.kpi_display_type === 'KeyResult'){
            alert('Reload KR')
          }*/
          else{
            let params = {id:this.selected_objective.id, user_id: this.activeUserId}
            this.loadObjectiveDetailAPI(params)
            .then(()=>{
              this.is_loading = false;
              this.show_modal = false;
            })
          }
        });
      }
    },
    showUnitTypeModal(){
        this.show_units_modal = true;
        this.$nextTick(()=>{
            this.$bvModal.show('unitTypesModal');
            this.$root.$once("bv::modal::hidden", (event) => {
                if (event.type == "hidden" && event.componentId == "unitTypesModal") {
                    this.show_units_modal = false;
                    //* refresh the array of avaliable unit types
                    window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.clearAll();
                    this.availableUnitTypes.forEach((item)=>{
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.add(item);
                    })
                    window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                    window.webix.$$(this.table_uid).refreshColumns();
                    window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                    window.webix.$$(this.table_uid).refresh();
                }
            });
        })
    },

    //* Graph events **/
    handleLinkEvent(id) {
      let index = _.findIndex(this.kpis, { id: id });
      if (index != -1) {
        this.linking_item = this.kpis[index];
        this.$nextTick(() => {
          this.$bvModal.show("linkingModal");
          this.$root.$once("bv::modal::hidden", (event) => {
            if (event.type == "hidden" && event.componentId == "linkingModal") {
              this.linking_item = null;
            }
          });
        });
      }
    },

    graphNext(){
        this.show = false;
        if(this.graph_index < this.kpis.length-1){
            this.graph_index++;
        }
        else{
            this.graph_index = 0;
        }
        this.$nextTick(()=>{
            if(this.kpis[this.graph_index] != undefined){
                this.setSelectedKPI(this.kpis[this.graph_index]);
            }
            this.show = true;
        })
    },
    graphPrevious(){
        this.show = false;
        if(this.graph_index > 0){
            this.graph_index--;
        }
        else{
            this.graph_index = this.kpis.length-1;
        } 
        this.$nextTick(()=>{
            if(this.kpis[this.graph_index] != undefined){
                this.setSelectedKPI(this.kpis[this.graph_index]);
            }
            this.show = true;
        })
    },
  },
    mounted(){
        
        if(this.selected_key_result != null){
            this.kpi_display_type = 'KeyResult';
        }
        else if(this.selected_objective != null){
            this.kpi_display_type = 'Objective';
        }
        else{
            this.kpi_display_type = 'Level';
        }
        /*this.$nextTick(()=>{
            if(this.kpis.length > 0){
                this.show_table = false;
                
            }
        });*/
        //Fix premature
        //if(this.table_state != null){
        //    this.show_table = this.table_state;
        //}
    },
    beforeDestroy(){
        this.setNewHeightState({
            size: 'default',
            type: 'kpi',
        });
    }
};
</script>

<style></style>
