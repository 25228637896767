<template>
    <b-card no-header class="tactical bg-light bg-soft border border-warning card-hover">
        <template #header>
            <div class="d-flex">
                <strong>
                    Tactical Plan
                </strong>
                <span class="ms-auto">
                    <span><b-button v-show="hide_projects" size="sm" variant="primary" @click="hideProjects(false)">Show Projects ({{ projects_count }})</b-button>
                        <b-button v-show="!hide_projects" size="sm" variant="primary" @click="hideProjects(true)">Hide Projects ({{ projects_count }})</b-button></span>
                </span>
            </div>
        </template>
        <section v-show="!hide_projects" class="normal-text">
            <b-card>
                <projects></projects>
            </b-card>
        </section>
        <section class="normal-text">
            <ActionPlans></ActionPlans>
        </section>
    </b-card>
</template>

<script>
import projects from '../components/cards/projects.vue';
import ActionPlans from '../components/cards/action-plans.vue';
import { ganttComputed, ganttMethods } from "@/state/helpers.js";
export default {
    data:() => ({}),
    components:{
        ActionPlans,
        projects
    },
    computed:{
        ...ganttComputed,
        projects_count() {
            if(this.projects.length != 0) {
                return this.projects.length
            } else {
                return 0
            }
        }
    },
    methods:{
        ...ganttMethods
    },
    beforeDestroy(){
    },
    mounted(){
    },
}
</script>

<style>

</style>