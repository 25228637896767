<template>
    <b-dropdown menu-class="border border-grey p-1 dropdown-menu-end" left class="me-2" size="sm" variant="light" :text="getQuarterText" ref="quarterDropdown">
            <div class="w-100 btn-group">
                <b-button @click="setViewingQuarter(0)" :variant="objective_viewing_quarter == 0 ? 'primary':'outline-dark'" block size="sm">All</b-button>
                <b-button @click="setViewingQuarter(1)" :variant="objective_viewing_quarter == 1 ? 'primary':'outline-dark'" block size="sm">Q1</b-button>
                <b-button @click="setViewingQuarter(2)" :variant="objective_viewing_quarter == 2 ? 'primary':'outline-dark'" block size="sm">Q2</b-button>
                <b-button @click="setViewingQuarter(3)" :variant="objective_viewing_quarter == 3 ? 'primary':'outline-dark'" block size="sm">Q3</b-button>
                <b-button @click="setViewingQuarter(4)" :variant="objective_viewing_quarter == 4 ? 'primary':'outline-dark'" block size="sm">Q4</b-button>
            </div>
    </b-dropdown>
</template>

<script>
import { activityComputed, activityMethods } from '@/state/helpers'
export default {
    data:() => ({}),
    computed:{
        ...activityComputed,
        getQuarterText(){
            if(this.objective_viewing_quarter == 0){
                return 'All Quarters'
            }
            else{
                return `Quarter ${this.objective_viewing_quarter}`;
            }
        },
    },
    methods:{
        ...activityMethods,
        
        setViewingQuarter(quarter){
            this.setObjectiveQuarter(quarter)
            this.$refs.quarterDropdown.hide();
            this.filterObjectivesByQuarter();
        }
    },
    mounted(){
        
    },
}
</script>

<style>

</style>