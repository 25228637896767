<template>
    <div v-if="uid != null">
        <b-collapse v-model="show_detailed" :accordion="getAccordionName">
            <slot name="detailed">

            </slot>
        </b-collapse>
        <b-collapse v-model="show_collapsed" :accordion="getAccordionName">
            <slot name="compact">

            </slot>
        </b-collapse >
    </div>
</template>

<script>
import {
    performanceComputed,
    performanceMethods,
} from "@/state/helpers";
export default {
    props:{
        detailed_view_active:{
            default: true,
        }
    },
    data:() => ({
        uid: null,
        show_detailed: false,
        show_collapsed: false,
    }),
    watch:{
        detailed_view_active:{
            immediate: true,
            handler(is_detailed_view_expanded){
                if(is_detailed_view_expanded){
                    this.showDetailed();
                }
                else{
                    this.hideDetailed();
                }
            }
        }
    },
    computed:{
        ...performanceComputed,
        getAccordionName(){
            return `accordion-${this.uid}`;
        }
    },
    methods:{
        ...performanceMethods,
        hideDetailed(){
            this.show_detailed = false;
            this.show_collapsed = true;
            this.showStrategicPlanDetails(this.show_detailed)
        },
        showDetailed(){
            this.show_collapsed = false;
            this.show_detailed = true;
            this.showStrategicPlanDetails(this.show_detailed)
        },
        handleToggle(){
            if(this.show_collapsed){
                this.showDetailed();
            }
            else{
                this.hideDetailed();
            }
        }
    },
    mounted(){},
    created(){
        this.uid = window.webix.uid();
    },
}
</script>

<style>

</style>