<template>
    <div>
        <!-- // REMOVED PROPS -->
        <!-- 
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @saveTable="saveTable"
            @deleteRows="deleteRow"
            :inner_card_component_name="'milestoneTile'"
            :default_values="default_values"
            :variable_watchers="variable_watchers"
            :paste_rules="paste_rules"
            :validation="table_validation_rules"
            :button_methods="button_methods"
        -->
        <TableComponent
            :columns="columns"
            :simple_columns="simpleColumns"
            :active_id="activeId"
            :uid="table_uid"
            :ref="table_uid"
            :data="goalObjectivesData"
            enable_pretty_mode
            :is_loading="isLoading"
            :context_menu_items="['View', 'Edit', 'Delete']"
            prevent_first_row_select
            has_header_filters
            :badge_class="'badge-item badge-item-goal-objective'"
            :default_sort_column="'name'"
            :required_permissions="['milestones', 'milestones-manager']"
            enforce_structure_checks
            :edit_permissions="{manager:'milestones-manager', user:'milestones'}"
            @selectedRowEvent="selectedRowEvent"
        >
            <template #title>
                <span>Objectives</span>
                <span>&nbsp;&nbsp;>&nbsp;&nbsp;</span>
                <span class="text-primary">Selected item name</span>
            </template>
        </TableComponent>
    </div>
</template>

<script>
import TableComponent from '@/components/widgets/table/table.vue'
import CommentModal from "@/components/widgets/comment.vue";
import _ from "lodash";
//* VUEX
import { performanceComputed, performanceMethods } from '@/state/helpers';
export default {
    components:{
        // eslint-disable-next-line
        TableComponent, CommentModal
    },
    data:() => ({
        table_uid: 'goalObjectivesTable',

    }),
    computed:{
        ...performanceComputed,
        goalObjectivesData(){
            return _.cloneDeep( this.goal_objectives );
        },
        activeId(){
            const item = this.selectedGoalObjective;
            return item ? item.id : -1;
        },
        columns(){
            return [{
                id: 'name', header: 'Name', fillspace: true, editor: 'text'
            }]
        },
        simpleColumns(){
            return [{
                id: 'name', header: 'Name', fillspace: true,
            }]
        },
        isLoading(){
            return false;
        }
    },
    methods:{
        ...performanceMethods,
        selectedRowEvent(item){
            const id = item != null ? item.id : -1
            this.selectGoalObjective(id);
        }
    },
    mounted(){},
}
</script>

<style>

</style>