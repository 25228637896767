<template>
    <b-card class="m-3 p-2">
        <b-alert show variant="danger">
            This graph was added in the <strong>Model</strong> screen. The required fields need to be saved before graph data can be added.
        </b-alert>
        <b-form @submit.stop.prevent="handleSubmit">
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Minimum Value:" label-for="minValueInput">
                        <b-form-input
                            id="minValueInput"
                            v-model="formData.min_value"
                            type="number"
                            size="sm"
                            required
                            min="0"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Maximum Value:" label-for="maxValueInput">
                        <b-form-input
                            id="maxValueInput"
                            v-model="formData.max_value"
                            type="number"
                            required
                            min="0"
                            size="sm"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <!-- <b-row>
                
                <b-col cols="6">
                    <b-form-group label="Start Date:" label-for="startDateInput">
                        <b-form-input
                            id="startDateInput"
                            v-model="formData.start_date"
                            type="date"
                            required
                            size="sm"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Deadline:" label-for="deadlineInput">
                        <b-form-input
                            id="deadlineInput"
                            v-model="formData.deadline"
                            type="date"
                            size="sm"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row> -->


            <b-row>
                <b-col cols="6">
                    <b-form-group label="Target Value:" label-for="targetInput">
                        <b-form-input
                            id="targetInput"
                            v-model="formData.target"
                            type="number"
                            required
                            size="sm"
                            min="0"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Time Period:" label-for="periodInput">
                        <b-form-select
                        class="make-sm form-select"
                            id="periodInput"
                            v-model="formData.current_time_period"
                            required
                            size="sm"
                            :options="options">
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-button size="sm" type="submit" variant="primary">Submit</b-button>
        </b-form>
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </b-card>
</template>

<script>
import {
    levelComputed,
    performanceMethods,
    performanceComputed,
} from "@/state/helpers";
export default {
    props:{
        item: { required: true,}
    },
    computed:{
        ...levelComputed,
        ...performanceComputed,
    },
    data:() => ({
        is_loading: false,
        formData: {
            min_value: null,
            max_value: null,
            target: null,
            //start_date: null,
            //deadline: null,
            current_time_period: null,
        },
        options: [
            { value: null, text: 'Please select an option' },
            { value: 'daily', text: 'Daily'},
            { value: 'weekly', text: 'Weekly'},
            { value: 'monthly', text: 'Monthly'},
            { value: 'quarterly', text: 'Quarterly'},
            { value: 'yearly', text: 'Yearly'},
        ]
    }),
    methods:{
        ...performanceMethods,
        handleSubmit() {
            // You can perform form validation here if needed
            // For example, check if min_value < max_value, start_date < deadline, etc.
            if (this.formData.min_value >= this.formData.max_value) {
                // Handle validation error
                alert('Minimum Value must be less than Maximum Value');
                return;
            }
            // Handle form submission
            let targ = {
                //milestone_objective_id: row.milestone_objective_id ? row.milestone_objective_id : this.selected_objective.id,//row.milestone_objective_id,
                id: this.item.id,
                level_id: this.item.level_id,
                owner_user_id: this.item.owner_user_id,
                name: this.item.name,
                target: this.formData.target,
                //status: '',
                //start_date: this.formData.start_date,
                //deadline: this.formData.deadline,
                unit_type_id: this.item.unit_type_id,
                graph_type: 'line',
                min_value: this.formData.min_value,
                max_value: this.formData.max_value,
                current_time_period: this.formData.current_time_period,
                graph_size: '12', //only one graph displays at a time, so force full width graph
                show_cumulative: 0,
                show_cumulative_target: 0,
                //notes: row.notes != null ? row.notes : '',
            }
            let target_params = {
                targets: [targ]
            }
            this.saveTargets(target_params)
            .then(()=>{
                let params2 = {
                    level_id: this.current_level_id,
                //user_id: this.selected_user_id ? this.selected_user_id : 0,
                //objective_id: this.selected_goal_objective,
                //objective_data: 1,
                graph_data: 1
            }
            this.loadPerformanceScreenData({
                type: 'targets',
                params: params2,
            })
                .then(()=>{
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    mounted(){},
}
</script>

<style>
.make-sm{
    height: 25px;
    
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>