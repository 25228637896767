<template>
    <div>
        <b-card no-header class="strategic bg-light bg-soft border border-primary card-hover">
            <template #header>
                <div class="d-flex">
                    <strong>
                        Strategic Plan
                        <span v-show="!hide_strategic_plan_details"> (Collapsed)</span>
                    </strong>
                    <span class="ms-auto"></span>
                    <ViewingQuarterDropdown v-if="false" />
                    <span v-if="false">

                        <b-button v-show="view_type == 'COMPANY'" class="pt-0 pb-0 w-lg" variant="outline-primary" size="sm" @click="show_vision_mission = !show_vision_mission">
                            {{ show_vision_mission ? 'Hide' : 'Show'}} Vision / Mission
                        </b-button>
                        &nbsp;
                        <b-button v-show="view_type == 'COMPANY'" class="pt-0 w-sm pb-0" variant="light" size="sm" @click="$refs.detailedViewContainer.handleToggle()">
                            <span v-show="hide_strategic_plan_details">
                                Hide Details
                            </span>
                            <span v-show="!hide_strategic_plan_details">
                                Show Details
                            </span>
                        </b-button>
                    </span>
                </div>
            </template>

            <section class="normal-text">
                <b-alert show v-if="view_type == 'COMPANY' &&  this.has_loaded_milestones && this.company_milestones.length == 0" variant="danger">
                    <div class="">
                        <strong>
                            <font-awesome-icon icon="
                            fa-exclamation-triangle" />
                            No Milestones have been added.
                        </strong>
                        &nbsp;
                        <span>
                            Please create a Milestone before adding Objectives
                        </span>
                        
                    </div>
                    <b-button @click="sendMilestoneEditEvent()" size="sm" variant="primary" class="mt-2">
                            Edit Milestones
                        </b-button>
                    
                </b-alert>

                <DetailedCollapedView ref="detailedViewContainer">
                    <template #detailed>
                        
                        
                        <div v-if="view_type == 'COMPANY'">
                            <b-collapse v-model="show_vision_mission">
                                <b-row class="mb-2">
                                    <b-col class="align-items-stretch" cols="6">
                                        <b-card class="h-100">
                                            <Vision @reloadData="reloadData()" :data="vision" :title="selected_level.name"  />
                                        </b-card>
                                    </b-col>
                                    <b-col class="align-items-stretch" cols="6">
                                        <b-card class="h-100">
                                            <Mission @reloadData="reloadData()" :data="mission" :title="selected_level.name" />
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-collapse>

                            <b-row>
                                <b-col cols="12" v-if="false">
                                    <b-card>
                                        <MissionMilestones
                                        :data="milestones"
                                        :title="selected_level.name"
                                    />
                                    </b-card>
                                </b-col>
                                <!-- <b-col cols="12">
                                    <b-card>
                                        <MilestoneStrategy/>
                                    </b-card>
                                </b-col> -->
                                <b-col cols="12">
                                    <b-card>
                                        
                                            <!-- supplied key to prevent component repaint error -->
                                            <Objectives :key="'system_objectives'" objective_type="milestone" />
                                    </b-card>
                                </b-col>
                                <!-- <b-col cols="12">
                                    <b-card>
                                        <Objectives objective_type="goal"/>
                                    </b-card>
                                </b-col> -->
                            </b-row>
                        </div>
                        <div>
                            <b-card v-if="view_type == 'USER'">
                                <!-- supplied key to prevent component repaint error -->
                                <Objectives :key="'user_objectives'"  objective_type="milestone" />
                            </b-card>
                            <slot name="okrs"></slot>
                        </div>
                    </template>
                    <template #compact>
                        <StrategicPlanCompactView v-if="false" />
                    </template>
                </DetailedCollapedView>
                
            </section>
        </b-card>
    </div>
</template>

<script>
//import ObjectiveKpis from "@/components/objectives/components/cards/kpis-objective.vue";
import MissionMilestones from "@/components/objectives/components/cards/mission-milestones-new.vue";
//import OperationalMetrics from "@/components/objectives/components/cards/metrics.vue";
//import MissionObjectives from "./components/cards/mission-objectives-new.vue";
//import KeyResults from "./components/cards/key-results-plan.vue";
//import AssignedItems from "./components/cards/assigned-items.vue";
//import ActivityList from "@/components/objectives/components/cards/action-plans.vue";
import Vision from "@/components/objectives/components/cards/vision.vue";
import Mission from "@/components/objectives/components/cards/mission.vue";
//import NotesModal from '@/components/objectives/components/misc/notesModal.vue'
//import kanbanView from '../kanban/kanbanView.vue';
//import MilestoneStrategy from "@/components/objectives/components/cards/MilestoneStrategy.vue";
import Objectives from "@/components/objectives/components/cards/mission-objectives-new.vue";
// eslint-disable-next-line
import ViewingQuarterDropdown from "../components/misc/ViewingQuarterDropdown.vue";
import GoalObjectives from "@/components/objectives/components/cards/GoalObjectives.vue";
import DetailedCollapedView from "@/components/widgets/misc/detailedCompactView.vue";
import StrategicPlanCompactView from './StrategicPlanCompactView.vue'
//Vuex imports
import {
    levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    performanceComputed,
    performanceMethods,
} from "@/state/helpers";
import _ from 'lodash';
import miletoneHelpers from '@/mixins/milestoneHelpers.js'
export default {
    mixins: [ miletoneHelpers ],
    props:{
        view_type:{}
    },
    components:{
        //MilestoneStrategy,
        MissionMilestones,
        Objectives,
        // eslint-disable-next-line
        GoalObjectives,
        // Kpis,
        // ObjectiveKpis,
        // MissionObjectives,
        // KeyResults,
        // ActivityList,
        // OperationalMetrics,
        Vision, Mission,
        // AssignedItems,
        // NotesModal,
        // kanbanView,
        DetailedCollapedView,
        StrategicPlanCompactView,
        ViewingQuarterDropdown,
    },
    data:() => ({
        //show_vision_mission: true,
    }),
    watch:{
        
        view_type(new_type){
            //* handle detailed view state, if collapsed then set to expanded
            let is_company_level = this.company_id == this.current_level_id;
            if(is_company_level == false){
                this.$refs.detailedViewContainer.showDetailed();
            }

            if(new_type == 'COMPANY' || new_type == 'USER'){
                this.$nextTick(()=>{
                    let table = window.webix.$$('milestonesTable');
                    if(table){ table.refresh(); }
                })
            }
        },
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        show_vision_mission:{
            get(){
                return this.show_vision_mission_cards
            },
            set(value){
                this.setVisionMissionCardVisibility(value)
            }
        },
        milestones(){
            if(this.company_milestones){
                return _.cloneDeep(this.company_milestones);
            }
            else{
                return []
            }
        },
        vision() {
            var vision = "";
            if (this.company_level_data && this.company_level_data.vision) {
                vision = this.company_level_data.vision;
            }
            return vision;
        },
        mission() {
            var mission = "";
            if (this.company_level_data && this.company_level_data.mission) {
                mission = this.company_level_data.mission;
            }
            return mission;
        },
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...performanceMethods,
        reloadData(){
            let params = {
                level_id: this.current_level_id,
                user_id: this.selected_user_id ? this.selected_user_id : 0,
                milestone_data: 1,
                milestone_id: this.selected_milestone.id
            }
            this.loadPerformanceScreenData({
                type: 'level',
                params: params,
            })
        },
        sendMilestoneEditEvent(){
            this.$eventHub.$emit('editMilestoneTable');
        }
    },
    mounted(){},
}
</script>

<style>
    .strategic > .card-header{
        background-color: rgba(85, 110, 230, 0.25) !important;
    }
    .operational > .card-header{
        background-color: rgba(52, 195, 143, 0.25) !important;
    }

    .tactical > .card-header{
        background-color: rgba(241, 180, 76, 0.25) !important;
    }
</style>