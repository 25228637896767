<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-03T10:24:59+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-15T20:16:26+02:00
@Copyright: Diversitude 2021
-->



<template>
    <div id="missionObjectiveCard">
        <Table
            :columns="columns"
            :simple_columns="simple_columns"
            :active_id="active_id"
            :uid="table_uid"
            :ref="table_uid"
            :data="formattedMilestoneData"
            enable_pretty_mode
            :min_height="166"
            :is_loading="is_loading"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :only_editable_columns="true"
            :child_uid="['objectivesTable', 'activityList', 'keyResultsTable', 'krKpiTable', 'metricsTable']"
            prevent_first_row_select
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @updatedOrderEvent="onRowIndexUpdate"
            @saveTable="saveTable"
            @deleteRows="deleteRow"
            re_order
            has_header_filters
            :inner_card_component_name="'milestoneTile'"
            :badge_class="'badge-item badge-item-milestone'"
            :default_sort_column="'year'"
            :default_values="default_values"
            :variable_watchers="variable_watchers"
            :paste_rules="paste_rules"
            :validation="table_validation_rules"
            :required_permissions="['milestones', 'milestones-manager']"
            enforce_structure_checks
            :edit_permissions="{manager:'milestones-manager', user:'milestones'}"
            :button_methods="button_methods"
            guideline_key="mission_milestones"
        >
            <template #title>
                {{ titleName }}
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span class="text-primary">
                    {{levelName}}
                </span>
            </template>
        </Table>

        <!-- Content -->
        <div class="">
            <div>
            <!-- flip front -->
                <!-- flip back -->
                <div class="" v-if="!flip">
                <!-- Guideline -->
                    <Guideline
                    v-if="isShow.isGuideline || isEditGuideline"
                    ref="guideline"
                    :content="data.guideline"
                    :cardName="'Objective'"
                    :isEditGuideline="isEditGuideline"
                    @refresh="update('guideline')"
                    />
                    <Comment
                    v-if="isShow.isComment"
                    ref="comment"
                    :comment="data.comments"
                    :cardName="'Milestone'"
                    :isAddComment="isAddComment"
                    @refresh="update"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'

import Comment from "@/components/widgets/comment.vue";
import _ from "lodash";
import Guideline from "@/components/objectives/components/guideline.vue";
import {
  levelComputed,
  levelMethods,
  activityMethods,
  activityComputed,
  adminComputed,
  adminMethods,
  authMethods,
  webixTableState,

  performanceMethods, performanceComputed,
} from "@/state/helpers";
import Swal from 'sweetalert2'
//eslint-disable-next-line
import { parseISO, format, isEqual, isAfter, isBefore} from 'date-fns'
//eslint-disable-next-line
import { startOfYear, endOfYear, getYear } from 'date-fns'
import dateMixin from '@/mixins/dateMixin.js'
export default {
    mixins:[ dateMixin ],
    props: {
        data: {},
        title: { type: String },
        isShowObjectiveRisks: { type: Boolean },
    },
    components: {
        Guideline,
        Comment,
        Table,
        
    },
    data: () => ({
        pretty_mode: false,

        //TODO - Find the unused vars -- damn.. lol

        table_uid: 'milestonesTable',
        table_view_mode: 'view',
        is_loading: false,

		delete_array: [],

        edit_mode: false,
        changes_made_flag: false,
        isAddComment: false,
        filter: {
        dateStart: "",
        dateEnd: "",
        },
        isFormError: false,
        smart: {
            specific: false,
            measurable: false,
            acheivable: false,
            realistic: false,
            time_based: false,
        },
        isEditGuideline: false,
        isShow: {
            isCancel: false,
            isView: true,
            isEditingExisting: false,
            isEditingNew: false,
            isComment: false,
            isGuideline: false,
        },
        flip: true,
        show_table: false,
        objective_data: null,
    }),
    watch: {
        edit_mode(newValue){
            this.toggleEditMode(newValue)
        },
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
    },
  computed: {
    ...performanceComputed,
    ...levelComputed,
    ...activityComputed,
    ...adminComputed,
    ...webixTableState,
    variable_watchers(){
        let el = this;
        return {
            //eslint-disable-next-line
            'year'(newVal, oldVal, id, is_new_row){
                if(newVal != null){
                    console.log('NewVal', newVal)
                    let item = window.webix.$$(el.table_uid).getItem(id);
                    item['start_date'] = startOfYear(el.getDate(newVal));
                    item['end_date'] = endOfYear(el.getDate(newVal));

                    window.webix.$$(el.table_uid).updateItem(item.id, item);
                }
            }
        }
    },
    isEditingTable(){
        if(this.$refs[this.table_uid]){
            return this.$refs[this.table_uid].table_mode == 'edit'
        }
        else{
            return false;
        }
    },
    canShowHeaderFilters(){
        if(this.table_states[this.table_uid] == undefined){
            return false;
        }
        else{
            return this.table_states[this.table_uid].show_datatable_filters;
        }
    },
    isMilestoneSystemDefault(){
        if(!this.core){
            return false;
        }
        if(this.hasMilestoneSelected == false || this.core.default_milestone == null ){ return false; }
        return this.selected_milestone.id == this.core.default_milestone.id;
    },
    getDefaultMilestoneButtonText(){
        if(this.isMilestoneSystemDefault){
            return "Default Milestone"
        }
        else{
            return "Set As System Default"
        }
    },
    levelName(){
        if(this.selected_level != null){
            return this.selected_level.name;
        }
        else{
            return '';
        }
    },
    userList(){
        let users = [];
        _.forEach(this.all_users, function(value) {
            users.push({id: value.id, value: value.name, email: value.email});
        });
        return users;
    },
    //* table computed props
    //active_id(){
    //    if(this.$route.params.milestone_id != undefined){
    //        return Number(this.$route.params.milestone_id);
    //    }
    //    else{
    //        return this.pinned_milestone_id
    //    }
    //},
    default_milestone(){
        let id = this.core.default_milestone.id
        return id;
    },
    active_id(){
        if(this.pinned_milestone_id != -1){
            return this.pinned_milestone_id;
        }
        //else if(this.core.default_milestone){
        //    let id = this.core.default_milestone.id
        //    return id;
        //}
        else{
            return -1;
        }
    },
    simple_columns(){
        let el = this;
        return [
            {   
                id: "progress",
                tooltip: '#progress_value#%',
                header: "Progress",
                width: '100', compact: true,
                sort: 'string',
                format: (value) => {
                    if(value != undefined){
                        return `<div class="bar mt-2"><div class="bar-inner" style="width: ${value}%"></div></div>`
                    }
                    else{
                        return `<div class="bar mt-2"><div class="bar-inner" style="width: 0%"></div></div>${value}`;
                    }
                },
            },
            {
                id: 'year', editor: 'yearly',  header: 'Year', width: 60,
                template(obj){
                    if(obj.is_empty){ return '' }

                    let val = '';
                    if(!obj.year || obj.year == -1){
                        val = new Date();
                    }
                    else{
                        val = new Date(obj.year);
                    }
                    return format(val, 'yyyy');
                }
            },
            { id: "name", editor: "text", header: this.canShowHeaderFilters ? ["Milestone", {content: "textFilter"}] : "Milestone",
                fillspace: true, sort: el.tableSortText, css: { "text-align": "left", "word-wrap": "break-word" }, tooltip: '',
            },
            { id: "end_date", editor: "date", header: "End Date", minWidth: 120, tooltip: '',
                sort: 'string', css: { "text-align": "left", "word-wrap": "break-word" },
                format:function(value){
                    return el.parseDateFormat(value);
                }
            },
            { id: "owner_user_id", editor: "combo", minWidth: 120, options: this.userList,
                header: this.canShowHeaderFilters ? ["Owner", { content:"richSelectFilter" }] : "Owner",
                tooltip: '',
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
            },
            {
                id: 'notes',
                header: "Notes",
                width: 60,
                template:function(obj){
                    if(obj.is_new || obj.is_empty){
                        return '';
                    }
                    if(obj.notes == null || obj.notes == ''){
                        return '';
                    }
                    return `
                    <div style='text-align: center' class="notes_button">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                    </div>
                    `
                }
            }
        ]
    },
    columns(){
        let el = this;
        return [
            { id: "progress_bar", header: "Progress", width: '100',
                tooltip: '#progress_value#%',
                sort: 'int',
            },
            {
                id: 'year', editor: 'yearly',  header: 'Year', width: 70,
                template(obj){
                    if(obj.is_empty){ return '' }

                    let val = '';
                    if(!obj.year || obj.year == -1){
                        val = new Date();
                    }
                    else{
                        val = new Date(obj.year);
                    }
                    return format(val, 'yyyy');
                }
            },
            { id: "name_only", editor: "text", header: this.canShowHeaderFilters ? ["Milestone", {content: "textFilter"}] : 'Milestone',
                width: 450, height: 100, sort: el.tableSortText, css: { "text-align": "left", "word-wrap": "break-word" },
                tooltip:(obj)=>{
                    if(obj.is_empty){ return ''}
                    else{
                        return obj.name;
                    }
                }
            },
            { id: "start_date", editor: "date", visibility:['edit'], header: "Start Date", suggest: { type: "calendar" }, sort: "date", width: 150, css: { "text-align": "left", "word-wrap": "break-word" },
                format:function(value){
                    return el.parseDateFormat(value);
                }
            },
            
            { id: "end_date", editor: "date", visibility:['edit'], header: "End Date", sort: "date", width: 150, css: { "text-align": "left", "word-wrap": "break-word" },
                format:function(value){
                    return el.parseDateFormat(value);
                }
            },
            {
                id: 'date_range', header: 'Start - End', visibility:['view', 'delete'], width: 150, css: { "text-align": "left", "word-wrap": "break-word" },
                template(obj){
                    if(obj.is_new || obj.is_empty){
                        return '';
                    }
                    let start = el.parseDateFormat(obj.start_date);
                    let end = el.parseDateFormat(obj.end_date);
                    if(start === ''){ start = 'Na'}
                    if(end === ''){ end = 'Na'}
                    return `${start} - ${end}`;
                }
            },
            { id: "owner_user_id", editor: "combo", options: this.userList, header: "Owner", width: 150,
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
            },
            { id: "change", header: "Change", sort: "string", width: 150, css: { "text-align": "left", "word-wrap": "break-word" },
                template: (obj, common, value) => {
                    if(value === ''){
                        return '';
                    }
                    else if (value === "noshow"){
                        return "";
                    }
                    else{
                        return value + "%";
                    }
                },
            },
            {
                id: 'notes', header:"Notes", editor: 'text', width: 150
            }
        ]
    },
    button_methods(){
        let el = this;
        let onClick = {
            //eslint-disable-next-line
            'notes_button':function(e, id){
                let item = this.getItem(id)
                let note_data = {
                    item: item,
                    can_edit: false,
                    table: 'missionMilestones'
                }
                let is_editing_self = el.core.id ==  this.selected_user_id;
                let is_editing_as_manager = el.$isManagerCheck(el.core.id, this.selected_user_id)
                if(is_editing_self){
                    note_data.can_edit = true ;
                }
                else if(el.$hasPerm('milestones-manager') && (is_editing_as_manager || is_editing_self)){
                    note_data.can_edit = true ;
                }
                //el.notes_text = item.notes;
                el.$eventHub.$emit('showNotesModal', note_data);
            },
        }
        return onClick;
    },
    paste_rules(){
        return [
            { id: 'level_id', value: 'level_id', default: this.selected_level.id},
        ]
    },
    default_values(){
        let el = this;
        return [
            { id: 'name', value: ""},
            { id: 'owner_user_id', value: el.core.id },
            { id: 'year', value: startOfYear(new Date())},
            { id: 'start_date', value: startOfYear(new Date())},
            { id: 'end_date', value: endOfYear(new Date())},

        ]
    },
    isEditMode(){
        let classes = '';
        if(this.edit_mode){
            classes = 'border border-primary'
        }
        return classes;
    },
    titleName() {
      return "Mission Milestone";
    },
    validSmartGoals() {
      if (
        this.smart.specific &&
        this.smart.measurable &&
        this.smart.acheivable &&
        this.smart.realistic &&
        this.smart.time_based
      ) {
        return true;
      } else {
        return false;
      }
    },
    editDropdownClass() {
      return this.selected_milestone ? "" : "disabled";
    },
    selectedObjevtive() {
      return this.selected_milestone;
    },
    allObjectiveId() {
      return this.milestones.map((item) => item.id);
    },
    isEditing() {
      return this.isShow.isEditingExisting;
    },
    activeUserId(){
            if(this.selected_user_id != null || this.selected_user_id != undefined){
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
      
                return -1;
            }
        },
    milestones() {
        if(this.activeUserId == -1){
            return this.company_milestones;
        }
        else{
            let data = [];
            this.company_milestones.forEach( item => {
                //if(item.owner_user_id == this.activeUserId){
                data.push(item);
                //}
            })
        return data;
        }
    },
    hasMilestoneSelected(){
        if(this.selected_milestone != null){
            return true;
        }
        else{
            return false;
        }
    },


    formattedMilestoneData() {
        if (!this.milestones) {
            return [];
        }
        else {
            let milestones = this.milestones.map((item) => {
                return {
                    id: item.id,
                    progress_bar:`<div class="bar mt-1"><div class="bar-inner" style="width: ${item.progress}%"></div></div>`,
                    progress:  item.progress,
                    year: item.year,
                    quarter: item.quarter,
                    month: item.month,
                    monthNumber: this.monthToNumber(item.month),
                    name_only: item.name != null ? `${item.name}` : '',
                    name: item.name ? `${item.year} - ${item.name}` : `${item.year}`,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    owner_name: item.owner_name,
                    status: Number(item.status),
                    level_id: item.level_id,
                    owner_user_id: item.owner_user_id,
                    smart_validation: item.smart_validation,
                    change: item.change,
                    item_order: item.item_order,
                    notes : !item.notes ? '' : item.notes,
                };
            });
            milestones = _.orderBy(milestones, ['year']);
            console.log('milestones', milestones);
            return milestones;
        }
    },
    size() {
        // return this.ui.pager.size;
        return this.milestones.length;
    },
    isEditingNew() {
        return this.isShow.isEditingNew;
    },
    table_validation_rules(){
        return {
            // "name": function(item, obj){
            //     if(obj.is_empty){
            //         return true;
            //     }
            //     else{
            //         if(item === '' || item == undefined){
            //             return false;
            //         }
            //         else{
            //             return true;
            //         }
            //     }
            // },
            "start_date": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item == {} || item === '' || item == undefined){
                        return false;
                    }
                    else{
                        //if(obj.start_date && obj.end_date){
                        //    return isBefore(parseISO(obj.start_date), parseISO(obj.end_date)) || isEqual(parseISO(obj.start_date), parseISO(obj.end_date))
                        //}
                        return true;
                    }
                }
            },
            "end_date": function(item, obj){
                if(item == {} || obj.is_empty){
                    return true;
                }
                else{
                    if(item === '' || item == undefined){
                        return false;
                    }
                    else{
                        //if(obj.start_date && obj.end_date){
                        //    return isAfter(parseISO(obj.end_date), parseISO(obj.start_date)) || isEqual(parseISO(obj.start_date), parseISO(obj.end_date))
                        //}
                        return true;
                    }
                }
            },
            "owner_user_id": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item === '' || item == undefined){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            }
        };
    },
    },
	methods: {
		...activityMethods,
		...levelMethods,
        ...adminMethods,
        ...performanceMethods,
        //showLoginOverlay: adminMethods.showLoginOverlay,
        isSessionValid: authMethods.validate,
        setItemAsSelected(id){
            window.webix.$$(this.table_uid).select(id);
        },
        tableSortText(a, b, column){
            a = a[column];
            b = b[column];
            return a>b?1:(a<b?-1:0);
    },
        togglePrettyMode(){
            this.pretty_mode = !this.pretty_mode;
            if(this.pretty_mode == false){
                this.$nextTick(()=>{
                    window.webix.$$(this.table_uid).refresh();
                })
            }
        },
        setTableMode(mode){
            switch(mode){
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
		toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
            //* table events
            selectedRowEvent(row){
                if(!row){
                    this.$eventHub.$emit('selectedNewMilestone', null);
                    return;
                }
                if(this.show_overlay_modal){
                    return;
                }
                //this.isSessionValid().then( response => {
                //    if(response == true){
                        //* Event returns the selected row
                        //var index = _.findIndex(this.company_milestones, { id: row.id });
                        //if (index != -1) {
                            this.$eventHub.$emit('selectedNewMilestone', row.id);
                        //}
                    //}
                    //else{
                    //    this.showLoginOverlay();
                    //    this.$nextTick(()=>{
                    //        if(this.selected_objective){
                    //            //* restore the previous selected row in the table
                    //            window.webix.$$(this.table_uid).select(this.selected_milestone.id)
                    //        }
                    //    })
                    //}
                //})
                
            },
            onChangesMade(value){
                //* update flag when the user makes changes to the datatable
                this.changes_made_flag = value;
            },
            onDeleteArrayUpdate(array){
                //* update array of items that are selected for delete
                this.delete_array = array;
            },
            setSystemDefaultMilestone(){
                this.is_loading = true;
                this.saveDefaultMilestone(this.selected_milestone.id)
                .then(()=>{
                    this.$swal.fire({
                        icon:'success',
                        title:'Updated Default Milestone',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3500,
                        timerProgressBar: true,
                    })
                    this.loadCoreData()
                    .then(()=>{
                        this.is_loading = false;
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            },
            clearSelection(){
                window.webix.$$(this.table_uid).clearSelection();
                this.setSelectedMilestone(null);
                this.setPinnedMilestoneId(-1);
                window.localStorage.removeItem('milestone_id');
                this.$eventHub.$emit('selectedNewMilestone', null);
            },
            showDeletePrompt(){
                Swal.fire({
                    title: "Delete selected milestones?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.deleteRow()
                    }
                });
            },
            //*
            onRowIndexUpdate(sortData){
                this.is_loading = true;
                let params = {
                    type: 'milestone',
                    data: sortData,
                }
                console.log(params);
                this.updateItemOrder(params)
                .then(()=>{
                    this.reloadMilestone();
                    this.is_loading = false;
                })
            },
            deleteRow(data){
                let promises = [];
                data.forEach( item => {
                    promises.push(this.deleteMilestone({id: item.id}));
                })

                this.is_loading = true;
                Promise.all(promises)
                .then(()=>{
                    this.reloadMilestone()
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$refs[this.table_uid].showCardView();
                    })
                    .catch(()=>{
                        this.is_loading = false;
                    })
                })
                .catch( error =>{
                    console.log(error)
                    Swal.fire('One or more milestones has linked objectives');
                    this.is_loading = false;
                    this.$refs[this.table_uid].delete_array = [];
                })
            },
        
        insertEmptyRows(){
            let length = this.objective_data.length;
            let remainder = length % 10;
            let empty_rows = 10 - remainder;

            if(this.edit_mode){
                //* add an extra x count of rows foe editing
                empty_rows += 20;
            }

            for(let i = 0; i < empty_rows; i++){
                console.log('a')
                // eslint-disable-next-line
                $$("milestonestable").add({id: `emptyRow${window.webix.uid()}`, is_empty: true,});
            }
        },
        saveTable(data){
            this.saveDatatableChanges(data);
        },
        saveDatatableChanges(data){
                let rows_to_save = [];
                data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        var params = {
                            id: newRow ? -1 : row.id,
                            parent_id: row.parent === "none" ? null : row.parent,
                            level_id: row.level_id != undefined ? row.level_id : this.selected_level.id,
                            name: row.name_only,
                            year: Number(format(new Date(row.year ), 'yyyy')),
                            draft: 0,
                            month: row.month != undefined ? row.month : '-1',
                            quarter: row.quarter != undefined ? row.quarter : '-1',
                            start_date: this.getFormattedDate(row.start_date),
                            end_date: this.getFormattedDate(row.end_date),
                            owner_id: row.owner_user_id,//this.findLevelUserId(row.owner_name)?.id,
                            notes: !row.notes ? '' : row.notes
                        };
                        rows_to_save.push(params);
                    }
                })
                console.log(rows_to_save);

                let params = {
                level_id: this.selected_level.id,
                milestones: rows_to_save
                }

                this.is_loading = true;
                this.saveMilestone(params)
                .then(()=>{
                    this.reloadMilestone()
                    .then(()=>{
                        this.delete_array = [];
                        this.changes_made_flag = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$refs[this.table_uid].showCardView();
                        this.is_loading = false;
                        //setTimeout(() => {
                            
                            //window.webix.$$(this.table_uid).select(this.selected_milestone.id);
                        //}, 1000)
                    })
                })
                .catch((e)=>{
                    this.is_loading = false;
                    console.log('ERROR:', e);
                })
        },
        addComment() {
        this.$refs.comment.editNew();
        },
        setValidation(data) {
            if (data.smart_validation != null) {
                this.smart.specific = data.smart_validation.specific;
                this.smart.measurable = data.smart_validation.measurable;
                this.smart.acheivable = data.smart_validation.acheivable;
                this.smart.realistic = data.smart_validation.realistic;
                this.smart.time_based = data.smart_validation.time_based;
            } else {
                this.resetValidation();
            }
        },
        resetValidation() {
            Object.keys(this.smart).forEach((key) => {
                this.smart[key] = false;
            });
        },
        getGeneralDate(date) {
            return (
                new Date(date).getFullYear() +
                "-" +
                (new Date(date).getMonth() + 1) +
                "-" +
                new Date(date).getDate()
            );
        },
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
            if(obj.name != undefined){
                return (
                obj.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text) != -1
                );
            }
            });
        },
        searchDate(value) {
            //* set date filters
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
            let start = parseISO(this.filter.dateStart);
            let end = parseISO(this.filter.dateEnd);

            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.name != undefined){
                    let s_d = parseISO(obj.start_date);
                    let e_d = parseISO(obj.end_date);
                    return (
                    isAfter(s_d, start) ||
                    isBefore(e_d, end)
                    );
                }
            });
        },
        update(type) {
            //TODO - DELETE THIS + COMMENT COMPONENT
            this.isEditGuideline = false;
            this.clearView();
            this.$refs.isEditingNew = false;
            this.$refs.isEditingExisting = false;
            // this.$eventHub.$emit("newLevelSelected");
            this.reloadMilestone(null, type);
        },
        saveGuideline() {
            //! Remove
            this.$refs.guideline.saveGuideline();
        },
        monthToNumber(month) {
            var months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "Octorber",
                "November",
                "December",
            ];
            return _.findIndex(months, month);
        },
        getFormattedDate(day) {
            const today = new Date(day);
            return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
                -2
            )}-${("0" + today.getDate()).slice(-2)}`;
        },
        
        reloadMilestone() {
            return new Promise(resolve => {
                this.loadCompanyMilestones(this.company_id)
                .then(()=>{
                    resolve();
                })
                /*this.$eventHub.$emit("reloadObjective");
                setTimeout(()=>{
                resolve();
                }, 150)*/
            })
        },
        parentObjectName(parent_id) {
            let result = "";
            if (parent_id)
                result = _.find(this.objective_data, (item) => item.id === parent_id);

            return result?.name;
        },
        clearView() {
            this.isEditGuideline = false;
            this.isFormError = false;
            Object.keys(this.isShow).forEach((item) => {
                this.isShow[item] = false;
            });
            this.isShow.isView = true;
        },
        deleteItem() {
            let id = this.selected_milestone.id;
            this.deleteMilestone({id: id}).then(() => {
                this.reloadMilestone();
            })
            .catch( e => {
                alert('Error ' + ' ' + e.response.data.message);
            })
        },
        handleMilestoneEditEvent(){
            this.$refs[this.table_uid].setMode('edit');
        }
    },
    beforeDestroy(){
        this.$eventHub.$off('editMilestoneTable');
    },

    mounted() {
        this.$eventHub.$on('editMilestoneTable', this.handleMilestoneEditEvent)
        if(this.pinned_milestone_id != -1){
            this.selectedRowEvent({id: this.pinned_milestone_id})
        }
        /*if (this.data != null) {
            this.objective_data = _.cloneDeep(this.data);
            this.$nextTick(() => {
                setTimeout(() => {
                    this.show_table = true;
                }, 100);
            });
        }
        else {
            this.objective_data = [];
            this.show_table = true;
        }*/
    },
};
</script>

<style lang="scss" scoped>
.tooltipwrapper {
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 180px;
    transform: translate(0%, -100%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      left: 50px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

.disabled {
  &::v-deep {
    pointer-events: none !important;
    .text-info {
      color: grey !important;
      pointer-events: none !important;
    }
  }
}

::v-deep .webix_cell {
  cursor: pointer;
}

::v-deep .webix_hcell {
  word-break: break-word;
  // line-height: inherit !important;
  // height:
}

::v-deep .selected-item {
  font-weight: bold;
  text-decoration: underline;
}

::v-deep .bg-red {
  background: rgb(255, 92, 76) !important;
}

::v-deep .border-red {
  border-color: rgb(255, 92, 76) !important;
}

.card-body {
  .flip-widget-back {
    width: 100%;
    // margin: 20px auto;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}
</style>
