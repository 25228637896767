<template>
    <div>
        <b-card>
            <Table
                v-if="has_mounted"
                :columns="columns"
                :simple_columns="simple_columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="projectsData"
                :active_id="activeId"
                :is_loading="is_loading"
                :pager_config="10"
                :context_menu_items="['View', 'Edit', 'Delete']"
                only_editable_columns
                :ignore_edit_only_rules="['linkable_id', 'linked_key_actions', 'responsible_users']"
                :button_methods="button_methods"
                re_order
                enable_pretty_mode
                has_header_filters
                column_autoheight_key="linked_key_actions"
                :inner_card_component_name="'projectsTile'"
                :validation="table_validation_rules"
                :badge_class="'badge-item badge-item-project'"
                prevent_first_row_select
                :required_permissions="['action-plans','action-plans-manager']"
                @selectedRowEvent="selectedRowEvent"
                @updateViewMode="updateViewMode"
                @saveTable="saveTable"
                @updatedOrderEvent="onRowIndexUpdate"
                @deleteRows="deleteRow">
                <template #title>
                    Projects
                    <!-- &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span>
                        <span class="text-primary">{{returnSelectedObjectiveName}}</span>
                        &nbsp;&nbsp;>&nbsp;&nbsp;
                        <span class="text-success">{{returnCurrentUserName}}</span>
                    </span>
                    <span v-if="false" class="text-danger">
                        No Objective Selected
                    </span> -->
                </template>
                <template #buttons>
                    <b-button v-show="selected_project_id != -1" title="Reset Selection" class="m-0 me-1" size="sm" variant="primary" @click="selectProjectID(-1);">
                           Reset Selection
                    </b-button>
                </template>
            </Table>
        </b-card>
        <levelsTargetsObjectivesModal
            v-if="show_items_modal"
            :payload="modal_payload"
            :project_view="true"
            @selectedNewID="handleChangedEvent"
        />
        <linkedKeyActionsModal
            v-if="show_linked_key_actions_modal"
            :payload="modal_payload_kas"
            @updated="handleModalClose"
        />
        <addActionPlanModal
            v-if="show_action_plan_modal"
            :payload="modal_payload"
            @saved="keyActionAdded"
        />
        <projectsResponsibleUsersModal
            v-if="show_projects_responsible_users_modal"
            :payload="modal_payload"
            @updated="handleModalClose"
        />
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue'
import levelsTargetsObjectivesModal from '../misc/levelsTargetsObjectivesModal.vue'
import linkedKeyActionsModal from '../misc/linkedKeyActionsModal.vue'
import projectsResponsibleUsersModal from '../misc/projectsResponsibleUsersModal.vue'
import addActionPlanModal from '@/components/kanban/modals/addActionPlanModal.vue';
import _ from 'lodash'
import {
    activityRatingMethods,
    activityMethods, activityComputed,
    levelComputed, levelMethods,
    adminComputed, webixTableState,
    performanceComputed, performanceMethods,
    ganttComputed, ganttMethods, 
} from "@/state/helpers.js";
import { format, parseISO } from 'date-fns';
import dateMixin from '@/mixins/dateMixin.js'
export default {
    mixins: [ dateMixin ],
    components:{
        Table,
        levelsTargetsObjectivesModal,
        linkedKeyActionsModal,
        addActionPlanModal,
        projectsResponsibleUsersModal
    },
    data:() => ({
        //* table vars
        table_uid: 'projectsTable',
        table_view_mode: 'view',
        is_loading: false,
		delete_array: [],
        modal_payload_kas: false,
        changes_made_flag: false,
        //* -------------

        flip: true,
        isShow: {
            isCancel: false,
            isView: true,
            isEditingNew: false,
            isEditingExisting: false,
            isGuideline: false,
        },
        type_options:[
            { id: 'target', value: 'target'},
            { id: 'kpi', value: 'KPI'},
        ],
        //* booleans to show modals
        // show_activity_linking_modal: false,
        // show_objective_linking_modal: false,
        // show_level_linking_modal: false,
        show_items_modal: false,
        show_linked_key_actions_modal: false,
        show_action_plan_modal: false,
        modal_payload: null,
        has_mounted: false,
        activeId: -1,
        saveCount: 0,
        show_projects_responsible_users_modal: false,
        toggleViewNames: false
    }),
    watch:{
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
        selected_level() {
            let params = {
                item_id: this.selected_level.id,
                item_type: 'level',
                user_id: this.selected_user_id, 
            }
            this.getClientProjects(params).then(() => {
                if(this.projects.length != 0) {
                this.hideProjects(false)
                } else {
                    this.hideProjects(true)
                }
                this.has_mounted = true;
            })
        },
        selected_target() {
            this.saveCount = 0
            this.filterProjectsData()
        },
        selected_goal_objective() {
            this.saveCount = 0
            this.filterProjectsData()
        },
        selected_user_id() {
            this.saveCount = 0
            this.filterProjectsData()
        },
        selected_project_id() {
            this.activeId = this.selected_project_id
        },
        table_view_mode() {
            if(this.table_view_mode == 'edit') {
                this.selectProjectID(-1);
            }
        }
    },
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...webixTableState,
        ...performanceComputed,
        ...ganttComputed,
        canShowHeaderFilters(){
            if(this.table_states[this.table_uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        activity_inbox(){
            //TODO VERIFY ACTIVITY_INBOX
            let data = [];
            //if(this.selected_objective_details != null){
            //    data = this.selected_objective_details.plan_data.inbox;
            //}
            return data;
        },
        projectsData(){
            let data = this.projects
            if(data != null){
                console.log(JSON.parse(JSON.stringify(data)))
                let sortedData = _.sortBy(data, 'end_date', 'desc')
                return sortedData;
            } else {
                return []
            }

            // if(this.projects == undefined){
            //     return [];
            // }
            // else {
            //     console.log(this.projects)
            //     return [];
            // }
            // data = _.uniqBy(data, 'id');
            // data = _.orderBy(data, ['item_order']);
            
        },
        availableLinkingActivities(){
            let options = [{ id: 'null', value: 'Not Linked'}];
            if(this.selected_objective_details != null){
                this.selected_objective_details.plan_data.forEach( plan => {
                    options.push({
                        id: plan.id,
                        value: plan.name
                    })
                })
            }
            return options;
        },
        simple_columns(){
            let el = this;
            let columns = [
                    //* NB! type is used by webix so just remember activity_type == type
                    { id: "created_at", header: "Date",tooltip: '', sort: "date",
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    {
                        id: "name", header: this.canShowHeaderFilters ? ["Project Name", {content: "textFilter"}] : "Project Name", tooltip: false, minWidth: 220, fillspace: true, sort: "string", searchable: true, editor: 'text',
                        template: (obj, common, value) => {
                            if(value != ''){
                                return common.treetable(obj, common) + "<span>"+value+"</span>";
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    { id: "linkable_id", header: "Linked Card/Level", minWidth: 220,
                    template: function (obj) {
                                let objective_options = el.milestone_goals;                  
                                let objective_index = _.findIndex(objective_options, {id: Number(obj.linkable_id)});
                                let name = '' 
                                if(objective_index != -1){
                                    name = objective_options[objective_index].name;
                                } else {
                                    let target_options = el.level_targets;                  
                                    let target_index = _.findIndex(target_options, {id: Number(obj.linkable_id)});
                                
                                    if(target_index != -1){
                                        name = target_options[target_index].name;
                                    } else {
                                        let level_index = _.findIndex(el.levels, {id: Number(obj.linkable_id) });
                                        if(level_index != -1){
                                            name = el.levels[level_index].name;
                                        }
                                    }
                                }
                                if(name == '' && !obj.is_empty) {
                                name = "No Card Linked"
                                }
                                return name
                        }
                    },
                    {
                        id: "linked_type", header: "Type", tooltip: false,
                        template: (obj) => {
                            if(obj.linked_type) {
                                return obj.linked_type.charAt(0).toUpperCase() + obj.linked_type.slice(1)
                            } else {
                                return '' 
                            }
                        }
                    },
                    { id: "linked_ka_count", header: "Linked KAs", minWidth: 150, editor: true,
                        template: (obj) => {
                            if(obj != ''){
                                return "<span>"+obj.linked_ka_count+"</span>";
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    { id: "responsible_users", header: "Responsible Users", editor: true, minWidth: 150, 
                    tooltip: function(obj) {
                        return el.getResponsibleUsers(obj.responsible_users, true)
                    },
                    template: function (obj) {
                            console.log(obj)
                            let name = '' 
                            if (!obj.is_empty) {

                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to assign Responsible Users
                                        </span>`
                                } else {
                                    return `
                                        <span class="show_hierarchy_text">
                                            ${el.getResponsibleUsers(obj.responsible_users, false)}
                                        </span>`
                                }
                                
                            }
                            else {
                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to assign Responsible Users
                                        </span>`
                                } else {
                                    return `
                                        <span>
                                            ${name}
                                        </span>`
                                }
                                
                            }
                        }
                    },
                    { id: "owner_user_id", header: this.canShowHeaderFilters ? ["Owner", { content:"richSelectFilter" }] : "Owner",
                        options: this.userList,
                        minWidth: 150,
                        tooltip: '',
                        suggest:{
                            view:"suggest",
                            filter:function(item, value){
                                let text = value.toLowerCase();
                                let name = item.value.toLowerCase();
                                let email = item.email.toLowerCase();
                                if(name.includes(text) || email.includes(text)){
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            },
                            body:{
                                view:"list",
                                data:this.userList,
                                template:"#value# - #email#",
                                yCount:10
                            }
                        },
                    },
                    { id: "start_date", header: "Start Date",tooltip: '', sort: "date",
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    { id: "end_date", header: "End Date",tooltip: '', sort: "date",
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    { id: 'description', header: 'Description', minWidth: 250, editor: "text",},
                    
                ];
                return columns;
        },
        //* table props
        columns(){
            let el = this;
            return [
                //* NB! type is used by webix so just remember activity_type == type
                {
                  id: "name", header: "Project Name", editor: 'text', minWidth: 220,
                },
                { id: "linked_key_actions", header: "Linked Key Actions", fillspace: true, 
                    tooltip: function(obj) {
                        return el.getKANamesForIds(obj.objective_plans, true)
                    },
                    template: function (obj) {
                            console.log(obj)
                            let name = '' 
                            if (!obj.is_empty) {

                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to link to Key Actions
                                        </span>`
                                } else {
                                    return `
                                        <span class="show_hierarchy_text">
                                            <i class="me-1 show_linked_key_actions_modal fas fa-pen cursor-pointer"></i>
                                            ${el.getKANamesForIds(obj.objective_plans, false)}
                                        </span>`
                                }
                                
                            }
                            else {
                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to link to Key Actions
                                        </span>`
                                } else {
                                    return `
                                        <span>
                                            ${name}
                                        </span>`
                                }
                                
                            }
                        }
                    },
                { id: "linkable_id", header: "Linked to", minWidth: 220,
                template: function (obj) {
                        console.log(obj)
                        let objective_options = el.milestone_goals;                  
                        let objective_index = _.findIndex(objective_options, {id: Number(obj.linkable_id)});
                        let name = '' 
                        if(objective_index != -1){
                            name = objective_options[objective_index].name;
                        } else {
                            let target_options = el.level_targets;                  
                            let target_index = _.findIndex(target_options, {id: Number(obj.linkable_id)});
                        
                            if(target_index != -1){
                                name = target_options[target_index].name;
                            } else {
                                let level_index = _.findIndex(el.levels, {id: Number(obj.linkable_id) });
                                if(level_index != -1){
                                    name = el.levels[level_index].name;
                                }
                            }
                        }
                        if(name == '' && !obj.is_empty) {
                        name = "No Card Linked"
                        }

                        if (!obj.is_empty) {
                            return `
                                    <span class="">
                                        <i class="me-1 show_items_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: "responsible_users", header: "Responsible Users", minWidth: 250, 
                    tooltip: function(obj) {
                        return el.getResponsibleUsers(obj.responsible_users, true)
                    },
                    template: function (obj) {
                            console.log(obj)
                            let name = '' 
                            if (!obj.is_empty) {

                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to assign Responsible Users
                                        </span>`
                                } else {
                                    return `
                                        <span class="">
                                            <i class="me-1 show_responsible_users_modal fas fa-pen cursor-pointer"></i>
                                            ${el.getResponsibleUsers(obj.responsible_users, false)}
                                        </span>`
                                }
                                
                            }
                            else {
                                if(obj.is_new) {
                                    return `
                                        <span>
                                            Add New Project to assign Responsible Users
                                        </span>`
                                } else {
                                    return `
                                        <span>
                                            ${name}
                                        </span>`
                                }
                                
                            }
                        }
                    },
                { id: "owner_user_id",
                    header: this.canShowHeaderFilters ? ["Owner", { content:"richSelectFilter" }] : "Owner",
                    editor: "combo", options: this.userList,
                    minWidth: 150,
                    suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                    },
                },
                { id: "start_date", header: "Start Date", minWidth: 120, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "end_date", header: "End Date",minWidth: 120, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: 'description', header: 'Description', minWidth: 250, editor: "text",},
            ];
        },

        // default_values(){
        //     let el = this;
        //     return [    
        //         { id : "level_id", value: el.selected_level.id },
        //         //{ id : "level_milestone_id", value: null },
        //         //{ id : "parent_objective_id", value: null },
        //         { id: "milestone_objective_id", value: el.current_objective_details ? el.current_objective_details.id: -1}, //! old selected_objective_id
        //         { id : "status", value: '0'},
        //         /*{ id : "parent_id", value: null },*/
        //         { id : "start_date", value: format(new Date(), 'yyyy-MM-dd')},
        //         { id : "priority", value: 'low'},
        //         //{ id : "linked_key_result", value: null } // if kr is selected, use it as default link
        //         //{ id : "deadline", value: format(new Date(), 'yyyy-MM-dd')}
        //     ]
        // },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_items_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showlevelsTargetsObjectivesModal(item);                 
                },
                'show_linked_key_actions_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showKeyActionsModal(item);
                },
                'show_responsible_users_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showResponsibleUsersModal(item);
                },
            }
            return onClick;
        },
        table_validation_rules(){
            let el = this;
            return{
                "name":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "linkable_id":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "start_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == {} || item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.end_date){
                                return el.startDateCheck(obj.start_date, obj.end_date)
                            }
                            return true;
                        }
                    }
                },
                "end_date": function(item, obj){
                    if(item == {} || obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.end_date){
                                return el.endDateCheck(obj.end_date, obj.start_date)
                            }
                            return true;
                        }
                    }
                },
            }
        },

        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid;
        },
        dropdownFix(){
            if(this.changes_made_flag || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
    },
    methods:{
        ...activityRatingMethods,
        ...activityMethods,
        ...levelMethods,
        ...performanceMethods,
        ...ganttMethods,
        keyActionAdded() {
            this.refreshKanban(this.refresh_kanban = !this.refresh_kanban)
        },
        //* table methods
        handleModalClose() {
            this.saveCount = 0
            this.filterProjectsData()
            this.modal_payload = []
            this.$refs[this.table_uid].table_mode = 'view';
        },
        getKANamesForIds(id_array, tooltip) {
            console.log(id_array)
            let names = [];
            let names_string = '';
            if(id_array != undefined) {
                id_array.forEach(KA => {
                    names.push(KA.name);
                })
            }
            names = names.sort();
            names.forEach((name, idx) => {
                if (idx == 0) {
                    if(this.table_view_mode == 'view') {
                        names_string += `<span class="badge-item badge-item-key-action mr-1"></span>${name}`;
                    } else {
                        if((Number(names.length) - 1) != idx) {
                            names_string += `${name}, `;
                        } else {
                            names_string += `${name}`;
                        }
                    }
                }
                else {
                    if(this.table_view_mode == 'view') {
                        names_string += `<br> <span class="badge-item badge-item-key-action mr-1"></span>${name}`;
                    } else {
                        if((Number(names.length) - 1) != idx) {
                            names_string += `${name}, `;
                        } else {
                            names_string += `${name}`;
                        }
                        
                    }
                }
            });
            if(tooltip) {
                return names_string
            }
            if(names.length > 3) {
                return names_string = `<span class="badge-item badge-item-key-action mr-1"></span>(${names.length})`; 
            }
            if(names_string == '') {
                return 'No Key Actions Linked'
            } else {
                return names_string
            }
        },
        getResponsibleUsers(id_array, tooltip) {
            console.log(id_array)
            let names = [];
            let names_string = '';
            let short_list = '';
            if(id_array != undefined) {
                id_array.forEach(KA => {
                    names.push(KA.name);
                })
            }
            
            names = names.sort();
            
            names.forEach((name, idx) => {
                if((Number(names.length) - 1) != idx) {
                    names_string += `${name}, `;
                } else {
                    names_string += `${name}`;
                }   
                if (idx < 3) {
                    if(2 != idx) {
                        return short_list += `${name}, `;
                    } else {
                        return short_list += `${name}...`;
                    }
                }
                                
                
            });

            if(tooltip) {
                return names_string
            }

            if(names.length > 3) {
                return names_string = `${short_list}`; 
            }

            if(names_string == '') {
                return 'Not set'
            } else {
                return names_string
            }
        },
        filterProjectsData() {
            this.$nextTick(() => {
                if(this.selected_goal_objective != -1) {
                    let params = {
                        item_id: this.selected_goal_objective,
                        item_type: 'objective',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params).then(() => {
                    })
                } else if(this.selected_target != -1) {
                        let params = {
                            item_id: this.selected_target,
                            item_type: 'target',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                        })
                } else {
                        let params = {
                            item_id: this.selected_level.id,
                            item_type: 'level',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                        })
                }     
            })
        },
        handleChangedEvent(object){
            console.log(object)
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['linkable_id'] = Number(object.newId);
            item['linked_type'] = object.newType
            item['level_id'] = object.level_id
            console.log(this.modal_payload.id);
            console.log(item)
            table.updateItem(this.modal_payload.id, item);
            //this.$bvModal.hide('levelsTargetsObjectivesModal')
        },
        showlevelsTargetsObjectivesModal(data){
                this.modal_payload = _.cloneDeep(data);
                let objective_options = this.milestone_goals;                  
                let objective_index = _.findIndex(objective_options, {id: Number(data.linkable_id)}); 
                if(objective_index != -1){
                    let objective = objective_options[objective_index]
                    this.modal_payload['name'] = objective.name
                    this.modal_payload['level_id'] = objective.level_id
                    this.modal_payload['modal_type'] = 'objective'
                } else {
                    let target_options = this.level_targets;                  
                    let target_index = _.findIndex(target_options, {id: Number(data.linkable_id)});
                
                    if(target_index != -1){
                        let target = target_options[target_index];
                        console.log(target.name)
                        this.modal_payload['name'] = target.name
                        this.modal_payload['level_id'] = target.level_id
                        this.modal_payload['modal_type'] = 'target'
                    } else {
                        let level_index = _.findIndex(this.levels, {id: Number(data.linkable_id) });
                        if(level_index != -1){
                            let level = this.levels[level_index]
                            this.modal_payload['name'] = level.name
                            this.modal_payload['level_id'] = level.id
                            this.modal_payload['modal_type'] = 'level'
                        } else {
                            this.modal_payload['name'] = 'No Card/Level Linked'
                            this.modal_payload['level_id'] = this.selected_level.id
                            this.modal_payload['modal_type'] = 'level'
                        }
                    }
                }
                this.openModal() 
        },
        showKeyActionsModal(payload){
                this.modal_payload_kas = _.cloneDeep(payload);
                let level_index = _.findIndex(this.levels, {id: Number(payload.linkable_id) });
                if(level_index != -1){
                    let level = this.levels[level_index]
                    this.modal_payload_kas['name'] = payload.name
                    this.modal_payload_kas['level_id'] = level.id
                    this.modal_payload_kas['modal_type'] = 'level'
                } else {
                    this.modal_payload_kas['name'] = 'No Key Actions Linked'
                    this.modal_payload_kas['level_id'] = this.selected_level.id
                    this.modal_payload_kas['modal_type'] = 'level'
                }
                this.show_linked_key_actions_modal = true;
                this.openKAModal() 
        },
        openModal() {      
            this.show_items_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('levelsTargetsObjectivesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "levelsTargetsObjectivesModal") {
                        this.show_items_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
                   
        },
        openKAModal() {
            this.$nextTick(()=>{
                if(this.kanban_data.length == 0) {
                    Swal.fire({
                    title: "No Key Actions",
                    text: "Would you like to add Key Actions?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        this.addActionPlan()
                    }
                })
                } else {
                    this.$bvModal.show("linkedKeyActionsModal");
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedKeyActionsModal") {
                            this.show_linked_key_actions_modal = false;
                            this.modal_payload_kas = null;
                        }
                    });
                }
                
            })
        },
        addActionPlan() {         
            this.show_action_plan_modal = true;
            let index = _.findIndex(this.levels, {id: this.selected_level.id});
            let name = ''
            if( index != -1){
                name = this.levels[index].name;
            }
            this.modal_payload = { level_id: this.selected_level.id, level_name: name};
            if(this.$hasPerm('action-plans') || this.$hasPerm('action-plans-manager')){
            this.$nextTick(()=>{
                this.$bvModal.show('addActionPlanModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addActionPlanModal") {
                        this.show_action_plan_modal = false;
                        this.modal_payload = null;
                    }
                });
            })    
            } else {
                this.$swal.fire({
                    title: "Action Plan permission required to add Key Action",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }) 
            }
        },
        selectedRowEvent(row){
            if(!row){
                // set selected_team_id to -1
                this.selectProjectID(-1);
                return;
            }
            else{
                 this.selectProjectID(row.id);
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },

        saveTable(){
            return new Promise(( resolve, reject) => {
                // eslint-disable-next-line
                let table = $$(this.table_uid);
                let is_valid = table.validate();

                if(is_valid == false){
                    reject();
                    Swal.fire("Invalid rows found.", "", "warning")
                }
                if(is_valid){
                    const all_data = table.serialize(null, true);
                    //let count = 0
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        if(!row.is_empty){
                            if(row.is_new) {
                                this.saveCount++
                            }
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : row.id,
                                linked_item_type: row.linked_type,
                                linked_item_id: row.linkable_id,
                                name: row.name,
                                start_date: format(new Date(row.start_date), 'yyyy-MM-dd'),
                                end_date: format(new Date(row.end_date), 'yyyy-MM-dd') ,
                                owner_user_id: row.owner_user_id,
                                description: row.description
                            };
                            rows_to_save.push(params);
                        }
                    });

                    // {"projects":[{"id":"-1","linked_item_type":"level","linked_item_id":"54","name":"The Project","start_date":"2023-12-01","end_date":"2023-12-31","owner_user_id":"38"}]}
                    //let objective_id = this.selected_objective.id;
                    let projects_params = {
                        //level_id: this.selected_level.id,
                        //objective_id: objective_id,
                        projects: rows_to_save
                    }
                    this.is_loading = true;
                    this.saveClientProject(projects_params)
                    .then(()=>{
                        this.filterProjectsData()
                        this.$refs[this.table_uid].table_mode = 'view';
                        // this.$refs[this.table_uid].table_mode = 'edit';
                        this.is_loading = false
                    })
                }
            })
        },
        getQueryParams(){
            let params = {};
            if(this.selected_milestone){
                params = {
                    level_id: this.current_level_id,
                    graph_data: 1,
                    //user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                    milestone_id: this.selected_milestone.id
                }
            }
            else{
                params = {
                    level_id: this.current_level_id,
                    //user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                }
            }
            if(this.selected_user_id){
                params['user_id'] =  this.selected_user_id
            }
            return params;
            
        },
        loadPerfromanceData: _.debounce( function (){
            let params = this.getQueryParams();
            this.loadPerformanceScreenData({
                type: 'level',
                params: params,
            })
            .then(()=>{
                this.delete_array = [];
                this.changes_made_flag = false;
                this.$refs[this.table_uid].table_mode = 'view';
                this.is_loading = false;
                this.$swal.fire({
                    icon:'success',
                    title:'Success',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        }, 1),
        getFormattedDate(value){
            let newValue = ''
            if(typeof value === 'string'){
                newValue = format(parseISO(value), 'dd-MMM-yyy');
            }
            else{
                newValue = format(value, 'dd-MMM-yyy');
            }
            return newValue;
        },
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            let params = {
                type: 'activity',
                data: sortData,
            }
            this.updateItemOrder(params)
            .then(()=>{
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected Projects?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            console.log(JSON.parse(JSON.stringify(data)))
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteClientProject({project_id: item.id}));
            })
            this.is_loading = false;
            Promise.all(promises)
            .then(()=>{
                this.saveCount = 0
                this.filterProjectsData()
                this.delete_array = [];
                this.changes_made_flag = false;
                //console.log('Action Plan table ref',this.$refs[this.table_uid])
                this.$refs[this.table_uid].table_mode = 'view';
                this.is_loading = false;
			})
        },
        showResponsibleUsersModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            this.show_projects_responsible_users_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('projectsResponsibleUsersModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "projectsResponsibleUsersModal") {
                        this.show_projects_responsible_users_modal = false;
                        this.modal_payload = null
                    }
                });
            })
        },
    },
    mounted(){
        let params = {
            item_id: this.selected_level.id,
            item_type: 'level',
            user_id: this.selected_user_id, 
        }
        this.getClientProjects(params).then(() => {
            if(this.projects.length != 0) {
                this.hideProjects(false)
            } else {
                this.hideProjects(true)
            }
            this.has_mounted = true;
        })
    },
}
</script>

<style lang="scss" scoped>
::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}
</style>