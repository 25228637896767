<!-- //* Webix table to edit tracking values - displayed when a graph gets flipped to edit mode -->
<!-- //TODO move save and cancel button into actual card header -->
<template>
    <div class="h-100 d-flex" >
        <div class="flex-grow-1" @mouseleave="closeWebixEditor">
              <b-overlay :show="isSaving">
                <webix-ui id="datasetTable" v-if="showTable" :config="ui" v-model="tracking_summed" ></webix-ui>
              </b-overlay>
        </div>
        <div style="" >
          <div>
            <!--<div style="width: 100%;">
                <label for="">Progress: {{progress}}%</label>
                <b-progress :value="progress" :max="100" class="mb-4" animated></b-progress>
            </div>-->
            <b-button @click="toggleActionsCol" style="padding: 0rem 0rem;" variant="light" class="w-md">
                <i v-if="show_actions_col == false" class="fas fa-toggle-off me-1"></i>
                <i v-else class="fas fa-toggle-on me-1 text-success"></i>
                Actions
            </b-button>
            <div v-if="false">
                <b-overlay :show="isSaving" >
                    <b-button variant="primary" @click="applyTableChanges" class=" w-100 mb-1" >
                        Save
                    </b-button>
                </b-overlay> 
            </div>
            <div v-if="false">
                <b-button variant="outline-secondary" @click="$emit('back')" class="w-100" >Cancel</b-button>
            </div>
        </div>
        <span v-if="false" class="ms-1" style="width: 300px;">
            <b-card  class="bg-soft bg-primary">
                <date-picker v-model="range_date" size="sm" class="mb-2" placeholder="Date Range" range append-to-body lang="en"></date-picker>
                <date-picker v-model="jump_to_date" size="sm" placeholder="Jump to Date" append-to-body lang="en"></date-picker>
            </b-card>
        </span>
        </div>
    </div>
</template>

<script>
//eslint-disable-next-line
import { format, parseISO, isAfter, isBefore, closestTo } from "date-fns";
//eslint-disable-next-line
import {
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
} from "date-fns";
//eslint-disable-next-line
import {
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    endOfWeek,
} from "date-fns";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import { activityMethods, activityComputed, webixTableState, webixTable, performanceComputed, } from "@/state/helpers";
import isEqual from 'date-fns/isEqual/index';
import dateMixin from '@/mixins/dateMixin.js'
export default {
    components: {
        DatePicker
    },
    mixins: [dateMixin],
    props: {
        linking_type:{
            default(){
                return 'Level'
            }
        },
        range:{},
        item_id: {},
        time_period: {
            required: true,
        },
        dates: {},
        tracking_data: {},
        is_key_result: {},
        is_metric: {},
        table_name: {
            required: true,
        },
        target:{
          default(){
            return 0;
          }
        },
    },
    data: () => ({
        was_data_updated: false,
        range_date: null,
        jump_to_date: null,

        show_actions_col: false,

        isSaving: false,
        ui: {
          select: 'cell',
        //* webix config
        id: "",
        undo: true,
        view: "datatable",
        css: "datasetTable webix_data_border webix_header_border",
        editable: true,
        scrollX: false,
        scrollY: true,
        autoheight: true,
        fillspace: true,
        resizeColumn:true,
        columns: [
          { id: "date", header: "Date", width: '100',
              format:function(value){
                  let date = _.cloneDeep(value);
                  if(date !== ''){
                  if(typeof date === 'string'){
                      date = parseISO(date);
                  }
                      return format(date, 'dd-MMM-yyyy');
                  }
                  else{
                      return '';
                  }
              },
          },
          { id: "value", header: "Achieved", fillspace: 0.6, editor: "text" },
          { id: "total", header: "Total", fillspace: 0.6, css:'read-only-col'},
          { id: "actions", header: 'Actions', hidden: true, fillspace: 1, template:function(){
            return `
                  <span title="Duplicate Value Up" class=" d-up hoverDatasetIcon me-1"><i class="datasetIcon text-primary fas fa-angle-double-up"></i></span>
                  <span title="Duplicate Value Down "class="d-down hoverDatasetIcon me-1"><i class="datasetIcon text-primary fas fa-angle-double-down"></i></span>
                  <span title="Clear Values Up" class="c-up hoverDatasetIcon me-1"><i class="datasetIcon text-danger fas fa-angle-up"></i></span>
                  <span title="Clear Values Down" class="c-down hoverDatasetIcon me-1"><i class="datasetIcon text-danger fas fa-angle-down"></i></span>
                  <span title="Clear All Values" class="c-all hoverDatasetIcon"><i class="datasetIcon text-dark fas fa-times"></i></span>
                  `
          }}
        ],
        ready(){
            let el = this.$scope.$parent.$parent;
            let key = el.is_metric ? 'metric' : 'kpi'
            if(el.dataset_table_states[key] != undefined){
                this.setState(
                    {size: el.dataset_table_states[key]}
                )
            }
            window.webix.ui({
                view:'contextmenu',
                id: 'editGraphContext'+el.ui.id,
                on:{
                    onMenuItemClick:function(id){
                        var context = this.getContext();
                        let payload = {
                            column: context.id.column,
                            row: context.id.row,
                        }

                        switch(id){
                            case 'Duplicate Down':{
                                el.duplicateDown(payload);
                                break
                            }
                            case 'Duplicate Up':{
                                el.duplicateUp(payload);
                                break
                            }
                            case 'Clear All':{
                                el.clearAll(payload);
                                break
                            }
                            case 'Clear Down':{
                                el.clearDown(payload);
                                break
                            }
                            case 'Clear Up':{
                                el.clearUp(payload);
                                break
                            }
                        }
                    }
                },
                data: [
                    'Duplicate Down',
                    'Duplicate Up',
                    'Clear All',
                    'Clear Down',
                    'Clear Up',
                ]
            }).attachTo(this)
        },
        on: {
            //eslint-disable-next-line
            onColumnResize:_.debounce(function(id, newWidth, oldWidth, user_action){
                if(user_action){
                    let el = this.$scope.$parent.$parent;
                    let column_state = this.getState();
                    el.saveDatasetTableState({
                        uid: el.is_metric ? 'metric' : 'kpi',
                        col_state: column_state.size,
                    });
                }
            }, 100),
            onAfterEditStop(state, editor){
                if(state.value != state.old){
                    let el = this.$scope.$parent.$parent;
                    let payload = {
                        column: editor.column,
                        row: editor.row,
                    }
                    el.saveChange(payload)
                }
            },
            //eslint-disable-next-line
            onKeyPress:function(code, e){
                const UP = 38;
                const DOWN = 40;
                const ENTER = 13;
                //* we need to check if the editor is open - if open then close the editor and select prev/next row
                //* if the value from getEditor is 0 then there is no editor open
                if(code == UP || code == DOWN){
                  const last_editor = this.getEditor();
                  if(last_editor){
                    this.editStop();
                    switch(code){
                      case UP:{
                        this.moveSelection('up');
                        let new_selection = this.getSelectedItem();
                        this.editCell(new_selection.id, last_editor.column);
                        break;
                      }
                      case DOWN:{
                        this.moveSelection('down');
                        let new_selection = this.getSelectedItem();
                        this.editCell(new_selection.id, last_editor.column);
                        break;
                      }
                    }
                  }
                }
                if(code == ENTER){
                  const last_editor = this.getEditor();
                  if(!last_editor){
                    let item = this.getSelectedId();
                    setTimeout(()=>{
                      this.select(item.row, item.column);
                    }, 50);
                    setTimeout(()=>{
                      this.editCell(item.row, item.column);
                    }, 100)
                  }
                }
            },
      },
      onClick: {
        //eslint-disable-next-line
        'd-up':function(e, obj){
            let el = this.$scope.$parent;
            let payload = {
                column: 'value',
                row: obj.row,
            }
            window.webix.$$(el.ui.id).editStop();
            el.$nextTick(()=>{
              el.duplicateUp(payload);
            })
            
        },//eslint-disable-next-line
        'd-down':function(e, obj){
            let el = this.$scope.$parent;
            let payload = {
                column: 'value',
                row: obj.row,
            }
            window.webix.$$(el.ui.id).editStop();
            el.$nextTick(()=>{
              el.duplicateDown(payload);
            })
            
        },//eslint-disable-next-line
        'c-up':function(e, obj){
            let el = this.$scope.$parent;
            let payload = {
                column: 'value',
                row: obj.row,
            }
            window.webix.$$(el.ui.id).editStop();
            el.$nextTick(()=>{
              el.clearUp(payload);
            })
            
        },//eslint-disable-next-line
        'c-down':function(e, obj){
            let el = this.$scope.$parent;
            let payload = {
                column: 'value',
                row: obj.row,
            }
            window.webix.$$(el.ui.id).editStop();
            el.$nextTick(()=>{
              el.clearDown(payload);
            })
        },//eslint-disable-next-line
        'c-all':function(e, obj){
            let el = this.$scope.$parent;
            let payload = {
                column: 'value',
                row: obj.row,
            }
            window.webix.$$(el.ui.id).editStop();
            el.$nextTick(()=>{
              el.clearAll(payload);
            })
            
        }
      },
    },
    uid: 0,
    showTable: false,
    frequency: "monthly",
    tracking: [], //      {date, value}
  }),
  computed: {
    ...activityComputed,
    ...webixTableState,
    ...performanceComputed,
    computedViewableRangeDate(){
        return this.viewableRangeDate;
    },
    progress(){
      let prog = 0;
      if(this.tracking_summed.length > 0){
        let total = this.tracking_summed[this.tracking_summed.length-1].total;
        
        if(total > 0){
          prog = (total/this.target) * 100;
          prog = Math.round((prog + Number.EPSILON) * 100) / 100;
          /*if(prog >= 100){
            prog = 100;
          }*/
        }
      }
      return prog;
    },
    tracking_summed(){
      this.tracking.forEach((item, idx)=>{
        item.total = 0;
        if(idx == 0){
          item.total = item.value;
        }
        else{
          item.total = Number(item.value) + Number(this.tracking[idx-1].total);
        }
      })
      return this.tracking;
    },
  },
  watch:{
    computedViewableRangeDate(new_val, old_val){
        let is_new_date_range = _.isEqual(new_val, old_val);
        if(is_new_date_range == false){
            this.setKpis();
        }
    },
    isSaving(){
      this.$emit('savingState', this.isSaving);
    }
  },
  methods: {
    ...webixTable,
    ...activityMethods,
    toggleActionsCol(){
      this.show_actions_col = !this.show_actions_col;
      let table = window.webix.$$(this.ui.id);
      if(this.show_actions_col){
        table.showColumn('actions');
      }
      else{
        table.hideColumn('actions');
      }
    },
    // eslint-disable-next-line
    closeWebixEditor(){
      window.webix.$$(this.ui.id).editStop();
    },
    duplicateDown(payload){
        let id = payload.row;
        let col = payload.column;
        let item = window.webix.$$(this.ui.id).getItem(id);
        let dateToCompare = parseISO(item.date);
        this.tracking.forEach( graph_item => {
            let date = parseISO(graph_item.date);
            if(isAfter(date, dateToCompare)){
                graph_item[col] = item[col];
            }
        })
        this.$nextTick(()=>{
            window.webix.$$(this.ui.id).define('data', this.tracking);
            window.webix.$$(this.ui.id).refresh();
        })
    },
    duplicateUp(payload){
        let id = payload.row;
        let col = payload.column;
        let item = window.webix.$$(this.ui.id).getItem(id);
        let dateToCompare = parseISO(item.date);
        this.tracking.forEach( graph_item => {
            let date = parseISO(graph_item.date);
            if(isBefore(date, dateToCompare)){
                graph_item[col] = item[col];
            }
        })
        this.$nextTick(()=>{
            window.webix.$$(this.ui.id).define('data', this.tracking);
            window.webix.$$(this.ui.id).refresh();
        })
    },
    clearDown(payload){
        let id = payload.row;
        let col = payload.column;
        let item = window.webix.$$(this.ui.id).getItem(id);
        let dateToCompare = parseISO(item.date);
        this.tracking.forEach( graph_item => {
            let date = parseISO(graph_item.date);
            if(isAfter(date, dateToCompare) || isEqual(date, dateToCompare)){
                graph_item[col] = 0;
            }
        })
        this.$nextTick(()=>{
            window.webix.$$(this.ui.id).define('data', this.tracking);
            window.webix.$$(this.ui.id).refresh();
        })
    },
    clearUp(payload){
        let id = payload.row;
        let col = payload.column;
        let item = window.webix.$$(this.ui.id).getItem(id);
        let dateToCompare = parseISO(item.date);
        this.tracking.forEach( graph_item => {
            let date = parseISO(graph_item.date);
            if(isBefore(date, dateToCompare) || isEqual(date, dateToCompare)){
                graph_item[col] = 0;
            }
        })
        this.$nextTick(()=>{
            window.webix.$$(this.ui.id).define('data', this.tracking);
            window.webix.$$(this.ui.id).refresh();
        })
    },
    clearAll(payload){
        let col = payload.column;
        this.tracking.forEach( graph_item => {
            graph_item[col] = 0;
        })
        this.$nextTick(()=>{
            window.webix.$$(this.ui.id).define('data', this.tracking);
            window.webix.$$(this.ui.id).refresh();
        })

    },

    //eslint-disable-next-line
    isValidRange(value){
      let isValid = true;
      /*if(this.range.min === 0 && this.range.max === 0){
        //* no min/max was supplied
        isValid = true;
      }
      else{
        if(value >= this.range.min && value <= this.range.max){
          isValid = true;
        }
      }*/
      return isValid
    },
        setKpis() {
          this.uid = window.webix.uid();
          this.ui.id += "kpiDates" + this.uid;
          //* Return uid to parent component, used to redraw the webix table if the graph component changes size
          this.$emit("returnWebixUID", this.ui.id);
                let start_date = this.computedViewableRangeDate.range_start;
                let end_date = this.computedViewableRangeDate.range_end;
            let start = null;
            let end = null;
            let dates = [];


      if (this.frequency == "daily") {
        //* Get every DAY for MONTH of the supplied DATE
        start = new Date(this.dates.start);
        end = new Date(this.dates.end);
        dates = eachDayOfInterval({ start, end });
      } else if (this.frequency == "weekly") {
        //TODO -> Fix, not working
        //* Get every DAY for MONTH of the supplied DATE
        start = startOfMonth(start_date);
        end = endOfMonth(end_date);
        dates = eachWeekOfInterval({ start, end });
        dates.forEach((week_date, idx)=>{
          let new_d = week_date;
          new_d = endOfWeek(new_d);
          dates[idx] = new_d;
        })
      } else if (this.frequency == "monthly") {
        //TODO -> Fix, not working
        start = startOfMonth(start_date);
        end = endOfMonth(end_date);
        dates = eachMonthOfInterval({ start, end });
        dates.forEach((single_date, idx)=>{
          let new_d = single_date;
          new_d = endOfMonth(new_d);
          dates[idx] = new_d;
        })
        /*dates.forEach( (current_item) => {
          current_item = endOfMonth(current_item);
        })*/
      } else if (this.frequency == "quarterly") {
        //TODO -> Fix, not working
        alert("Invalid");
      } else if (this.frequency == "yearly") {
        //TODO -> Fix, not working
        start = startOfYear(start_date);
        end = endOfYear(end_date);
        dates.push(start);
        dates.push(end);
      }

      //* Add empty rows to edit data
      dates.forEach((date) => {
        // let current_date = format(date, "dd-MM-yyyy");
        let current_date = this.$getGeneralDate(date);
        let index = _.findIndex(this.tracking_data, (item) => {
          //   let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
          let item_date = this.$getGeneralDate(item.achieved_date);
          return item_date == current_date;
        });
        if (index != -1) {
          //* push the existing value
          this.tracking.push({
            date: this.$getGeneralDate(current_date),
            value: this.tracking_data[index].value,
            target_value: this.tracking_data[index].target_value,
            id: this.tracking_data[index].id,
            total: 0,
          });
        } else {
          //* push an empty value
          this.tracking.push({
            date: this.$getGeneralDate(current_date),
            value: 0,
            target_value: null,
            isEmpty: true,
            total: 0,
          });
        }
      });
      if(this.tracking[this.tracking.length - 1].target_value == 0 || this.tracking[this.tracking.length - 1].target_value == null){
        this.tracking[this.tracking.length - 1].target_value = this.target;
      }

      this.showTable = false;
      /*if(this.is_key_result){
        this.ui.columns[1]['hidden'] = false;
      }
      else{
        this.ui.columns[1]['hidden'] = true;
      }*/
      setTimeout(() => {
        //* wait for the flip animation to finish - webix needs to be rendered after
        this.showTable = true;
        setTimeout(()=>{
          this.selectCurrentDate();
        },200)
      }, 200);
    },

    selectCurrentDate(){
        this.$nextTick(()=>{
          if(this.tracking_summed.length > 0){
              window.webix.$$(this.ui.id).filter();
              this.range_date = null;
              let date = format(new Date(),'yyyy-MM-dd')
              let result = window.webix.$$(this.ui.id).find(function(obj){
                return obj.date === date;
              })
              if(result.length > 0){
                window.webix.$$(this.ui.id).select(result[0].id, 'value');
                //window.webix.$$(this.ui.id).editCell(result[0].id, 'value');
              }
              else{
                let date = new Date();
                //let date = format(new Date(),'yyyy-MM-dd')
                //let last_index = this.tracking_summed.length - 1;
                let dates = this.tracking_summed.map((item)=>{
                  return new Date(item.date);
                })
                let closest_date = closestTo(date, dates);
                let result_2 = window.webix.$$(this.ui.id).find(function(obj){
                  return obj.date === format(closest_date, 'yyyy-MM-dd');
                })
                if(result_2.length > 0){
                  this.$nextTick(()=>{
                    window.webix.$$(this.ui.id).select(result_2[0].id, 'value');
                    //window.webix.$$(this.ui.id).editCell(result_2[0].id, 'value');
                  })
                }
                //window.webix.$$(this.ui.id).select(this.tracking_summed[last_index].id);
                //window.webix.$$(this.ui.id).editCell(this.tracking_summed[last_index].id, 'value');
              }
          }
        })
    },

    saveValue(params) {
        //if(this.isValidRange(params.value)){
            if (this.is_key_result) {
              console.log('B4 save', params)
                //*Path for key result
                params["key_result_id"] = this.item_id;
                this.saveKeyResultTracking(params)
                .then(()=>{
                  this.$emit("updated");
                  this.$nextTick(()=>{
                    this.$emit('savedChanges');
                  })
                })
            } else if (this.is_metric) {
                //* Save path for kpi
                params["metric_id"] = this.item_id;
                this.saveMetricTracking(params)
                .then(()=>{
                  this.$emit("updated");
                  this.$nextTick(()=>{
                    this.$emit('savedChanges');
                  })
                })
            } else {
                //* Save path for kpi
                params["kpi_id"] = this.item_id;
                this.saveKpiTracking(params)
                .then(()=>{
                  this.$emit("updated");
                  this.$nextTick(()=>{
                    this.$emit('savedChanges');
                  })
                })
                
            }
    },
    saveChange(payload){// contains column, row(id)
        let updated_item = window.webix.$$(this.ui.id).getItem(payload.row);
        let params = {
            tracking_data: [{
                achieved_date: this.getBackendDateFormat(updated_item.date),
                value: Number(updated_item.value),
                //* save target val as 0 if it is null
                target: 1
            }],
        }
        if(this.is_metric){
          params["metric_id"] = this.item_id;
          this.saveMetricTracking(params).then(()=>{
            this.was_data_updated = true;
            this.$emit("updated");
          })
        }
        else{
          params["kpi_id"] = this.item_id;
          this.saveKpiTracking(params).then(()=>{
            this.was_data_updated = true;
            this.$emit("updated");
          })
        }
    },
    applyTableChanges(){
      this.isSaving = true;
      let table = window.webix.$$(this.ui.id);
      let params = {
        tracking_data: [],
      };
      table.eachRow( row => {
        const record = table.getItem(row);
        if(record.isEmpty == undefined){
          //* if undefined then this is a normal row
          params.tracking_data.push({
            achieved_date: record.date,
            target: 1,
            value: Number(record.value),
          })
        }
        else if(record.isEmpty == true){
          //if(record.target_value != 0 || record.value != 0){
          //if(record.target_value != null){
            //* check if target or value was updated if newRow is true
            params.tracking_data.push({
              achieved_date: record.date,
              target: 1,
              value: Number(record.value),
            });
          //}
        }
      });
      if(params.tracking_data.length > 0){
        this.saveValue(params)
      }
      else{
        alert('Nothing to save')
      }

    },
    handleFiredSaveEvent(id){
      if(this.ui.id == id){
        this.applyTableChanges();
      }
    }
  },
  created(){
    this.frequency = this.time_period;
  },
  mounted() {
    this.$eventHub.$on('saveGraphDatatableKPI', this.handleFiredSaveEvent)
    window.kr_el = this;
    this.frequency = this.time_period;
    //* Give the webix table a unique id
    
    this.setKpis();
  },
  beforeDestroy(){
    if(this.was_data_updated){
        this.$emit("savedChanges");
    }
    this.$eventHub.$off('saveGraphDatatableKPI')
    window.kr_el = null;
  }

};
</script>

<style>
.read-only-col{
  background: #e4e4e4;
}
.hoverDatasetIcon{
  cursor: pointer;
  text-align: center;
}
.hoverDatasetIcon:hover{
  background-color: rgb(192, 192, 192) !important;
}

.datasetIcon{
  width: 15px;
}

.datasetTable .webix_cell_select{
  box-shadow: inset 0px 0px 0px 1px #000000;
}
</style>
