<template>
    <b-row>
        <transition name="fade">
        <b-col :cols="getSize" v-if="hasMilestoneSelected" >
            <b-card no-body class="newTile m-2" >
        <template #header class="cursor-pointer pt-1">
            <div class="d-flex">
                    <span style="padding-top: 0.06rem;"></span>
                    <strong>Selected Milestone</strong>
                </div>
            <div class="d-flex">
                
                <strong class="sub-heading-text cursor-pointer pt-1" >
                    <span  style="display: block;" class="title-text-header">{{ selected_milestone.name}}</span>
                </strong>
                <div v-if="selected_milestone.owner_user_id != undefined" class="d-flex ms-auto ps-1">
                    <img src="/img/user.png" alt="" class="cursor-pointer avatar-xxs rounded-circle " v-b-tooltip.hover.left :title="ownerName(getSelectedMilestone.owner_user_id)">
                </div>
            </div>
        </template>

        
        <div class="d-flex px-3 small-text">
            <span>Year:</span>
            <span class="ms-auto">
                <span>{{ selected_milestone.year }}</span>
            </span>
            <!-- <span>Duration:</span>
            <span class="ms-auto">
                <span>{{ selected_milestone.start }}</span>
                <span class="text-secondary"> to </span>
                <span>{{ selected_milestone.end }}</span>
            </span> -->
        </div>

        <!-- progress bar only visible if progress property exists -->

        <div >
        <div class="d-flex h-100" style="align-self: flex-end;"></div>
        <div class="px-3 d-flex" >
            <span class="small-text">Progress:</span>
            <span class="ms-auto">{{ getClampedProgress(this.getSelectedMilestone.progress) }}%</span>
        </div>
        
        <div class="d-flex px-3 pb-1">
            
            <div class="tc-progress">
                <!-- <div class="tc-progress-bar" role="progressbar" :style="`width: ${{getClampedProgress}}%;`" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div> -->
                <b-progress height="10px" class="flex-grow-1 modify-progress-bar" :value="getClampedProgress(this.getSelectedMilestone.progress) " max=100></b-progress>
            </div>
        </div>
        </div> 

        <template #footer v-if="false">
            <div class="d-flex" >
                <span v-if="hasNote">
                    <font-awesome-icon v-b-popover.hover="getPopoverContent" v-if="getNote" @click="showNote($event)" class=" icon-margin-fix icon-pointer" icon="fa-comment-dots"></font-awesome-icon>
                    <font-awesome-icon  v-else @click="showNote($event)" class="text-secondary icon-margin-fix icon-pointer" icon="fa-comment"></font-awesome-icon>
                </span>
                <span v-if="hasNote" class="ps-1 font-size-10">
                    <span v-if="getNote == ''">No comment</span>
                </span>
                <b-button v-if="has_nested_data" :disabled="childrenLength == 0" :variant="isCurrentItemInExpandedList ? 'primary' :'light'" size="sm" @click="toggleChildren($event)" class="w-sm pt-0 pb-0 ms-auto" >
                        <span v-show="childrenLength > 0" > Children ({{ childrenLength }})</span>
                        <span v-show="childrenLength == 0" > Children (0)</span>

                        <font-awesome-icon v-show="!isCurrentItemInExpandedList && childrenLength > 0"  class="fa-fw" icon="fa-caret-down"></font-awesome-icon>
                        <font-awesome-icon v-show="isCurrentItemInExpandedList && childrenLength > 0" class="fa-fw" icon="fa-caret-up"></font-awesome-icon>
                </b-button>
            </div>
        </template>
    </b-card>
        </b-col>
        </transition>

        <transition name="fade">
            <!-- hasObjectiveSelected -->
            <b-col :cols="getSize" v-if="hasObjectiveGoalSelected">
            <b-card no-body class="newTile m-2" >
        <template #header class="cursor-pointer pt-1">
            <div class="d-flex">
                    <span style="padding-top: 0.06rem;"></span>
                    <strong>Selected Milestone Objective</strong>
                </div>
            <div class="d-flex">
                
                <strong class="sub-heading-text cursor-pointer pt-1" >
                    <span  style="display: block;" class="title-text-header">{{ selectedMilestoneGoal.name}}</span>
                </strong>
                <div v-if="selectedMilestoneGoal.owner_user_id != undefined" class="d-flex ms-auto ps-1">
                    <img src="/img/user.png" alt="" class="cursor-pointer avatar-xxs rounded-circle " v-b-tooltip.hover.left :title="ownerName(selectedMilestoneGoal.owner_user_id)">
                </div>
            </div>
        </template>

        
        <div class="d-flex px-3 small-text">
            <span>Duration:</span>
            <!-- <span class="ms-auto">
                <span>{{ selectedMilestoneGoal.start }}</span>
                <span class="text-secondary"> to </span>
                <span>{{ selectedMilestoneGoal.end }}</span>
            </span> -->
        </div>
    </b-card>
            <!-- <div class="card border border-info m-0 mini-stats-wid">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-muted fw-medium mb-2">Objective</p>
                            <h5 class="mb-0">{{ getObjectiveName }}</h5>
                        </div>
                        <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                            <span class="avatar-title">
                                <i class="bx bx-layer font-size-24"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->
        </b-col>
            
        </transition>

        <transition name="fade">
            <!-- hasObjectiveSelected -->
            <b-col :cols="getSize" v-if="hasObjectiveGoalSelected == false && hasObjectiveSelected">
            <b-card no-body class="newTile m-2" >
        <template #header class="cursor-pointer pt-1">
            <div class="d-flex">
                    <span style="padding-top: 0.06rem;"></span>
                    <strong>Selected Milestone Objective</strong>
                </div>
            <div class="d-flex">
                
                <strong class="sub-heading-text cursor-pointer pt-1" >
                    <span  style="display: block;" class="title-text-header">{{ selectedMilestoneGoal.name}}</span>
                </strong>
                <div v-if="selectedMilestoneGoal.owner_user_id != undefined" class="d-flex ms-auto ps-1">
                    <img src="/img/user.png" alt="" class="cursor-pointer avatar-xxs rounded-circle " v-b-tooltip.hover.left :title="ownerName(selectedMilestoneGoal.owner_user_id)">
                </div>
            </div>
        </template>

        
        <div class="d-flex px-3 small-text">
            <span>Duration:</span>
            <!-- <span class="ms-auto">
                <span>{{ selectedMilestoneGoal.start }}</span>
                <span class="text-secondary"> to </span>
                <span>{{ selectedMilestoneGoal.end }}</span>
            </span> -->
        </div>
    </b-card>
            <!-- <div class="card border border-info m-0 mini-stats-wid">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-muted fw-medium mb-2">Objective</p>
                            <h5 class="mb-0">{{ getObjectiveName }}</h5>
                        </div>
                        <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                            <span class="avatar-title">
                                <i class="bx bx-layer font-size-24"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->
        </b-col>
        </transition>

        <transition name="fade">
        <b-col :cols="getSize" v-if="hasKeyResultSelected">
            <div class="card border border-warning m-0 mini-stats-wid">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-muted fw-medium mb-2">Key Result</p>
                            <h5 class="mb-0">{{ getKeyResultName }}</h5>
                        </div>
                        <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                            <span class="avatar-title">
                                <i class="bx bx-key font-size-24"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
        </transition>
    </b-row>
</template>

<script>
import { performanceComputed, levelComputed, activityComputed } from '@/state/helpers';
export default {
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...performanceComputed,
        ...activityComputed,
        getSize(){
            if(this.is_viewing_company_level){
                if(this.hasKeyResultSelected){
                    return '3'
                }
                else if(this.hasSubObjectiveSelected){
                    return '4'
                }
                else if(this.hasObjectiveGoalSelected){
                    return '6'
                }
                else{
                    return '12'
                }
            }
            else{
                if(this.hasKeyResultSelected){
                    return '4'
                }
                else if(this.hasObjectiveSelected){
                    return '6'
                }
                else{
                    return '12'
                }
            }
            
        },
        is_viewing_company_level(){
            return this.company_id == this.current_level_id;
        },  
        hasMilestoneSelected(){
            return this.selected_milestone != null;
        },
        hasObjectiveGoalSelected(){
            return this.selectedMilestoneGoal != null;
        },
        hasSubObjectiveSelected(){
            return this.selectedGoalObjective != null;
        },
        hasObjectiveSelected(){
            let has_valid_item = false;
            if(this.is_viewing_company_level){
                has_valid_item = this.selectedGoalObjective != null || this.selectedMilestoneGoal != null;
            }
            else{
                has_valid_item = this.selectedLevelObjective != null;
            }
            return has_valid_item && this.hasMilestoneSelected;
        },
        hasKeyResultSelected(){
            return this.hasObjectiveSelected && this.selected_kr_id != -1;
        },
        getMilestoneName(){
            if(this.selected_milestone && this.selected_milestone.name){
                return `${this.selected_milestone.year} - ${this.selected_milestone.name}`;
            }
            return 'N/A';
        },
        getSelectedMilestone(){
            let m_stone = this.company_milestones.find( item => {
                return item.id == this.selected_milestone.id
            })
            return m_stone;
        },
        getObjectiveName(){
            if(this.is_viewing_company_level){
                if(this.selectedGoalObjective){
                    return this.selectedGoalObjective.name;
                }
                else if(this.selectedMilestoneGoal){
                    return this.selectedMilestoneGoal.name;
                }
            }
            else{
                
                if(this.selectedLevelObjective){
                    return this.selectedLevelObjective.name;
                }
            }
            return 'N/A'
        },
        getKeyResultName(){
            if(this.current_objective_details){
                let found_item = this.current_objective_details.key_results.find( item => {
                    return item.id == this.selected_kr_id;
                })
                if(found_item){
                    return found_item.name;
                }
            }
            return 'N/A'
        }
    },
    methods:{
        ownerName(owner_user_id){
            let user = this.all_users.find( item => { return item.id == owner_user_id});
            if(user){
                console.log('user', user);
                return user.name
            }
            else{
                return '';
            }
        },
        getClampedProgress(progress){
            if(progress > 100){
                return 100;
            }
            return Number(progress);
        },
    },
    mounted(){},
}
</script>

<style>

</style>