<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-02T10:02:23+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-05T13:59:58+02:00
@Copyright: Diversitude 2021
-->



<template>
  <div class="wrapper">
    <!-- Add comment -->
    <div class="d-flex mb-4" v-if="isEditingNew || isEditingExisting">
      <b-form-input
        ref="commentInput"
        v-model="text"
        placeholder="Enter your comment"
        autofocus
        class="me-4"
      ></b-form-input>
      <b-button
        v-if="isEditingNew"
        variant="outline-primary"
        class="btn"
        @click="addComment"
      >
        Add
      </b-button>
      <b-button
        v-if="isEditingExisting"
        variant="outline-primary"
        class="btn"
        @click="sendEditedComment"
        >Edit</b-button
      >
    </div>
    <!-- Show comments -->
    <b-alert v-show="comment && comment.length == 0" show>No Comments Added</b-alert>
    <div
      class="d-flex align-items-start comment-row"
      v-for="(item, i) in displayComment"
      :key="i"
    >
      <!-- User icon -->
      <!-- <div class="d-flex align-items-center">
        <span
          class="icon me-2 d-flex align-items-center justify-content-center"
        >
        </span>
      </div> -->
      <div class="item w-100">
        <div class="d-flex justify-content-between">
          <h5>{{ item["user_name"] }}</h5>
          <div>
            <span class="d-inline-block" :style="dropdownstyle(item)">{{
              new Date(item["created_at"]).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })
            }}</span>

            <b-dropdown
              v-if="core.id === item.user_id && !item.deleted"
              text="Button text via Prop"
              class="ms-auto"
              variant="white"
              toggle-tag="a"
              menu-class="dropdown-menu-end"
              toggle-class="font-size-16 p-0"
              right
            >
              <template #button-content>
                <i class="mdi mdi-dots-horizontal"></i>
              </template>
              <b-dropdown-item @click="editComment(item.comment, item.id)"
                >Edit</b-dropdown-item
              >
              <b-dropdown-item @click="deleteComment(item.id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <div class="comment-content" v-if="!item.deleted">
          {{ item["comment"] }}
        </div>
        <div
          class="comment-content"
          v-if="item.deleted"
          style="font-style: italic;color: grey;"
        >
          Comment deleted
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { activityMethods, levelComputed, adminComputed } from "@/state/helpers";
import _ from "lodash";
export default {
  props: {
    isAddComment: {
      type: Boolean,
    },
    comment: {
      type: Array,
    },
    requestId: {
      type: Number,
    },
    cardName: {
      type: String,
    },
  },
  data() {
    return {
      text: "",
      id: null,
      isEditingNew: false,
      isEditingExisting: false,
    };
  },
  watch: {
    isAddComment: {
      handler() {
        this.isEditingNew = this.isAddComment;
        this.isEditingExisting = false;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...activityMethods,
    editNew() {
      this.isEditingNew = true;
      this.isEditingExisting = false;
      this.id = "";
      this.text = "";
    },
    dropdownstyle(item) {
      return this.core.id !== item.user_id || item.deleted
        ? "margin-right:22px"
        : "";
    },
    clearAll() {
      this.isEditingNew = false;
      this.isEditingExisting = false;
    },
    addComment() {
      const payload = {
        level_id: this.selected_level.id,
        comment: this.text,
      };
      let apiName = `save${this.cardName}CommentAPI`;
      this[apiName](payload);
      this.isEditingExisting = false;
      this.isEditingNew = false;
      this.$emit("refresh");

      this.id = "";
      this.text = "";
    },
    sendEditedComment() {
      const payload = {
        id: this.id,
        comment: this.text,
      };

      let apiName = `editCommentAPI`;
      this[apiName](payload);
      this.isEditingExisting = false;
      this.$emit("refresh");

      this.id = "";
      this.text = "";
    },
    editComment(comment, id) {
      this.text = comment;
      this.id = id;
      this.isEditingExisting = true;
      this.isEditingNew = false;
    },
    deleteComment(id) {
      const payload = {
        id: id,
      };

      let apiName = `deleteCommentAPI`;
      this[apiName](payload);
      this.$emit("refresh");

      this.isEditingExisting = false;
      this.isEditingNew = false;
    },
  },
  computed: {
    ...adminComputed,
    ...levelComputed,
    displayComment() {
      return _.orderBy(
        this.comment,
        (item) => Date.parse(new Date(item.created_at)),
        ["desc"]
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep input:focus {
  border: 1px solid blue;
}

.wrapper {
  margin-top: 25px;
  padding: 0 0px;

  .comment-row {
    width: 70%;
  }
}

.item {
  margin-bottom: 25px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

.reply {
  color: #34c38f;
}

.icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #556ee640;
  color: #556ee6;
  font-size: 16px;
}

.comment-content {
  text-align: left;
}

.form-control {
  width: 70%;
}
</style>
